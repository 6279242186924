import Forms from '../../index'

class ComplimentForm extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.selectedCategoryId = null
        this.selectedCategoryName = null

        this.setInitialValues()
        this.setEventListeners()
    }

    /**
     * Destructor
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('click touch', '.btn-back', () => this.toPreviousStep())
        this.form.on('click touch', '[data-tab="step-1"] .category-choice-button', this.handleCategoryChange.bind(this))

        /**
         * Reset form
         */
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.form.find('.tab-pane[data-tab="step-1"] .category-choice-button').removeClass('active')
        })
    }

    /**
     * Remove atatched event listeners
     */
    removeEventListeners() {
        this.form.off('click touch')
    }

    /**
     * Initial values setter
     */
    setInitialValues() {
        //@todo add something if needed
    }

    /**
     * Category change event handler
     * @param {Object} event
     */
    handleCategoryChange(event) {
        const $button = $(event.currentTarget)
        const { value, name } = $button.data()

        this.selectedCategoryId = value
        this.selectedCategoryName = name

        const $step1 = this.form.find('[data-tab="step-1"]')

        // Set proper active class
        $step1.find('.category-choice-button').removeClass('active')
        $button.addClass('active')

        // Update nav title
        this.setNavTitle()

        // Set value to hidden input
        $step1.find('.category-input').val(this.selectedCategoryId)

        this.toNextStep()
    }

    /**
     * Set top "small" navigation
     */
    setNavTitle() {
        this.form.find('.top-title .category-title').text(`/${this.selectedCategoryName}`)
    }

    /**
     * Called after each step/form submission
     */
    afterSubmit() {
        this.setNavTitle()
        this.setInitialValues()
    }

    toNextStep() {
        this.step = this.step + 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 175)
    }

    toPreviousStep() {
        this.step = this.step - 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 125)
    }
}

export default ComplimentForm
