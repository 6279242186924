import LocationInput from '../fields/LocationInput'
import PassengerInput from '../fields/PassengerInput'
import SimpleInput from '../fields/SimpleInput'
import DateInput from '../../../../../components/fields/DateInput'

import DeepLinking from '../DeepLinking'

/**
 * BookingPanel Flights tabs Base class
 */
class BaseBookingTab {
    constructor(domElement = null, {
        locations: {
            origins = [],
            destinations = []
        },
        templates = {},
        routes = {},
        onSubmit = null,
        onPassengersChange = null,
        onPromoCodeChange = null
    }) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide propper DOM element for BookingTabs Classes')
            return
        }

        // Input instances store
        this.instances = {}

        // Locations
        this.originsData = origins
        this.destinationsData = destinations

        // Templates
        this.templates = templates

        // Routes
        this.routes = routes

        // Deeplinking
        this.DeepLinking = new DeepLinking(this.routes.deeplinking)

        this.onSubmit = null
        if(onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.onPassengersChange = null
        if(onPassengersChange && typeof onPassengersChange === 'function') {
            this.onPassengersChange = onPassengersChange
        }

        this.onPromoCodeChange = null
        if(onPromoCodeChange && typeof onPromoCodeChange === 'function') {
            this.onPromoCodeChange = onPromoCodeChange
        }
    }

    /**
     * Main initialization method
     */
    init() {
        this.initInputs()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        // Clear instances
        Object.keys(this.instances).forEach(rowKey => {
            const rowInstances = this.instances[rowKey]
            Object.keys(rowInstances).forEach(inputName => {
                const inputInstance = rowInstances[inputName]
                inputInstance.destroy()
            })
        })

        this.instances = {}
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-search', this.handleSearchButtonClick.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Action handler for submission logic
     * @param {Object} event
     */
    handleSearchButtonClick(event) {
        event.stopPropagation()

        let isValid = true
        const payloadValues = {}

        Object.keys(this.instances).forEach(rowKey => {
            const rowInstances = this.instances[rowKey]
            Object.keys(rowInstances).forEach(inputName => {
                // Validate
                const inputInstance = rowInstances[inputName]
                if(!inputInstance.validate()) {
                    isValid = false
                }

                // Get value
                const value = inputInstance.getValue()

                payloadValues[rowKey] = payloadValues[rowKey] || {}
                payloadValues[rowKey][inputName] = value
            })
        })

        if (isValid) {
            const payload = {
                tripType: this.tabType,
                payloadValues,
            }

            // Trigger Deeplinking
            this.DeepLinking.engage(payload)

            // Update saved search items
            if (this.onSubmit) {
                this.onSubmit(payload)
            }
        }
    }

    /**
     * Input initialization
     */
    initInputs() {
        const inputs = this.container.find('select.form-input, input.form-input')
        inputs.each((_, input) => {
            const $input = $(input)

            if (!$input.hasClass('input-initialized')) {
                let inputInstance
                const { inputType, name } = $input.data()

                switch (inputType) {
                    case 'location-input':
                        inputInstance = this.initLocationInput(input)
                        break

                    case 'date-input':
                        inputInstance = this.initDateInput(input)
                        break

                    case 'passengers-input':
                        inputInstance = this.initPassengersInput(input)
                        break

                    default:
                        inputInstance = this.initPromoCodeInput(input)
                        break
                }

                $input.addClass('input-initialized')

                const $row = $input.closest('.flight-row')
                const rowIndex = $row.data('rowIndex')

                const rowKey = `row_${rowIndex}`

                this.instances[rowKey] = {
                    ...this.instances[rowKey] || {},
                    [name]: inputInstance
                }
            }
        })
    }

    /**
     * Set values for all of the fields
     * @param {Object} values
     */
    setFieldValues(values = {}) {
        Object.keys(values).forEach(rowKey => {
            const rowValues = values[rowKey]
            Object.keys(rowValues).forEach(fieldName => {
                const fieldValue = rowValues[fieldName]
                const currentInstance = this.instances[rowKey][fieldName]

                if (fieldName === 'location_from' || fieldName === 'location_to') {
                    const { id } = fieldValue
                    currentInstance.setValue(id)
                }

                if (fieldName === 'date') {
                    const dateValues = fieldValue.map(dateStr => new Date(dateStr))
                    currentInstance.setValue(dateValues)
                }

                if (fieldName === 'passengers' || fieldName === 'promo_code') {
                    currentInstance.setValue(fieldValue)
                }
            })
        })
    }

    /**
     * Passengers input field setter
     * @param {Object} values
     */
    setPassengersInputValue(values = {}) {
        // Find passengers input instances
        Object.values(this.instances).forEach(rowInstances => {
            if (rowInstances['passengers']) {
                /**
                 * @type PassengerInput || undefined
                 */
                const inputInstance = rowInstances['passengers']

                // Update values
                inputInstance.setValue(values)
            }
        })
    }

    /**
     * PromoCode field setter
     * @param {String} value
     */
    setPromoCodeValue(value = null) {
        // Find promo code instances
        Object.values(this.instances).forEach(rowInstances => {
            if (rowInstances['promo_code']) {
                /**
                 * @type SimpleInput || undefined
                 */
                const inputInstance = rowInstances['promo_code']

                // Update value
                inputInstance.setValue(value)
            }
        })
    }

    /**
     * Location input change handler
     * @param {HTMLElement} input
     * @param {Object} data
     */
    handleLocationInputChange(input, data = {}) {
        const {
            name,
            instanceKey,
            selected
        } = data

        // All instances in row
        const rowInstances = this.instances[instanceKey]

        if (rowInstances) {
            // Fetch connected input instance
            const connectedInputName = name === 'location_from'
                ? 'location_to'
                : 'location_from'

            /**
             * @type LocationInput
            */
            const inputInstance = rowInstances[name]

            /**
             * @type LocationInput
            */
            const connectedInputInstance = rowInstances[connectedInputName]

            /**
             * @type DateInput
             */
            const dateInputInstance = rowInstances['date']

            if (connectedInputInstance) {
                const connectedInputData = connectedInputInstance.getValue()

                // Set new options to connected input
                if (selected) {
                    connectedInputInstance.fetchNewOptions(selected.id)
                }

                // Reset options
                if (!selected && !connectedInputData) {
                    inputInstance.fetchNewOptions()
                    connectedInputInstance.fetchNewOptions()
                }

                // Set available dates to date input
                if (selected && connectedInputData) {
                    dateInputInstance.fetchEnabledDates({
                        [name]: selected.code,
                        [connectedInputName]: connectedInputData.code
                    })
                }
            }
        }

        // Update passengers inputs
        this.setPassengerInputs()
    }

    /**
     * Date input change handler
     *  @param {HTMLElement} input
     * @param {Object} data
     */
    handleDateInputChange(input, data = {}) {
        // @todo check if we can set other fields, partially atleast
    }

    /**
     * Passengers input change handler
     * @param {HTMLElement} input
     * @param {Object} data
     */
    handlePassengersInputChange(data = {}) {
        if (this.onPassengersChange) {
            const { values } = data

            this.onPassengersChange({
                values,
                tabType: this.tabType,
            })
        }
    }

    /**
     * Promo code input change handler
     * @param {HTMLElement} input
     * @param {Object} data
     */
    handlePromoCodeInputChange(data = {}) {
        if(this.onPromoCodeChange) {
            const { value } = data

            this.onPromoCodeChange({
                value,
                tabType: this.tabType
            })
        }
    }

    /**
     * Location input initialization
     * @param {HTMLElement} input
     */
    initLocationInput(input) {
        const { type } = $(input).data()
        const inputInstance = new LocationInput(input, {
            options: type === 'from' ? this.originsData : this.destinationsData,
            optionsRoute: type === 'from' ? this.routes.origins : this.routes.destinations,
            onChange: data => this.handleLocationInputChange(input, data)
        })

        // Fetch options onload
        inputInstance.fetchNewOptions()

        return inputInstance
    }

    /**
     * Date input initialization
     * @param {HTMLElement} input
     */
    initDateInput(input) {
        const inputInstance = new DateInput(input, {
            datesRoute: this.routes.dates,
            onChange: data => this.handleDateInputChange(input, data)
        })

        return inputInstance
    }

    /**
     * Passengers input initialization
     * @param {HTMLElement} input
     */
    initPassengersInput(input) {
        const inputInstance = new PassengerInput(input, {
            values: null,
            popoverTemplate: this.templates.passengerPopover,
            onChange: data => this.handlePassengersInputChange(data)
        })

        return inputInstance
    }

    /**
     * Promo code input initialization
     * @param {HTMLElement} input
     */
    initPromoCodeInput(input) {
        const inputInstance = new SimpleInput(input, {
            onChange: data => this.handlePromoCodeInputChange(data)
        })

        return inputInstance
    }

    /**
     * Update passenger inputs by location
     */
    setPassengerInputs() {
        /**
         * @type PassengerInput || null
         */
        let passengerInput = null
        const locationInputs = []

        // Find passengers input instances
        Object.values(this.instances).forEach(rowInstances => {
            if (rowInstances['passengers']) {
                passengerInput = rowInstances['passengers']
            }

            if (rowInstances['location_from']) {
                const value = rowInstances['location_from'].getValue()
                locationInputs.push(value)
            }

            if (rowInstances['location_to']) {
                const value = rowInstances['location_to'].getValue()
                locationInputs.push(value)
            }
        })

        const locationValues = locationInputs.filter(item => item !== null).map(item => item.countryCode)

        if(locationValues.includes('GB')) {
            passengerInput.enableYouthInput()
        } else {
            passengerInput.disableYouthInput()
        }
    }
}

export default BaseBookingTab
