import FireBase from "../../utils/firebase"
import Maureva from "../../utils/maureva"
import moment from "moment"

class UserAuth {
    constructor({
        onAuthChange = null,
    }) {
        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        this.Maureva = new Maureva()

        this.onAuthChange = null
        if(onAuthChange && typeof onAuthChange === 'function') {
            this.onAuthChange = onAuthChange
        }

        // Store for event listeners
        this._triggers = {}
    }

    // "on" event listeners on object
    on(event, callBack) {
        if(!this._triggers[event]) {
            this._triggers[event] = []
        }

        this._triggers[event].push(callBack)
    }

    // Event dispatcher for "on" events
    triggerHandler(event, eventData) {
        if(this._triggers[event]) {
            this._triggers[event].forEach(listener => {
                listener({
                    event,
                    data: eventData
                })
            })
        }
    }

    /**
     * Check wheter user is already logged in firebase
     */
    async isUserLogged() {
        try {
            const userUid = await this.FireBase.getLoggedUser()
            return userUid ? true : false
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Check if logged user is social logged
     */
    async isUserSocialLogged() {
        try {
            const providers = await this.FireBase.getUserProviders()

            return providers.includes('google.com') || providers.includes('facebook.com')
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Fetch "Fresh" user data
     */
    async fetchUserData() {
        try {
            const userUid = await this.FireBase.getLoggedUser()
            if(userUid) {
                const userResponse = await this.Maureva.retrieveUserData(userUid)
                this.handleUserResponse(userResponse.data)
            }
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * User login
     */
    async loginUser() {

    }

    /**
     * User logout
     */
    async logoutUser() {
        try {
            await this.FireBase.logOutUser()

            // Trigger CB function so we can update header
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            // Trigger event listener
            this.triggerHandler('onAuthChange', null)

            return
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     *
     * @param {Object} data
     */
    async updateUserData(data = {}) {
        try {
            // Update firebase credentials
            const isUserSocialLogged = await this.isUserSocialLogged()
            if(!isUserSocialLogged) {
                const { email, password } = data

                await this.FireBase.updateUserEmail(email)
                await this.FireBase.updateUserPassword(password)
            }

            // Maureva API call
            const response = await this.Maureva.updateUserProfile(data)

            // Fetch new data & set it
            this.fetchUserData()

            // Return API call response so we can handle potential errors
            return response
        } catch(err) {
            console.error(err)
            throw err
        }
    }


    /**
     * Deactivate user profile
     * @param {Object} data
     * @param {String} deactivate
     */
    async deleteUserProfile(data = {}, deactivate = '0') {
        const createUserData = data => {
            const { email, firstname, lastname, dateOfBirth, mobilePhone } = data

            const dob = dateOfBirth ? moment(dateOfBirth).format('DD.MM.YYYY') : null

            // Mobile phone
            let phoneNumber = null
            if (mobilePhone) {
                phoneNumber = `${mobilePhone.areaCode}${mobilePhone.number}`
            }

            return {
                email,
                phoneNumber,
                firstName: firstname,
                lastName: lastname,
                dateOfBirth: dob,
            }
        }

        try {
            // Fetch firebase token
            const userFirebaseToken = await this.FireBase.getAccessToken()
            const userData = createUserData(data)

            const payload = {
                userData,
                deactivate,
                userFirebaseToken
            }

            // Maureva API call
            await this.Maureva.deactivateAccount(payload)

            return
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Handler for Maureva response
     * @param {Object} data
     */
    handleUserResponse(data = {}) {
        const { successfulReply } = data

        if (successfulReply) {
            // Handle user data
            const { response } = data

            // Set user data to localStorage
            window.localStorage.setItem('user_data', JSON.stringify(response))

            // Trigger CB function
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            // Trigger event listener
            this.triggerHandler('onAuthChange', response)

            return true
        } else {
            // Logout user from Firebase
            this.FireBase.logOutUser()

            // Trigger event listener
            this.triggerHandler('onAuthChange', null)

            return false
        }
    }

    /**
     * Update user data in localstorage and trigger CBs & listeners
     * @param {Object} data
     * @returns {Boolean}
     */
    handleUserUpdateResponse(data = {}) {
        const { successfulReply } = data

        if (successfulReply) {
            // Handle user data
            const { response } = data

            // Set user data to localStorage
            window.localStorage.setItem('user_data', JSON.stringify(response))

            // Trigger CB function
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            // Trigger event listener
            this.triggerHandler('onAuthChange', response)

            return true
        } else {
            return false
        }
    }

    /**
     * Dummy user response
     * @returns {Object}
     */
    getDummyData() {
        return {
            data: {
                "response": {
                    "login": "test@test.com",
                    "firstname": "John",
                    "lastname": "Doe",
                    "displayName": "John",
                    "gender": "M",
                    "email": "test@test.com",
                    "userUuid": "6zdgcPMsAidsyvVyRdSKJeSKM000",
                    "photoUrl": "https://lh3.googleusercontent.com/ogw/AGvuzYbPCYc7RakoP93k8U41pESR55jBG3rtZ6yjJqg1=s32-c-mo",
                    "id": "yzv9RZPz2ZkfzazesRwX",
                    "title": "Mr",
                    "dateOfBirth": "2000-06-12T11:43:20.934Z",
                    "mobilePhone": {
                      "number": "123456",
                      "areaCode": "+33"
                    },
                    "flightNotificationActivated": true,
                    "commercialNotificationActivated": true,
                    "pushNotificationsToken": "fQ_zpsanQMSz5tMU6F4Pdt:APA91bGJURmo2VFb8eIdhU7xttz4bDsqAYJwkwxXeI66g-gFejxXC4uq2nobjk5mWpyO-h8u7bUI",
                    "dcsUniqueId": "300014762283",
                    "type": "ADT"
                },
                "successfulReply": true,
                "userErrorMessage": "NO_ERROR"
            }
        }
    }
}

export default UserAuth
