/**
 * ContentBundle main script file
 */
// jQuery
import $ from 'jquery'
window.$ = window.jQuery = $

// Components
import ContentPagesList from './components/ContentPagesList'
import ImageGallery from './components/ImageGallery'

$('.content-pages-list--container').each(function() {
    new ContentPagesList(this)
})

$('.image-gallery').each(function() {
    new ImageGallery(this)
})
