import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

import ResizeObserver from 'resize-observer-polyfill'

// Expose pollyfill
window.ResizeObserver = ResizeObserver

/**
 * Onload initialization
 */
$(() => {
    initScrollbars()
})

/**
 * Init scrollbar plugin
 * [Import & call when we have possible dynamic content with "custom-scroll" class]
 */
function initScrollbars() {
    const customScrollbars = $('.custom-scroll')

    window.customScrollbars = window.customScrollbars || []
    window.customScrollbars.forEach(scrollbar => {
        scrollbar.unMount()
    })

    customScrollbars.each((_, scrollbar) => {
        const $scrollContainer = $(scrollbar)

        $scrollContainer.find('.row').addClass('mx-0')
        $scrollContainer.find('.row .col:first').addClass('ps-1')
        $scrollContainer.find('.row .col:last').addClass('pe-1')

        const el = new SimpleBar(scrollbar, {
            autoHide: false
        })

        window.customScrollbars.push(el)
    })
}

export default initScrollbars
