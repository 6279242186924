/**
 * Success step class
 */

class SuccessStep {
    constructor(domElement = null, {
        onSubmit = null
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provider propper DOM element for SuccessStep class')
            return
        }

        this.onSubmit = null
        if (onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    setEventListeners() {
        this.container.on('click', '.btn-step-submit', this.handleStepSubmit.bind(this))
    }

    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Step submission action handler
     */
    handleStepSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const $button = $(event.currentTarget)
        if(this.onSubmit) {
            this.onSubmit($button)
        }
    }
}

export default SuccessStep
