import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class SabreCheckIn {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        const {
            country,
            language,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.defaults = {
            deepLink: true,
            locale: this.localeFormatter(this.userLanguage),
        }
    }

    engage(payload = {}) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        window.open(redirectUrl, '_blank')
    }

    formatPayload(payload = {}) {
        const data = {}
        Object.keys(payload).forEach(inputName => {
            const value = payload[inputName]

            if (inputName === 'booking_code') {
                const isTicketNumber = /^\d+$/.test(value)

                if (isTicketNumber) {
                    data['virtualCouponRecordNumber'] = value
                } else {
                    data['recordLocator'] = value
                }
            } else if (inputName === 'surname') {
                data['lastName'] = value
            }
        })

        return {
            ...this.defaults,
            ...data,
        }
    }

    /**
     * Locale formatter
     * @param {String} language
     */
    localeFormatter(language = null) {
        const formatter = {
            'sr_Cyrl': 'sr-SP',
            'sr_Latn': 'sr-BA',
            'en': 'en-US',
        }

        if (formatter.hasOwnProperty(language)) {
            formatter[language]
        } else {
            return 'en-US'
        }
    }
}

export default SabreCheckIn
