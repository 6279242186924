import LocationInput from "../flight-schedule/fields/LocationInput"

class TopOffersCarousel {
    constructor(domElement = null) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide proper DOM element for TopOffersCarousel class')
            return
        }

        this.isEditMode = this.container.hasClass('editmode')
        if (this.isEditMode) {
            this.init()
        }
    }

    init() {
        // Flag for document reload
        this.canReload = false

        // Fetch and set data
        const { index } = this.container.data()
        const { locations, selectedLocation } = window[`top_offers_carousel_${index}_data`]

        this.locations = locations
        this.selectedLocation = selectedLocation

        const $locationInput = this.container.find('.top-offers-carousel--editmode-container .form-select[data-input-type="location-input"]')
        this.LocationInput = new LocationInput($locationInput[0], {
            options: this.locations,
            optionsRoute: null,
            popoverValidation: false,
            onChange: ({ selected }) => this.handleLocationSelection(selected)
        })

        this.hiddenInput = this.container.find('.location-hidden-input')
        this.setOnLoadValue()
    }

    /**
     * Location selection handler
     * @param {Object} selectedLocation
     */
    handleLocationSelection(selectedLocation = {}) {
        const selectedValue = JSON.stringify(selectedLocation) || '{}'
        this.hiddenInput.html(selectedValue)

        this.reloadDocument()
    }

    /**
     * Set onload value for location input (if selected)
     */
    setOnLoadValue() {
        if (this.selectedLocation && this.selectedLocation.hasOwnProperty('id')) {
            const { id } = this.selectedLocation
            this.LocationInput.setValue(id)
        }

        // Flag so we don't trigger document reload when setting onload values
        this.canReload = true
    }

    /**
     * Reload document opened in editmode
     * [Same logic as "reload: true" in twig editables]
     */
    reloadDocument() {
        if (window.hasOwnProperty('editWindow') && this.canReload) {
            window.editWindow.reload()
        }
    }
}

export default TopOffersCarousel
