import ComplaintForm from "../Complaint"

class BaggageComplaint extends ComplaintForm {
    constructor(domElement = null) {
        super(domElement)

        this.step = 3
        this.initOverrides()
        this.hideCategorySteps()
    }

    initOverrides() {
        const $step1 = this.form.find('[data-tab="step-1"]')
        const $step2 = this.form.find('[data-tab="step-2"]')

        const $topCategory = $step1.find('.category-button.active')
        const categoryTitle = $topCategory.attr('title')

        this.topCategory = categoryTitle

        const $subCategory = $step2.find('.category-choice-button.active')
        const { value, name } = $subCategory.data()

        this.subCategory = name
        this.selectedCategoryId = value

        this.setFormNavTitle()
    }

    hideCategorySteps() {
        this.form.find('.tab-pane').removeClass('active show')

        // "Your info" step
        const $infoStep = this.form.find('.tab-pane[data-tab="step-3"]')
        $infoStep.addClass('active show')
    }
}

export default BaggageComplaint
