import Input from '../../main-booking-panel/parts/fields/SimpleInput'

class TextInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.requiredPopover = null
        this.inputContainer = this.input.parent()
    }

    /**
     * Add event listeners
     */
    setEventListeners() {
        this.input.on('focus', this.handleInputOnFocus.bind(this))
        this.input.on('change', this.handleInputOnChange.bind(this))
    }

    /**
     * Remove attached Event listeners
     */
    removeEventListeners() {
        this.input.off('change')
        this.input.off('focus')
    }

    /**
     * Field validation
     */
    validate() {
        // Empty field
        if (this.isRequired && !this.getValue()) {
            // Set invalid classes
            this.input.addClass('is-invalid')
            this.inputContainer.addClass('is-invalid')

            return false
        }

        return true
    }

    /**
     * Remove validation classes on focus
     */
    handleInputOnFocus() {
        this.input.removeClass('is-invalid')
        this.inputContainer.removeClass('is-invalid')
    }
}

export default TextInput
