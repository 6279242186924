/**
 * Date input component class
 * [Use of flatpickr date-picker]
 * https://flatpickr.js.org/getting-started/
 */

import axios from 'axios'
import moment from 'moment'
import { Tooltip } from 'bootstrap'
import flatpickr from 'flatpickr'

class DateInput {
    /**
     * PassengerInput class constructor
     * @param {HTMLElement} domElement Input to attach Popover
     */
    constructor(domElement = null, {
        onChange = null,
        datesRoute = '',
        popoverValidation = true,
        extraOptions = {},
    }) {
        this.input = $(domElement)
        if(!this.input) {
            console.error('Please provide proper DOM element for DateInput class')
            return
        }

        // Set picker localization
        const {
            datePicker
        } = window.localeData
        this.locale = datePicker

        const {
            type,
            pickerTitle,
            requiredMessage,
            enableYearSelection,
            enableMonthSelection,
        } = this.input.data()

        this.type = type
        this.title = pickerTitle
        this.enableYearSelection = enableYearSelection
        this.enableMonthSelection = enableMonthSelection

        this.isRequired = this.input.prop('required')

        this.fpInstance = null
        this.enabledDates = null
        this.dateFormat = null

        this.inputContainer = this.input.parent()

        this.extraOptions = extraOptions

        // Required Tooltip
        this.isValidated = false
        this.requiredPopover = null
        if (this.isRequired && popoverValidation) {
            this.requiredPopover = new Tooltip(this.inputContainer, {
                trigger: 'manual',
                placement: 'bottom',
                customClass: 'required-tooltip',
                title: requiredMessage,
                container: this.input.closest('.input-group')
            })
        }

        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        this.datesRoute = datesRoute

        this.init()
        this.setEventListeners()
    }

    init() {
        this.fpInstance = new flatpickr(this.input[0], {
            mode: this.type,
            locale: this.locale,
            dateFormat: "F j, Y",
            minDate: "today",
            maxDate: new Date().fp_incr(365), // => Year from now
            //disable: [ new Date().fp_incr(4) ],
            onReady: (selectedDates, dateStr, instance) => {
                this.setCalendarTitle(instance)
                this.disableMonthSelection(instance)
                this.disableYearSelection(instance)
            },
            onChange: (selectedDates, dateStr, instance) => this.handleOnChange(selectedDates, dateStr, instance),
            ...this.extraOptions,
        })

        // Expose provided date format
        this.dateFormat = this.fpInstance.config.dateFormat
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.inputContainer.on('click', () => {
            // Hide popover if shown
            if(this.requiredPopover && this.isValidated) {
                this.isValidated = false
                this.requiredPopover.hide()
            }
        })

        // QFIX for feature to select range over disabled dates
        if (this.type === 'range') {
            $("[class^=flatpickr]").on('mousemove click', () => {
                $('.flatpickr-day').removeClass('notAllowed')
            })
        }
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.inputContainer.off('click')

        if (this.type === 'range') {
            $("[class^=flatpickr]").off('mousemove click')
        }
    }

    /**
     * Destructor method
     */
    destroy() {
        if(this.fpInstance) {
            this.fpInstance.destroy()
            this.fpInstance = null
        }

        if (this.requiredPopover) {
            this.requiredPopover.dispose()
        }

        this.removeEventListeners()
    }

    /**
     * Value setter
     * @param {Array} selectedDates Array with JS Dates
     */
    setValue(selectedDates = []) {
        if(this.fpInstance) {
            this.fpInstance.setDate(selectedDates)
            this.fpInstance.redraw()

            this.setInputValue()
        }
    }

    /**
     * Value getter
     * @returns {Array || null}
     */
    getValue() {
        if(this.fpInstance) {
            return this.fpInstance.selectedDates
        }

        return null
    }

    /**
     * Get formatted selected values
     * @returns {Array || null}
     */
    getFormattedValue() {
        if (this.fpInstance) {
            const { selectedDates } = this.fpInstance
            return selectedDates.map(date => this.fpInstance.formatDate(date, this.dateFormat))
        }

        return null
    }

    /**
     * Clear value
     */
    clearDate() {
        if(this.fpInstance) {
            this.fpInstance.clear()
        }
    }

    /**
     * Field validation
     * @returns {Boolean}
     */
    validate() {
        const { selectedDates } = this.fpInstance
        const isFilled = this.type === 'range'
            ? selectedDates.length === 2
            : selectedDates.length ==1

        if (this.isRequired && !isFilled) {
            // Show popover if not shown already
            if(!this.isValidated) {
                this.isValidated = true
                this.requiredPopover.show()
            }

            return false
        }

        return true
    }

    /**
     * Set available dates
     * @param {Array} dates Array of available dates
     */
    setAvailableDates(dates = [], flightEveryDay = true) {
        // Enable some dates or up to 1 year from today
        if (flightEveryDay) {
            this.enabledDates = [{
                from: 'today',
                to: new Date().fp_incr(365)
            }]
        } else {
            // Convert timestamps to ms
            this.enabledDates = dates.map(date => date * 1000)
        }

        this.fpInstance.set('enable', this.enabledDates)

        // Check if already selected values are enabled
        let clearValues = false
        const { selectedDates } = this.fpInstance
        selectedDates.every(date => {
            let isInResponse = false
            const momentDate = moment(date)
            this.enabledDates.forEach(apiDate => {
                if (momentDate.isSame(apiDate, 'day')) {
                    isInResponse = true
                }
            })

            if (!isInResponse) {
                clearValues = true
                return false
            } else {
                return true
            }
        })

        // Clear values
        if (clearValues) {
            this.fpInstance.clear()
        }

        /*
        this.enabledDates = dates.map(date => moment.unix(date))
        this.fpInstance.set('enable', [(date) => {
            if (date.getDay() === 6 || date.getDay() === 2) {
                return false
            }

            // Date from API
            let isInResponse = false
            const momentDate = moment(date)
            this.enabledDates.forEach(apiDate => {
                if (momentDate.isSame(apiDate, 'day')) {
                    isInResponse = true
                }
            })

            return isInResponse
        }])*/

        // Redraw picker
        this.fpInstance.redraw()
    }

    /**
     * Get available dates by origin & destination codes
     * @param {Object} params
     */
    async fetchEnabledDates(params = {}) {
        try {
            const { location_from, location_to } = params
            let requestUri = this.datesRoute.replace('origin_code', location_from)
            requestUri = requestUri.replace('destination_code', location_to)

            const response = await axios.get(requestUri)
            const { dates, flightEveryDay } = response.data

            this.setAvailableDates(dates, flightEveryDay)

        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * OnChange handler
     */
    handleOnChange(selectedDates, dateStr, instance) {
        if (this.type === 'single') {
            // Trigger change handler
            if(this.onChange) {
                this.onChange({
                    dateStr,
                    instance,
                    selectedDates,
                })
            }
        } else {
            // 2 dates selection
            if (selectedDates.length >= 2) {
                // Trigger change handler
                if(this.onChange) {
                    this.onChange({
                        dateStr,
                        instance,
                        selectedDates,
                    })
                }

                // Set input value
                this.setInputValue()
            }
        }
    }

    /**
     * Set input value from instance values
     */
    setInputValue() {
        if (this.fpInstance && this.type === 'range') {
            const { selectedDates } = this.fpInstance

            // Format input value with conjuction
            const formattedDate1 = this.fpInstance.formatDate(selectedDates[0], this.dateFormat)
            const formattedDate2 = this.fpInstance.formatDate(selectedDates[1], this.dateFormat)

            setTimeout(() => {
                this.input.val(`${formattedDate1} - ${formattedDate2}`)
            }, 100)
        }
    }

    /**
     * Set calendar title method
     * @param {Object} calendarInstance
     */
    setCalendarTitle(calendarInstance = null) {
        // Append title
        if(this.title && calendarInstance) {
            const { calendarContainer } = calendarInstance
            $(calendarContainer).addClass('flatpicker-title').prepend(`<h5 class="title">${this.title}</h5>`)
        }
    }

    /**
     * Disable month selection
     * @param {Object} calendarInstance
     */
    disableMonthSelection(calendarInstance = null) {
        if(!this.enableMonthSelection && calendarInstance) {
            const { calendarContainer } = calendarInstance
            $(calendarContainer).addClass('flatpicker-disable-month-selection')
        }
    }

    /**
     * Disable year selection
     * @param {Object} calendarInstance
     */
    disableYearSelection(calendarInstance = null) {
        if(!this.enableYearSelection && calendarInstance) {
            const { calendarContainer } = calendarInstance
            $(calendarContainer).addClass('flatpicker-disable-year-selection')
        }
    }
}

export default DateInput
