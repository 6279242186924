/**
 * Booking class component
 */
import OneWayBookingTab from './tabs/OneWayBookingTab'
import RoundTripBookingTab from './tabs/RoundTripBookingTab'
import MultiCityBookingTab from './tabs/MultiCityBookingTab'

class Booking {
    constructor(domElement = null, {
        options = {},
        templates = {},
        routes = {},
    }) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Booking Class')
            return
        }

        this.routes = routes
        this.options = options
        this.templates = templates

        this.init()
    }

    init() {
        const roundTripTab = this.container.find('.tab-pane[data-type="round-trip"]')
        const oneWayTab = this.container.find('.tab-pane[data-type="one-way"]')
        const multiCityTab = this.container.find('.tab-pane[data-type="multi-city"]')

        const options = {
            locations: this.options,
            templates: this.templates,
            routes: this.routes,
            //onSubmit: data => this.handleOnSubmit(data),
            onPassengersChange: data => this.handlePassengersChange(data),
            onPromoCodeChange: data => this.handlePromoCodeChange(data)
        }

        this.oneWayTab = new OneWayBookingTab(oneWayTab[0], options)
        this.roundTripTab = new RoundTripBookingTab(roundTripTab[0], options)
        this.multiCityTab = new MultiCityBookingTab(multiCityTab[0], options)
    }

    /**
     * Global handler for passenger changes
     * @param {Object} data
     */
    handlePassengersChange(data = {}) {
        const { tabType, values } = data

        switch (tabType) {
            case 'one-way':
                this.roundTripTab.setPassengersInputValue(values)
                this.multiCityTab.setPassengersInputValue(values)

                break

            case 'round-trip':
                this.oneWayTab.setPassengersInputValue(values)
                this.multiCityTab.setPassengersInputValue(values)

                break

            default:
                this.oneWayTab.setPassengersInputValue(values)
                this.roundTripTab.setPassengersInputValue(values)

                break
        }
    }

    /**
     * Global handler for promo code changes
     * @param {Object} data
     */
    handlePromoCodeChange(data = {}) {
        const { tabType, value } = data

        switch (tabType) {
            case 'one-way':
                this.roundTripTab.setPromoCodeValue(value)
                this.multiCityTab.setPromoCodeValue(value)

                break

            case 'round-trip':
                this.oneWayTab.setPromoCodeValue(value)
                this.multiCityTab.setPromoCodeValue(value)

                break

            default:
                this.oneWayTab.setPromoCodeValue(value)
                this.roundTripTab.setPromoCodeValue(value)

                break
        }
    }
}

export default Booking
