/**
 * Flight info step class component
 */
import axios from 'axios'

class FlightInfo {
    constructor(domElement = null) {
        this.form = $(domElement)
        if(!this.form.length) {
            console.error('Please provide proper DOM element for Complaint/FlightInfo class')
            return
        }

        const $stepContainer = this.form.find('.flight-info-step')
        const { flightNumbersPath } = $stepContainer.data()

        this.flightNumbersData = []
        this.flightNumbersPath = flightNumbersPath

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('change', '.flight-info-step .flight-date-input', this.handleFlightDateChange.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('change')
    }

    /**
     * Flight date change handler
     * @param {Object} event
     */
    handleFlightDateChange(event) {
        const $input = $(event.currentTarget)
        const inputFpInstance = $input[0]._flatpickr

        if(inputFpInstance) {
            let selectedDate = null

            const { selectedDates } = inputFpInstance
            if(selectedDates.length) {
                selectedDate = inputFpInstance.formatDate(selectedDates[0], 'Y-m-d')
                this.fetchFlightNumbersData(selectedDate)
            } else {
                this.flightNumbersData = []
                this.setFlightNumberOptions()
            }
        }
    }

    /**
     * Set options for Flight number select
     */
    setFlightNumberOptions() {
        const $flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const selectizeInstance = $flightNumberInput[0].selectize

        if(selectizeInstance) {
            const options = this.flightNumbersData.map(item => ({
                id: item,
                text: item,
                value: item,
            }))

            const currentValue = selectizeInstance.getValue()
            const isCurrentValueInNewOptions = this.flightNumbersData.includes(currentValue)

            // Clear options
            selectizeInstance.clearOptions(true)
            selectizeInstance.clearOptionGroups(true)

            // Add new options
            selectizeInstance.addOption(options)
            selectizeInstance.refreshOptions(false)

            // Keep selection of "old value" if it is in new options
            if (isCurrentValueInNewOptions) {
                selectizeInstance.addItem(currentValue, true)
            } else {
                selectizeInstance.clear()
            }
        }
    }

    /**
     * Fetch flight numbers for given selected date
     * @param {String|null} selectedDate
     * @returns {Promise}
     */
    async fetchFlightNumbersData(selectedDate = null) {
        const $flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const selectizeInstance = $flightNumberInput[0].selectize

        try {
            // Lock input
            if(selectizeInstance) {
                selectizeInstance.lock()
            }

            // Make request
            const requestUri = `${this.flightNumbersPath}?date=${selectedDate}`
            const response = await axios.get(requestUri)
            const { data } = response

            // Unlock input
            if(selectizeInstance) {
                selectizeInstance.unlock()
            }

            // Update/Set options
            this.flightNumbersData = data
            this.setFlightNumberOptions()

        } catch(err) {
            // Unlock input
            if(selectizeInstance) {
                selectizeInstance.unlock()
            }

            console.error(err)
            throw err
        }
    }
}

export default FlightInfo
