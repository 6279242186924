import BaseBookingTab from './BaseBookingTab'

class MultiCityBookingTab extends BaseBookingTab {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.tabType = 'multi-city'

        this.init()

        // Extra event listeners for adding/removing rows
        this.setRowsEventListeners()
    }

    /**
     * Attach extra event listeners
     * [Will be removed also via "destroy" method in base class]
     */
    setRowsEventListeners() {
        this.container.on('click', '.add-new-flight-btn', this.handleRowAdd.bind(this))
        this.container.on('click', '.remove-flight-btn', this.handleRowRemove.bind(this))
    }

    /**
     * Handler for adding new row
     */
    handleRowAdd() {
        // Last row with flight inputs
        const $lastFlightRow = this.container.find('.flight-row').eq(-2)

        // Get index for one we will insert
        const newRowIndex = $lastFlightRow.data('rowIndex') + 1
        const markup = this.createNewRowHtml(newRowIndex)

        // Insert new row
        $lastFlightRow.after(markup)
        const $newRow = this.container.find(`.flight-row[data-row-index="${newRowIndex}"]`)
        setTimeout(() => {
            $newRow.addClass('show')
        }, 200)

        // Update index and instances of last "non-flight" row
        const $lastRow = this.container.find('.flight-row').last()
        this.updateRowIndex($lastRow, newRowIndex + 1)

        // Append new row instances to store
        this.initInputs()

        // Validate add new flight button
        this.validateAddButton()
    }

    /**
     * Handler for row removal
     * @param {Object} event
     */
    handleRowRemove(event) {
        const $button = $(event.currentTarget)
        const $row = $button.closest('.flight-row')

        // Fade row
        $row.removeClass('show')

        // Remove row
        setTimeout(() => {
            // Remove inputs instances from store
            this.removeInputInstancesByRow($row)

            // Update other indexes and instances
            const $rowsForUpdate = $row.nextAll('.flight-row')
            $rowsForUpdate.each((_, rowElem) => {
                const $rowForUpdate = $(rowElem)
                const oldIndex = $rowForUpdate.data('rowIndex')

                // Just decrement index, since we removed one before
                this.updateRowIndex($rowForUpdate, oldIndex - 1)
            })

            // Remove HTML
            $row.remove()

            // Validate add new flight button
            this.validateAddButton()
        }, 200)
    }

    /**
     * Remove input instances by provided row selector
     * @param {JQuery} $row
     */
    removeInputInstancesByRow($row) {
        // Get indexes
        const { rowIndex } = $row.data()
        const rowKey = `row_${rowIndex}`

        if (this.instances.hasOwnProperty(rowKey)) {
            const rowInstances = this.instances[rowKey]

            Object.values(rowInstances).forEach(instance => {
                instance.destroy()
            })

            delete this.instances[rowKey]
        }
    }

    /**
     * Update row index along with inputs instances
     * @param {jQuery} $row
     * @param {Number} newIndex
     */
    updateRowIndex($row, newIndex = this.generateUniqueId()) {
        // Fetch old index
        const oldIndex = $row.data('rowIndex')

        const oldRowKey = `row_${oldIndex}`
        const newRowKey = `row_${newIndex}`

        // Update instances in store
        if (this.instances.hasOwnProperty(oldRowKey)) {
            const rowInstances = this.instances[oldRowKey]
            this.instances[newRowKey] = rowInstances

            delete this.instances[oldRowKey]
        }

        // Update new index in HTML
        $row.data('rowIndex', newIndex)
        $row.attr('data-row-index', newIndex)
    }

    /**
     * Validate Add new flight button
     * [*Max 3 flights*]
     */
    validateAddButton() {
        const $button = this.container.find('.add-new-flight-btn')
        const $flightRows = this.container.find('.flight-row')

        if ($flightRows.length >= 4) {
            $button.prop('disabled', true)
        } else {
            $button.prop('disabled', false)
        }
    }

    /**
     * Generate markup for new flight row
     * @returns {String}
     */
    createNewRowHtml(rowIndex = this.generateUniqueId()) {
        const { fromInput, toInput, dateInput, removeFlightButton } = this.templates

        const fromInputMarkup = fromInput.replace('data-name=""', 'data-name="location_from"')
        const toInputMarkup = toInput.replace('data-name=""', 'data-name="location_to"')
        const dateInputMarkup = dateInput.replace('data-name=""', 'data-name="date"')

        const markup = `
            <div class="d-flex flex-column flight-row fade pt-3 border-top" data-row-index="${rowIndex}">
                <div class="input-group input-group-sm mb-4">
                    ${fromInputMarkup}
                </div>

                <div class="input-group input-group-sm mb-4">
                    ${toInputMarkup}
                </div>

                <div class="d-flex flex-row mb-3">
                    <div class="input-group input-group-sm me-4">
                        ${dateInputMarkup}
                    </div>
                    <div class="button-remove-col pe-1">
                        ${removeFlightButton}
                    </div>
                </div>
            </div>
        `

        return markup
    }

    /**
     * Generate unique Id
     * @returns {String}
     */
    generateUniqueId() {
        return Math.random().toString(36).substr(2, 9)
    }
}

export default MultiCityBookingTab
