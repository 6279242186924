import XRegExp from 'xregexp'
import moment from 'moment'
import axios from "axios"
import PhoneNumberInput from '../components/fields/PhoneNumber'
import { initSingleSelect } from '../components/selects'
import DateInput from '../components/fields/DateInput'
import FileUploadInput from './fields/FileUploadInput'
import PassengerInput from './fields/PassengerInput'

import { isElementVisible } from 'ContentBundle/js/frontend/utils/domHelpers'

class Forms {
    constructor(domElement = null) {
        this.form = $(domElement)

        if(!this.form.length) {
            return
        }

        this.step = 1
        this.PhoneNumberInput = null
        this.selects = []
        this.dateInputs = []
        this.dropzoneInputs = {}
        this.passengersInputs = []

        const {
            country,
            language,
            displayRegions,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.localizedRegions = Object.keys(displayRegions).reduce((acc, k) => {
            acc[k.toLowerCase()] = displayRegions[k]
            return acc
        }, {})

        this.formEndPoints = null
        if (this.form.hasClass('formbuilder') && this.form.data('ajaxStructureUrl')) {
            this.setEndpoints(this.form.data('ajaxStructureUrl'))
        } else {
            this.init()
        }
    }

    /**
     * Fetch endpoints for form, eg. file-chunk, file-add etc.
     * @param {String} ajaxStructureUrl
     */
    async setEndpoints(ajaxStructureUrl = '') {
        try {
            const response = await axios.get(ajaxStructureUrl)
            const { data } = response

            this.formEndPoints = data

            this.init()
        } catch(err) {
            console.error('Error fetching form endpoints')
            throw err
        }
    }

    init() {
        this.initFields()

        /**
         * Reset form
         */
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            if(this.form.hasClass('no-clear-on-close')) {
                return
            }

            this.step = 1

            this.form.removeClass('d-none')
            this.form.find('.alert').remove()
            this.form.closest('.offcanvas-body').find('.template-content').remove()
            this.form.find('.tab-pane').removeClass('active show')
            this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
            this.form.find('.visible-fields input, .visible-fields textarea').val('')
            this.form.find('.visible-fields input[type="radio"]').prop('checked', false)
            this.form.find('.visible-fields input[type="checkbox"]').prop('checked', false)
            this.form.find('.is-invalid').removeClass('is-invalid')
            this.form.find('.invalid-feedback').remove()

            this.initFields(true)
        })

        this.form.on('click', 'button[type="submit"]', (event) => {
            $('input[type="submit"]', $(event.currentTarget).parents('form')).removeAttr('clicked');
            $(event.currentTarget).attr('clicked', 'true');
        })

        this.form.on('submit', async (event) => {
            event.preventDefault()
            event.stopPropagation()

            const submitButton = this.form.find('button[type="submit"][clicked=true]')

            const formData = this.getFormData(this.form[0])
            submitButton.addClass('requesting')

            try {
                const response = await axios.post(this.form.attr('action'), formData)

                submitButton.removeClass('requesting')

                this.updateForm(response.data)

                if (submitButton.hasClass('next')) {
                    if (this.validateStep()) {
                        this.toNextStep()
                    }
                }

                this.afterSubmit()
            } catch (error) {
                submitButton.removeClass('requesting')
                console.log(error)
                this.afterSubmit()
                throw error
            }
        })

        /**
         * Special inputs case
         */

        // Disable paste-ing to input (eg. repeat e-mail & password)
        this.form.on('paste', '.disable-paste', event => { event.preventDefault() })

        // Letters only
        this.form.on('keydown', '.letters-only', event => {
            const unicodeWordRegex = XRegExp("^\\p{L}")
            return unicodeWordRegex.test(event.key)
        })

        // Numbers only
        this.form.on('keydown', '.numbers-only', event => {
            const unicodeWordRegex = XRegExp("^\\d+$")
            return unicodeWordRegex.test(event.key)
        })

        // Zip code only
        this.form.on('keydown', '.zip-input-only', event => {
            const unicodeWordRegex = XRegExp("^[a-zA-Z0-9]+$")
            return unicodeWordRegex.test(event.key)
        })
    }

    /**
     * Fields initialization
     * @param {Boolean} clear clear action flag
     */
    initFields(clear = false) {
        this.initPhone()
        this.initSelect()
        this.initDates()
        this.initPassengersInputs()

        this.reInitDropzone(!clear)
    }

    initPhone() {
        const phoneField = this.form.find('.form-input[data-input-type="phone-input"]')
        if (phoneField.length) {
            this.PhoneNumberInput = null
            this.PhoneNumberInput = new PhoneNumberInput(phoneField[0], {
                initialCountry: this.userCountry, // Selected country from header
                localizedCountries: this.localizedRegions, // Localized regions
                options: {} // Extra options
            })
        }
    }

    initSelect() {
        const selectFields = this.form.find('.form-input[data-input-type="select"]')
        if (selectFields.length) {
            this.destroySelects()

            selectFields.each((_, select) => {
                const $select = $(select)
                const inputId = $select.attr('id')
                const inputContainer = $select.parent()
                const inputLabel = $select.next('.form-label')

                initSingleSelect(select, {
                    render: {
                        optgroup_header: function(data, escape) {
                            return `<div class="optgroup-header bordered">${escape(data.label)}<span class="divider"></span></div>`
                        },
                        option: function(data, escape) {
                            return `
                            <div class="option d-flex flex-row align-items-center">
                                ${escape(data.text)}
                            </div>
                            `
                        },
                        item: function(data, escape) {
                            return `<div class="item">${escape(data.text)}</div>`
                        }
                    },
                    // Floating label logic
                    onFocus: () => {
                        inputLabel.addClass('fp-floating-label--focused')
                    },
                    onBlur: () => {
                        inputLabel.removeClass('fp-floating-label--focused')
                    },
                    onItemAdd: () => {
                        inputLabel.addClass('fp-floating-label--valued')
                    },
                    onDelete: () => {
                        inputLabel.removeClass('fp-floating-label--valued')
                    },
                    onInitialize: function() {
                        // Set label
                        const val = this.getValue()
                        const trimmedValue = val.trim()

                        if (trimmedValue) {
                            inputLabel.addClass('fp-floating-label--valued')
                        } else {
                            inputLabel.removeClass('fp-floating-label--valued')
                        }
                    },
                    onChange: val => {
                        if ($(select).attr('name').includes('flight_type')) {
                            if (val == 'layover' || val == 'return') {
                                this.form.find('.range-input').addClass('d-none')
                                this.form.find('.departure-return-inputs').removeClass('d-none')
                            } else {
                                this.form.find('.departure-return-inputs').addClass('d-none')
                                this.form.find('.range-input').removeClass('d-none')
                            }
                        }

                        // Set label
                        const trimmedValue = val.trim()

                        if (trimmedValue) {
                            inputLabel.addClass('fp-floating-label--valued')
                        } else {
                            inputLabel.removeClass('fp-floating-label--valued')
                        }
                    },
                    onClear: () => {
                        inputLabel.removeClass('fp-floating-label--valued')
                    }
                })

                // Fetch selectize instance
                const selectizeInstance = select.selectize

                // Outside click "disable" multiple dropdowns open at same time
                $('html').on(`click.${inputId}`, event => {
                    if (
                        inputContainer.has(event.target).length === 0
                    ) {
                        // Trigger blur method (Remove focus and closes dropdown)
                        selectizeInstance.blur()
                    }
                })

                // Add instance to store
                this.selects.push(selectizeInstance)
            })
        }
    }

    initDates() {
        const dateFields = this.form.find('.form-input[data-input-type="date"]')
        if (dateFields.length) {
            this.destroyDateInputs()
            dateFields.each((_, dateField) => {
                const $input = $(dateField)
                const value = $input.val()
                const { minDate, maxDate, type } = $input.data()

                const extraOptions = {}
                if(typeof minDate !== 'undefined') {
                    extraOptions['minDate'] = Date.parse(minDate)
                        ? new Date(minDate)
                        : minDate
                }

                if(typeof maxDate !== 'undefined') {
                    extraOptions['maxDate'] = Date.parse(maxDate)
                        ? new Date(maxDate)
                        : maxDate
                }

                const inputInstance = new DateInput(dateField, {
                    popoverValidation: false,
                    extraOptions,
                })

                // Set onload value (And after submission)
                if(value) {
                    if(type === 'single') {
                        if(Date.parse(value)) {
                            const date = moment(value).toDate()
                            inputInstance.setValue([ date ])
                        }
                    } else {
                        // @todo handle range values
                    }
                }

                this.dateInputs.push(inputInstance)
            })
        }
    }

    /**
     * FileInputs (re)initialization
     * @param {Boolean} keepFiles
     */
    reInitDropzone(keepFiles = true) {
        const fileInputs = this.form.find('.form-input[data-input-type="file"]')

        if(fileInputs.length) {
            fileInputs.each((_, input) => {
                const $field = $(input)
                const fieldName = $field.attr('name')

                if(this.dropzoneInputs.hasOwnProperty(fieldName)) {
                    const inputInstance = this.dropzoneInputs[fieldName]
                    const oldInstance = this.dropzoneInputs[fieldName].getInstance()

                    // Keep files
                    let files = []
                    if (keepFiles && oldInstance) {
                        if (inputInstance.formbuilderInput) {
                            files = oldInstance.files
                        } else {
                            oldInstance.getQueuedFiles().forEach(file => {
                                files.push(file)
                            })
                        }
                    }

                    // Destroy "old" dropzone instance
                    // Destroy action will also trigger "removedFile" and call endPoints
                    oldInstance.destroy()
                    delete this.dropzoneInputs[fieldName]

                    // Initialize new one
                    const dropzoneInstance = new FileUploadInput(input, {
                        action: this.form.attr('action'),
                        endPoints: this.formEndPoints,
                    })

                    // Set files to new instance
                    if (keepFiles) {
                        const newInstance = dropzoneInstance.getInstance()
                        files.forEach(file => {
                            newInstance.addFile(file)
                        })
                    }

                    // Set instance to fields "store"
                    this.dropzoneInputs[fieldName] = dropzoneInstance
                } else {
                    const dropzoneInstance = new FileUploadInput(input, {
                        action: this.form.attr('action'),
                        endPoints: this.formEndPoints,
                    })

                    this.dropzoneInputs[fieldName] = dropzoneInstance
                }
            })
        }
    }

    initPassengersInputs() {
        const passengersFields = this.form.find('.form-input[data-input-type="passengers-input"]')
        if (passengersFields) {
            this.destroyPassengersInputs()
            passengersFields.each((_, passengerInput) => {
                const passengerInstance = new PassengerInput(passengerInput, {
                    values: null,
                    popoverTemplate: this.form.find('.passengers-input-template').html(),
                    onChange: () => {}
                })

                this.passengersInputs.push(passengerInstance)
            })
        }
    }

    /**
     * Called after form submission
     * *Override if needed*
     */
    afterSubmit() {

    }

    getFormData(form) {
        const formData = new FormData(form)

        this.dateInputs.forEach(input => {
            if (input.type == 'range' && input.getValue().length) {
                formData.set($(input.input[0]).attr('name'), `${moment(input.getValue()[0]).format('Y-MM-DD')}/${moment(input.getValue()[1]).format('Y-MM-DD')}`)
            }

            if (input.type == 'single' && input.getValue().length) {
                formData.set($(input.input[0]).attr('name'), moment(input.getValue()[0]).format('Y-MM-DD'))
            }
        })

        Object.keys(this.dropzoneInputs).forEach(inputName => {
            const input = this.dropzoneInputs[inputName]
            const instance = input.getInstance()

            if (instance && instance.getQueuedFiles().length) {
                formData.delete(instance.element.name)

                instance.getQueuedFiles().forEach(file => {
                    let fieldName
                    if (instance.options.maxFiles) {
                        fieldName = `${instance.element.name}[]`
                    } else {
                        fieldName = instance.element.name
                    }

                    if (!input.formbuilderInput) {
                        formData.append(fieldName, file)
                    }
                })
            }
        })

        return formData
    }

    updateForm(data) {
        const template = $(data).find('.template-content')
        const form = $(data).find(`form[name="${this.form.attr('name')}"]`)

        if (template.length) {
            this.form.addClass('d-none')
            this.form.closest('.offcanvas-body').append(template)
        }

        if (form.length) {
            // Set content from response
            this.form.empty()
            this.form.append(form.html())

            // Re-init fields
            this.initFields()

            if (this.form.find('.tab-pane').length) {
                if (this.form.find('.alert').length) {
                    this.step = 1
                    this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
                } else {
                    this.form.find('.tab-pane').removeClass('active show')
                    this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
                    this.PhoneNumberInput ? this.PhoneNumberInput.validate() : () => {}
                }
            } else {
                this.PhoneNumberInput ? this.PhoneNumberInput.validate() : () => {}
            }
        }

        setTimeout(() => {
            this.scrollAfterSubmit()
        }, 50)
    }

    toNextStep() {
        this.step = this.step + 1
        this.form.find('.tab-pane').removeClass('active show')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.invalid-feedback').remove()
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.is-invalid').removeClass('is-invalid')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
    }

    validateStep() {
        // Check if we have steps or just form
        const $fieldContainers = this.form.find('.tab-pane').length
            ? this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)
            : this.form

        // Fields
        const invalidFields = $fieldContainers.find('.is-invalid')
        if (invalidFields.length) {
            return false
        }

        // Invalid feedbacks (+recaptcha)
        const invalidFeedbackMsg = $fieldContainers.find('.invalid-feedback')
        if(invalidFeedbackMsg.length) {
            return false
        }

        return true
    }

    /**
     * Scroll after form submit (markup update)
     */
    scrollAfterSubmit() {
        if(this.validateStep()) {
            this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)
        } else {
            let $scrollElement
            let $fieldContainer

            if (this.form.find('.tab-pane').length) {
                $fieldContainer = this.form.find('.tab-pane.active')
            } else {
                $fieldContainer = this.form
            }

            const $invalidField = $fieldContainer.find('.is-invalid:visible:first')
            const $invalidFeedback = $fieldContainer.find('.invalid-feedback:visible:first')

            $scrollElement = $invalidField.length
                ? $invalidField
                : $invalidFeedback.length ? $invalidFeedback : null

            if($scrollElement) {
                if (!isElementVisible($scrollElement[0], this.form.closest('.offcanvas-body')[0])) {
                    this.form.closest('.offcanvas-body').animate({
                        scrollTop: $scrollElement.offset().top - 50
                    }, 500)
                }
            } else {
                this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)
            }
        }
    }

    destroySelects() {
        this.selects.forEach(selectizeInstance => {
            const $select = selectizeInstance.$input

            // Destroy instance
            selectizeInstance.destroy()

            // Keep value (selectize.js bug)
            // https://stackoverflow.com/questions/27911184/selectize-js-destroy-method-changes-select-value
            // const value = selectizeInstance.getValue() || ''
            $select.val('')

            const inputId = $select.attr('id')
            $('html').off(`click.${inputId}`)
        })

        this.selects = []
    }

    destroyDateInputs() {
        this.dateInputs.forEach(input => {
            input.destroy()
        })
        this.dateInputs = []
    }

    destroyPassengersInputs() {
        this.passengersInputs.forEach(input => {
            input.destroy()
        })
        this.passengersInputs = []
    }
}

export default Forms
