/**
 * Passenger input component
 * [Use Bootstrap Tooltip with HTML content]
 */
import { Tooltip } from 'bootstrap'

class PassengerInput {
    /**
     * PassengerInput class constructor
     * @param {HTMLElement} domElement Input to attach Popover
     * @param {Object} options
     */
    constructor(domElement = null, {
        values = null,
        popoverTemplate = '',
        onChange = null,
    }) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide proper DOM element for PassengerInput class')
        }

        const { name, brick, placeholderSingle, placeholderMultiple } = this.input.data()

        const $row = this.input.closest('.flight-row')
        const rowIndex = $row.data('rowIndex')

        this.name = name
        this.inputId = `${brick}-${name}-${rowIndex}`
        this.inputContainer = this.input.parent()

        this.placeholderSingle = placeholderSingle
        this.placeholderMultiple = placeholderMultiple

        this.isRequired = this.input.prop('required')

        this.popover = null
        this.isYouthInputActive = false

        this.popoverTemplate = popoverTemplate
        this.values = values || {
            adults: 1,
            youth: 0,
            children: 0,
            infants: 0,
        }

        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        this.init()
    }

    init() {
        this.popover = new Tooltip(this.input, {
            html: true,
            sanitize: false,
            container: this.inputContainer,
            customClass: 'passengers-tooltip',
            placement: 'bottom',
            trigger: 'click',
            title: this.createHtmlContent()
        })

        this.setInputValue()
        this.setEventListeners()
    }

    /**
     * Values setter
     * @param {Object} values
     */
    setValue({ adults = 1, youth = 0, children = 0, infants = 0 }, triggerChange = true) {
        // Set states
        this.values = {
            adults,
            youth,
            children,
            infants
        }

        if (this.popover) {
            // Update popover content
            this.popover.setContent({
                '.tooltip-inner': this.createHtmlContent()
            })

            // Set new values to input
            this.setInputValue()
        }

        // OnChange Trigger
        /* @Todo check bug for maximum call stack error
        if (triggerChange && this.onChange) {
            this.onChange({
                name: this.name,
                values: this.values,
            })
        }
        */
    }

    /**
     * Values Getter
     * @returns {Object} values
     */
    getValue() {
        return {
            ...this.values,
            youth: this.isYouthInputActive ? this.values['youth'] : null,
        }
    }

    /**
     * Destructor method
     */
    destroy() {
        // Remove popover
        if(this.popover) {
            this.popover.dispose()
        }

        // Remove event listeners
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        // Outside click
        $('html').on(`click.${this.inputId}`, this.handleOutsideClick.bind(this))

        // Action button handlers
        this.inputContainer.on('click touch', '.passengers-tooltip .btn', this.handleInputChange.bind(this))
    }

    /**
     * Remove all event listeners atatched
     */
    removeEventListeners() {
        $('html').off(`click.${this.inputId}`)

        this.inputContainer.off('click')
    }

    /**
     * Enable "youth" input
     */
    enableYouthInput() {
        this.isYouthInputActive = true

        // Update popover content
        this.popover.setContent({
            '.tooltip-inner': this.createHtmlContent()
        })

        // Set value to input
        this.setInputValue()
    }

    /**
     * Disable "youth" input
     */
    disableYouthInput() {
        this.isYouthInputActive = false

        this.values = {
            ...this.values,
            youth: 0
        }

        // Update popover content
        this.popover.setContent({
            '.tooltip-inner': this.createHtmlContent()
        })

        // Set value to input
        this.setInputValue()
    }

    /**
     * Set value to underlying input
     */
    setInputValue() {
        let total = 0
        Object.keys(this.values).forEach(keyValue => {
            if (keyValue !== 'youth') {
                total += this.values[keyValue]
            } else if(this.isYouthInputActive) {
                total += this.values[keyValue]
            }
        })

        const placeholder = total > 1
            ? this.placeholderMultiple
            : this.placeholderSingle

        this.input.val(`${total} ${placeholder}`)
    }

    /**
     * Field validation method
     * @returns {Boolean}
     */
    validate() {
        // Since we will always have atleast one adult selected...
        return true
    }

    /**
     * Buttons action handler
     * @param {Object} event
     */
    handleInputChange(event) {
        const $button = $(event.currentTarget)
        const $popover = $button.closest('.passengers-tooltip')
        const $preview = $button.parent().find('.num')

        const { type, action } = $button.data()

        // Update value
        let fieldValue = action === 'increment'
            ? this.values[type] + 1
            : this.values[type] - 1

        this.values[type] = fieldValue

        // Set preview
        $preview.text(fieldValue)

        // Set value to input
        this.setInputValue()

        // Validate & set states for next action
        this.setButtonStates($popover)

        // Check infants number
        this.checkInfantsNumber($popover)

        // OnChange Trigger
        if (this.onChange) {
            this.onChange({
                name: this.name,
                values: this.values,
            })
        }
    }

    /**
     * Handler for outside (outside of popover) click action
     * @param {Object} event
     */
    handleOutsideClick(event) {
        if (
            this.inputContainer.has(event.target).length === 0
        ) {
            // Hide & update popover
            this.popover.hide()
            this.popover.setContent({
                '.tooltip-inner': this.createHtmlContent()
            })
        }
    }

    /**
     * Extra check for infants rule (adults <= infants)
     * @param {jQuery} $popover
     */
    checkInfantsNumber($popover = null) {
        let { adults, infants } = this.values
        if (adults < infants) {
            this.values.infants = adults

            const $preview = $popover.find('.num[data-type="infants"]')
            $preview.text(adults)

            // Set value to input
            this.setInputValue()
        }
    }

    /**
     * Create template for popover
     * @returns {String} template
     */
    createHtmlContent() {
        // Clone template & update data
        const template = $('<div />').append(this.popoverTemplate)
        const $popover = $(template)

        const { adults, youth, children, infants } = this.values

        // Set values
        $popover.find('.num[data-type="adults"]').text(adults)
        $popover.find('.num[data-type="children"]').text(children)
        $popover.find('.num[data-type="infants"]').text(infants)

        // Set label
        const $adultLabel = $popover.find('.adults-label')
        const { labelText, labelLhrText } = $adultLabel.data()

        if (this.isYouthInputActive) {
            $adultLabel.text(labelLhrText)
            $popover.find('.youth-input-container').removeClass('d-none')
            $popover.find('.num[data-type="youth"]').text(youth)
        } else {
            $adultLabel.text(labelText)
            $popover.find('.youth-input-container').addClass('d-none')
        }

        // Set action button states
        this.setButtonStates($popover)

        return template.html()
    }

    /**
     * Set enable/disable props to action buttons via rules
     * [Use "attr" instead of "prop" bacuse we use this with html string change]
     * @param {jQuery} $popover
     */
    setButtonStates($popover = null) {
        const { adults, youth, children, infants } = this.values

        const $adultsDecrementButton = $popover.find('.btn[data-action="decrement"][data-type="adults"]')
        const $adultsIncrementButton = $popover.find('.btn[data-action="increment"][data-type="adults"]')

        const $youthDecrementButton = $popover.find('.btn[data-action="decrement"][data-type="youth"]')
        const $youthIncrementButton = $popover.find('.btn[data-action="increment"][data-type="youth"]')

        const $childrenDecrementButton = $popover.find('.btn[data-action="decrement"][data-type="children"]')
        const $childrenIncrementButton = $popover.find('.btn[data-action="increment"][data-type="children"]')

        const $infantsDecrementButton = $popover.find('.btn[data-action="decrement"][data-type="infants"]')
        const $infantsIncrementButton = $popover.find('.btn[data-action="increment"][data-type="infants"]')

        // Min values
        $adultsDecrementButton.attr('disabled', adults <= 1)
        $childrenDecrementButton.attr('disabled', children < 1)
        $infantsDecrementButton.attr('disabled', infants < 1)

        if (this.isYouthInputActive) {
            $youthDecrementButton.attr('disabled', youth < 1)
        }

        // Max values
        if (this.isYouthInputActive) {
            $adultsIncrementButton.attr('disabled', adults + youth + children >= 9)
            $childrenIncrementButton.attr('disabled', adults + youth + children >= 9)
            $youthIncrementButton.attr('disabled', adults + youth + children >= 9)
        } else {
            $adultsIncrementButton.attr('disabled', adults + children >= 9)
            $childrenIncrementButton.attr('disabled', adults + children >= 9)
        }

        $infantsIncrementButton.attr('disabled', adults <= infants)
    }
}

export default PassengerInput
