import TopPanel from './TopPanel'
import FlightsList from './FlightsList'
import initScrollbars from '../../utils/simplebar'

class FlightSchedule {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for FlightSchedule class')
            return
        }

        const { index } = this.container.data()
        const { options, routes, icons, translations } = window[`flight_schedule_${index}_data`]

        this.routes = routes
        this.locations = options

        this.icons = icons
        this.translations = translations

        this.errorTemplate = this.container.find('template.flight-schedule--list-server-error-template').html()

        this.init()
    }

    init() {
        const panelContainer = this.container.find('.flight-schedule--top-wrapper')
        const listContainer = this.container.find('.flight-schedule--list-wrapper')

        this.TopPanel = new TopPanel(panelContainer[0], {
            routes: this.routes,
            locations: this.locations,
            onSubmit: () => this.handleFiltersSubmit()
        })

        this.FlightsList = new FlightsList(listContainer[0], {
            icons: this.icons,
            translations: this.translations,
            onTabChange: type => this.fetchListData(type)
        })
    }

    /**
     * Filter submission handler => Triggered from filter
     */
    async handleFiltersSubmit() {
        try {
            this.TopPanel.setRequestingFlag()
            this.FlightsList.setRequestingFlag()

            // Make request
            const listData = await this.TopPanel.fetchFlightData()

            // Set filters
            const filtersData = this.TopPanel.getValues()
            this.FlightsList.setFiltersData(filtersData)

            // Reset active tab
            this.FlightsList.setActiveTab()

            // Update list markup
            this.FlightsList.setListData(listData)

            this.TopPanel.removeRequestingFlag()
            this.FlightsList.removeRequestingFlag()
            initScrollbars()
        } catch(err) {
            this.TopPanel.removeRequestingFlag()

            this.FlightsList.setListData(this.errorTemplate)
            this.FlightsList.removeRequestingFlag()

            throw err
        }
    }

    /**
     * Fetch list data without filters change => Triggered from list
     * @param {String} type
     */
    async fetchListData(type = null) {
        try {
            this.FlightsList.setRequestingFlag()

            const isReturnFlight = type === 'return'
            const listData = await this.TopPanel.fetchFlightData(isReturnFlight)

            this.FlightsList.setListData(listData)
            this.FlightsList.removeRequestingFlag()
            initScrollbars()

        } catch(err) {
            this.FlightsList.setListData(this.errorTemplate)
            this.FlightsList.removeRequestingFlag()

            throw err
        }

    }
}

export default FlightSchedule
