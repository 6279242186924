import FireBase from './firebase'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// https://felixgerschau.com/web-push-notifications-tutorial/
// https://stackoverflow.com/questions/54996206/firebase-cloud-messaging-where-to-find-public-vapid-key
// https://github.com/makeuseofcode/React-Push-Notifications-Firebase/blob/main/src/firebase.js
class FCM {
    /**
     * Constructor
     * @param {Function} onMessage
     */
    constructor(onMessage = null) {
        try {
            /**
             * @type {FireBase}
             */
            this.FireBase = window.FireBase
            this.MessagingService = getMessaging(this.FireBase.app)

            this.onMessage = null
            if(onMessage && typeof onMessage === 'function') {
                this.onMessage = onMessage
            }

            this.isSupportedByDevice = true

            // Store for event listeners
            this._triggers = {}

            this.init()
        } catch(err) {
            console.error(err)
            this.isSupportedByDevice = false
        }
    }

    // Initialize
    init() {
        this.requestPermission()
    }

    // "on" event listeners on object
    on(event, callBack) {
        if(!this._triggers[event]) {
            this._triggers[event] = []
        }

        this._triggers[event].push(callBack)
    }

    // Event dispatcher for "on" events
    triggerHandler(event, eventData) {
        if(this._triggers[event]) {
            this._triggers[event].forEach(listener => {
                listener({
                    event,
                    data: eventData
                })
            })
        }
    }

    /**
     * Check for user notification request
     */
    requestPermission() {
        if(!process.env.MIX_FIREBASE_WEB_PUSH_KEY) {
            console.error('Missing Firebase Web push key config in environment')
            return
        }

        console.log('Requesting User Notifications Permission...')

        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification User Permission Granted.')

                return getToken(this.MessagingService, { vapidKey: process.env.MIX_FIREBASE_WEB_PUSH_KEY })
                    .then((currentToken) => {
                        if (currentToken) {
                            // Save client device token
                            //console.log('Client Token: ', currentToken)

                            // Call message listener
                            this.onMessageListener()
                        } else {
                            console.log('Failed to generate the app registration token.')
                        }
                    })
                    .catch((err) => {
                        console.log('An error occurred when requesting to receive the token.', err)
                    })
            } else {
                console.warn('Notification User Permission Denied.')
            }
        })
    }

    /**
     * Message listener
     */
    onMessageListener() {
        onMessage(this.MessagingService, (payload) => {
            //console.log('FCM recieved:', payload)

            // Cb function
            if(this.onMessage) {
                this.onMessage(payload)
            }

            // Trigger event listener
            this.triggerHandler('onMessage', payload)
        })
    }
}

export default FCM
