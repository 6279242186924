import SabreMYB from "../booking/quick-booking-panel/components/manage-booking/SabreMYB"
import TextInput from "../../components/fields/TextInput"

class FindMyTrip {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for FindMyTrips class')
            return
        }

        const { index } = this.container.data()
        const { routes } = window[`find_my_trip_${index}_data`]

        this.routes = routes
        this.SabreMYB = new SabreMYB(this.routes.sabre.bookings)

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Initialize method
     */
    init() {
        const bookingInput = this.container.find('.form-input[data-name="booking_code"]')
        const surnameInput = this.container.find('.form-input[data-name="surname"]')

        this.bookingCodeInput = new TextInput(bookingInput[0], {
            popoverValidation: true,
        })

        this.surnameInput = new TextInput(surnameInput[0], {
            popoverValidation: true,
        })
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-search', this.handleSubmit.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Validator method
     * @returns {Boolean}
     */
    validateFields() {
        let valid = true

        if (!this.bookingCodeInput.validate()) {
            valid = false
        }

        if (!this.surnameInput.validate()) {
            valid = false
        }

        return valid
    }

    /**
     * Create payload for Sabre API handler
     */
    createPayload() {
        const payload = {
            [this.bookingCodeInput.getName()]: this.bookingCodeInput.getValue(),
            [this.surnameInput.getName()]: this.surnameInput.getValue()
        }

        return payload
    }

    /**
     * Submit button action handler
     * @param {Object} event
     */
    handleSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const isValid = this.validateFields()
        if(isValid) {
            const payload = this.createPayload()
            this.SabreMYB.engage(payload)
        }
    }
}

export default FindMyTrip
