/**
 * Location input component
 * [Use of custom selectize input with custom dropdown]
 */

import axios from 'axios'
import { Tooltip } from 'bootstrap'

class LocationInput {
    constructor(domElement = null, {
        options = [],
        optionsRoute = '',
        onChange = null,
        popoverValidation = true
    }) {
        this.input = $(domElement)
        if(!this.input) {
            console.error('Please provide proper DOM element fro LocationInput class')
            return
        }

        const { name, type, brick, requiredMessage, loadingMessage } = this.input.data()

        this.name = name
        this.type = type

        this.label = this.input.next('.form-label')
        this.isRequired = this.input.prop('required')

        this.inputContainer = this.input.parent()

        const $row = this.input.closest('.flight-row')
        const rowIndex = $row.data('rowIndex')
        const rowKey = `row_${rowIndex}`

        this.instanceKey = rowKey
        this.inputId = `${brick}-${name}-${rowIndex}`

        this.options = []
        this.optionsData = options || []
        this.defaultOptions = options || []

        this.optionsRoute = optionsRoute
        this.loadingMessage = loadingMessage

        this.selectizeInstance = null

        // Required Tooltip
        this.isValidated = false
        this.requiredPopover = null
        if (this.isRequired && popoverValidation) {
            this.requiredPopover = new Tooltip(this.inputContainer, {
                trigger: 'manual',
                placement: 'bottom',
                customClass: 'required-tooltip',
                title: requiredMessage,
                container: this.input.closest('.input-group')
            })
        }

        // OnChange CB function
        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        this.init()
    }

    init() {
        this.setOptions()
        this.initSelectize()

        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.inputContainer.on('click', () => {
            // Hide popover if shown
            if(this.requiredPopover && this.isValidated) {
                this.isValidated = false
                this.requiredPopover.hide()
            }
        })

        // Outside click
        $('html').on(`click.${this.inputId}`, this.handleOutsideClick.bind(this))
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.inputContainer.off('click')
        $('html').off(`click.${this.inputId}`)
    }

    /**
     * Value setter
     */
    setValue(value = null) {
        if(this.selectizeInstance) {
            this.selectizeInstance.setValue(value)
        }
    }

    /**
     * Value getter
     * @returns {Object} selected value
     */
    getValue() {
        if(this.selectizeInstance) {
            const selectedId = this.selectizeInstance.getValue()
            const selectedData = this.options.filter(item => item.id == selectedId)[0]

            return selectedData || null
        }

        return null
    }

    /**
     * Destructor method
     */
    destroy() {
        if(this.selectizeInstance) {
            this.selectizeInstance.destroy()
        }

        if(this.requiredPopover) {
            this.requiredPopover.dispose()
        }

        // Remove attached event listeners
        this.removeEventListeners()
    }

    /**
     * Field validation
     */
    validate() {
        // Empty field
        if (this.isRequired && !this.getValue() && !this.isValidated) {
            // Show popover if not shown already
            if(!this.isValidated) {
                this.isValidated = true
                this.requiredPopover.show()
            }

            return false
        }

        return true
    }

    /**
     * Fetch new options by connected Id
     * @param {Number} id
     */
    async fetchNewOptions(id = null) {
        try {
            this.setLoadingOptions()

            // Fetch new options
            const requestUri = this.optionsRoute.replace('location_id', id)
            const response = await axios.get(requestUri)

            const { data } = response

            // Update options
            this.updateOptions(data)

        } catch(err) {
            this.resetOptions()
            console.error(err)
            throw err
        }
    }

    /**
     * Reset options to default ones
     */
    resetOptions() {
        this.updateOptions(this.defaultOptions)
    }

    /**
     * Set loading option
     */
    setLoadingOptions() {
        let loadingOptions = []

        // Custom loading option
        const loadingOption = {
            cityName: null,
            code: 'loading-option',
            countryCode: null,
            countryName: null,
            display: null,
            id: this.generateUniqueId(),
        }

        loadingOptions.push(loadingOption)

        // Keep selected option
        const selectedOption = this.getValue()
        if(selectedOption) {
            loadingOptions.push({
                ...selectedOption,
                hide: true,
                countryName: null
            })
        }

        this.updateOptions(loadingOptions)
    }

    /**
     * Update options
     * @param {Array} options
     */
    updateOptions(options = []) {
        this.optionsData = options
        this.setOptions()

        const currentValue = this.selectizeInstance.getValue()
        const isCurrentValueInNewOptions = this.options.map(item => item.id).includes(parseInt(currentValue))

        // Clear options
        this.selectizeInstance.clearOptions(true)
        this.selectizeInstance.clearOptionGroups(true)

        // Add new options
        this.selectizeInstance.addOption(this.options)
        this.selectizeInstance.refreshOptions(false)

        // Select current option if it is in new group
        if (isCurrentValueInNewOptions) {
            this.selectizeInstance.addItem(currentValue, true)
        }
    }

    /**
     * Set options
     */
    setOptions() {
        // Append search field, because user can search it by typing city, country, IATA airport code
        if (this.optionsData) {
            this.options = this.optionsData.map(optionData => {
                return {
                    ...optionData,
                    hide: optionData.hide || false,
                    searchField: `${optionData.display}||${optionData.countryName}`
                }
            })
        }
    }

    /**
     * OnChange handler
     * @param {Number} selectedId
     */
    handleOnChange(selectedId = null) {
        const selectedValue = this.options.filter(item => item.id == selectedId)[0]

        // Trigger onChange listener from parent
        if (this.onChange) {
            this.onChange({
                name: this.name,
                type: this.type,
                instanceKey: this.instanceKey,
                selected: selectedValue
            })
        }
    }

    /**
     * Handler for outside click action
     * @param {Object} event
     */
    handleOutsideClick(event) {
        if (
            this.inputContainer.has(event.target).length === 0
        ) {
            // Trigger blur method (Remove focus and closes dropdown)
            this.selectizeInstance.blur()
        }
    }

    /**
     * Init selectize plugin
     */
    initSelectize() {
        // Selectize initialization
        this.input.selectize({
            maxItems: 1,
            valueField: 'id',
            searchField: 'searchField',
            optgroupField: 'countryName',
            options: this.options,
            optionGroupRegister: function (optGroup) {
                const capitalised = optGroup.charAt(0).toUpperCase() + optGroup.substring(1)
                let group = {
                    label: capitalised
                }

                group[this.settings.optgroupValueField] = optGroup

                return group
            },
            showAddOptionOnCreate: false,
            render: {
                optgroup_header: function(data, escape) {
                    return `<div class="optgroup-header bordered">${escape(data.label)}<span class="divider"></span></div>`
                },
                option: (data, escape) => {
                    if (data.code !== 'loading-option') {
                        return `
                            <div class="option ${data.hide ? 'd-none' : 'd-flex'} flex-row align-items-center">
                                <div class="country-flag fi fi-${data.countryCode.toLowerCase()} fis"></div>
                                <div class="location-data d-flex flex-column">
                                    <div class="city body-1">${escape(data.cityName)}</div>
                                    <div class="code caption-regular">${escape(data.code)}</div>
                                </div>
                            </div>
                        `
                    } else {
                        return `
                            <div class="option loading-option caption-regular text-gray-2 d-flex flex-row justify-content-center align-items-center pe-none p-4">
                                <span class="spinner-grow spinner-grow-sm text-gray-2" role="status" aria-hidden="true"></span>
                                <span class="ms-3 text caption-regular text-gray-2">${this.loadingMessage || ''}</span>
                            </div>
                        `
                    }
                },
                item: function(data, escape) {
                    return `<div class="item">${escape(data.cityName)}</div>`
                }
            },
            create: function() {
                return {
                    id: null,
                    display: '',
                };
            },
            // Floating label logic
            onFocus: () => {
                this.label.addClass('fp-floating-label--focused')
            },
            onBlur: () => {
                this.label.removeClass('fp-floating-label--focused')
            },
            onItemAdd: () => {
                this.label.addClass('fp-floating-label--valued')
            },
            onDelete: () => {
                this.label.removeClass('fp-floating-label--valued')
            },
            onChange: (value) => {
                const trimmedValue = value.trim()

                // Handle label
                if (trimmedValue) {
                    this.label.addClass('fp-floating-label--valued')
                } else {
                    this.label.removeClass('fp-floating-label--valued')
                }

                // OnChange handler
                this.handleOnChange(trimmedValue)
            }
        })

        // Save instance
        this.selectizeInstance = this.input[0].selectize
    }

    /**
     * Generate unique Id
     * @returns {String}
     */
    generateUniqueId() {
        return Math.random().toString(36).substr(2, 9)
    }
}

export default LocationInput
