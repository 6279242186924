import Booking from './components/booking'
import ManageBooking from './components/manage-booking'
import CheckIn from './components/check-in'

class QuickBookingPanel {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for QuickBookingPanel Class')
            return
        }

        // Fetch data
        const { index } = this.container.data()
        const { options, routes } = window[`quick_booking_panel_${index}_data`]

        this.routes = routes
        this.options = options

        this.init()
    }

    init() {
        const bookingTab = this.container.find('.tab-pane[data-tab="booking"]')
        const manageBookingTab = this.container.find('.tab-pane[data-tab="manage-booking"]')
        const checkInTab = this.container.find('.tab-pane[data-tab="check-in"]')

        const passengerPopoverTemplate = this.container.find('template.passengers-popover-template').html()
        const fromInputTemplate = this.container.find('template.location-from-input-template').html()
        const toInputTemplate = this.container.find('template.location-to-input-template').html()
        const dateInputTemplate = this.container.find('template.date-input-template').html()
        const removeFlightButtonTemplate = this.container.find('template.remove-flight-btn-template').html()

        const options = {
            options: this.options,
            templates: {
                fromInput: fromInputTemplate,
                toInput: toInputTemplate,
                dateInput: dateInputTemplate,
                passengerPopover: passengerPopoverTemplate,
                removeFlightButton: removeFlightButtonTemplate
            },
            routes: this.routes,
        }

        this.bookingTab = new Booking(bookingTab[0], options)
        this.manageBookingTab = new ManageBooking(manageBookingTab[0], options)
        this.checkInTab = new CheckIn(checkInTab[0], options)
    }
}

export default QuickBookingPanel
