import { Offcanvas } from "bootstrap"

class DynamicDrawer {
    constructor({
        id = this.generateUniqueId(),
        content = '',
        options = {},
        className = '',
        onShow = null,
        onHide = null,
    }) {
        this.drawerId = id
        this.content = content
        this.className = className
        this.drawerOptions = options

        this.domElement = null
        this.drawerInstance = null

        this.onShow = null
        if(onShow && typeof onShow === 'function') {
            this.onShow = onShow
        }

        this.onHide = null
        if(onHide && typeof onHide === 'function') {
            this.onHide = onHide
        }

        this.init()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        this.drawerInstance = null
        this.domElement.remove()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        if (this.domElement) {
            this.domElement.on('shown.bs.offcanvas', this.handleShow.bind(this))
            this.domElement.on('hidden.bs.offcanvas', this.handleClose.bind(this))
        }
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        if (this.domElement) {
            this.domElement.off('shown.bs.offcanvas')
            this.domElement.off('hidden.bs.offcanvas')
        }
    }

    /**
     * Initialize
     */
    init() {
        const markup = this.createMarkup()
        $('body').append(markup)

        this.domElement = $('body').find(`#${this.drawerId}`)
        this.drawerInstance = new Offcanvas(this.domElement[0], this.drawerOptions)

        this.setEventListeners()
    }

    /**
     * Show drawer
     */
    show() {
        this.drawerInstance.show()
    }

    /**
     * Hide drawer
     */
    hide() {
        this.drawerInstance.hide()
    }

    /**
     * Toggle drawer (Hide/Show)
     */
    toggle() {
        this.drawerInstance.toggle()
    }

    /**
     * Drawer ID getter (Eg. use it in trigger)
     * @returns {String}
     */
    getDrawerId() {
        return this.drawerId
    }

    /**
     * Handler for show event
     * @param {Object} event
     */
    handleShow(event) {
        if(this.onShow) {
            this.onShow(event)
        }
    }

    /**
     * Handler for hide event
     * @param {Object} event
     */
    handleClose(event) {
        if(this.onHide) {
            this.onHide(event)
        }
    }

    createMarkup() {
        return `
            <div
                tabindex="-1"
                id="${this.drawerId}"
                class="offcanvas offcanvas-end dynamic-drawer ${this.className}"
            >
                <div class="offcanvas-header justify-content-end">
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="offcanvas"
                    ></button>
                </div>
                <div class="offcanvas-body ${this.className ? `${this.className}--body` : ''} px-6 pb-5">
                    ${this.content}
                </div>
            </div>
        `
    }

    /**
     * Generate unique Id
     * @returns {String}
     */
    generateUniqueId() {
        return Math.random().toString(36).substr(2, 9)
    }
}

export default DynamicDrawer
