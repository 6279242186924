/**
 * AreaBrickBundle main script file
 */

// jQuery
import $ from 'jquery'
window.$ = window.jQuery = $

/**
 * Components
 */
import ButtonBrick from './components/Button'
import RegularDrawerBrick from './components/RegularDrawer'
import TableBrick from './components/Table'
import HeaderTicker from './components/HeaderTicker'


/**
 * Initialization
 */

$('.button-container').each(function() {
    new ButtonBrick(this)
})

$('.table-areabrick').each(function() {
    new TableBrick(this)
})

$('.regular-drawer-container').each(function() {
    new RegularDrawerBrick(this)
})

new HeaderTicker($('.header-ticker-container'))
