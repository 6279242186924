import PassengerInputOld from '../../bricks/booking/main-booking-panel/parts/fields/PassengerInput'

class PassengerInput extends PassengerInputOld {
    constructor(domElement = null, {
        values = null,
        popoverTemplate = '',
        onChange = null,
    }) {
        super(domElement, {values, popoverTemplate, onChange})

        this.values = values || {
            adults: 1
        }
    }

    init() {
        this.input.addClass('d-none')
        this.inputContainer.append(this.createHtmlContent())

        this.setInputValue()
        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        // Action button handlers
        this.inputContainer.on('click touch', '.passengers-input .btn', this.handleInputChange.bind(this))
    }

    /**
     * Buttons action handler
     * @param {Object} event
     */
    handleInputChange(event) {
        const $button = $(event.currentTarget)
        const $popover = $button.closest('.passengers-input')
        const $preview = $button.parent().find('.num')

        const { type, action } = $button.data()

        // Update value
        let fieldValue = action === 'increment'
            ? this.values[type] + 1
            : this.values[type] - 1

        this.values[type] = fieldValue

        // Set preview
        $preview.text(fieldValue)

        // Set value to input
        this.setInputValue()

        // Validate & set states for next action
        this.setButtonStates($popover)

        // OnChange Trigger
        if (this.onChange) {
            this.onChange({
                name: this.name,
                values: this.values,
            })
        }
    }

    /**
     * Set value to underlying input
     */
    setInputValue() {
        const total = Object.values(this.values).reduce((acc, value) => acc + value, 0)

        this.input.val(total)
    }

    /**
     * Create template for popover
     * @returns {String} template
     */
    createHtmlContent() {
        // Clone template & update data
        const template = $('<div />').append(this.popoverTemplate)
        const $popover = $(template)

        const { adults } = this.values

        // Set values
        $popover.find('.num[data-type="adults"]').text(adults)

        // Set action button states
        this.setButtonStates($popover)

        return template.html()
    }

    /**
     * Set enable/disable props to action buttons via rules
     * [Use "attr" instead of "prop" bacuse we use this with html string change]
     * @param {jQuery} $popover
     */
    setButtonStates($popover = null) {
        const { adults } = this.values

        const $adultsDecrementButton = $popover.find('.btn[data-action="decrement"][data-type="adults"]')
        const $adultsIncrementButton = $popover.find('.btn[data-action="increment"][data-type="adults"]')

        // Min values
        $adultsDecrementButton.attr('disabled', adults <= 1)

        // Max values
        // $adultsIncrementButton.attr('disabled', adults >= 9)
    }

    /**
     * Destructor method
     */
    destroy() {
        // Remove added class and added HTML
        this.input.removeClass('d-none')
        this.inputContainer.find('.passengers-input').remove()

        // Remove event listeners
        this.removeEventListeners()
    }
}

export default PassengerInput
