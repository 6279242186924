import FlightsList from './List'
import TopPanel from './TopPanel'

class FlightStatus {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for FlightStatus class')
            return
        }

        const { index } = this.container.data()
        const { options, routes, icons, translations } = window[`flight_status_${index}_data`]

        this.routes = routes
        this.locations = options

        this.icons = icons
        this.translations = translations

        this.templates = {
            serverError: this.container.find('template.flight-status--list-server-error-template').html(),
            sitaFetchingError: this.container.find('template.flight-status--list-timeout-error-template').html()
        }

        this.init()
    }

    init() {
        const panelContainer = this.container.find('.flight-status--top-wrapper')
        const listContainer = this.container.find('.flight-status--list-wrapper')

        this.TopPanel = new TopPanel(panelContainer[0], {
            routes: this.routes,
            locations: this.locations,
            onSubmit: () => this.handleFiltersSubmit()
        })

        this.FlightsList = new FlightsList(listContainer[0], {
            icons: this.icons,
            routes: this.routes,
            templates: this.templates,
            translations: this.translations,
        })
    }

    /**
     * Top panel filters submission
     */
    async handleFiltersSubmit() {
        try {
            this.TopPanel.setRequestingFlag()

            // Set selected formated date value
            const formatedDates = this.TopPanel.getFormattedDateValues()
            this.FlightsList.setSelectedDate(formatedDates[0])

            // Fetch list data with provided payload
            const payload = this.TopPanel.createPayload()
            await this.FlightsList.fetchListData(payload)

            this.TopPanel.removeRequestingFlag()
        } catch(err) {
            this.TopPanel.removeRequestingFlag()
            throw err
        }
    }
}

export default FlightStatus
