/**
 * Registration detail class
 */
import FireBase from '../../../../utils/firebase'
import { Tooltip } from 'bootstrap'

class CredentialsStep {
    constructor(domElement = null, {
        onSubmit = null
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provider propper DOM element for RegistrationDetail class')
            return
        }

        // Firebase util
        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        const {
            registerMessages
        } = window.errorMesages

        this.errorMesages = registerMessages

        this.onSubmit = null
        if (onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.isPasswordPopooverShown = false
        const passwordInput = this.container.find('.form-input[data-name="password"]')
        this.passwordPatternPopover = new Tooltip(passwordInput.parent(), {
            html: true,
            sanitize: false,
            trigger: 'manual',
            placement: 'bottom',
            customClass: 'required-tooltip',
            title: this.errorMesages.password_pattern_text,
            container: passwordInput.parent().parent()
        })

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.clearValues()
        this.removeEventListeners()
        this.FireBase = null
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-step-submit', this.handleStepSubmit.bind(this))
        this.container.on('click', '.form-input[data-name="password"], .form-input[data-name="password_confirm"]', () => {
            if(this.isPasswordPopooverShown) {
                this.passwordPatternPopover.hide()
                this.isPasswordPopooverShown = false
            }
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Step submission action handler
     */
    async handleStepSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const $button = $(event.currentTarget)

        if($button.hasClass('is-requesting')) {
            return
        }

        $button.addClass('is-requesting')

        try {
            const isValid = await this.validateFields()
            $button.removeClass('is-requesting')

            if(isValid && this.onSubmit) {
                this.onSubmit()
            }
        } catch(err) {
            $button.removeClass('is-requesting')
            throw err
        }

    }

    /**
     * Values getter
     * @returns {Object} Key-value object with name as key and value
     */
    getValues() {
        const payload = {}
        const fields = this.container.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)
            const value = $field.val()
            const { name } = $field.data()

            if(value) {
                payload[name] = value
            }
        })

        return payload
    }

    /**
     * Clear field values
     */
    clearValues() {
        const fields = this.container.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)

            // Clear value
            $field.val('')

            // Clear errors
            this.clearFieldError($field)
        })

        // Hide password popover
        if(this.isPasswordPopooverShown) {
            this.passwordPatternPopover.hide()
            this.isPasswordPopooverShown = false
        }
    }

    /**
     * Form fields validation
     * @returns {Boolean} true - No for errors
     */
    async validateFields() {
        try {
            let valid = true
            const fields = this.container.find('.form-input')

            // Use "old" classic for loop aproach so we can use "await"
            for (let index = 0; index < fields.length; index++) {
                const $field = $(fields[index])

                const { name } = $field.data()

                const value = $field.val()
                const isRequired = $field.prop('required')

                // Empty field
                if (isRequired && !value) {
                    this.setFieldError($field, this.errorMesages.required_field)

                    valid = false
                    continue
                }

                // Email field
                if (name === 'email') {
                    // Pattern check
                    if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                        this.setFieldError($field, this.errorMesages.email_error)

                        valid = false
                        continue
                    }

                    // Availability check on Firebase
                    const isEmailUsed = await this.FireBase.checkEmailAvailability(value)
                    if (!isEmailUsed) {
                        this.setFieldError($field, this.errorMesages.email_response_error)

                        valid = false
                        continue
                    }
                }

                // Email confirmation
                if (name === 'email_confirm') {
                    const emailFieldValue = this.container.find('.form-input[data-name="email"]').val()
                    if(value !== emailFieldValue) {
                        this.setFieldError($field, this.errorMesages.email_match_error)

                        valid = false
                        continue
                    }
                }

                // Password
                if (name === 'password') {
                    if(!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}$/)) {
                        // Show Popover
                        if(!this.isPasswordPopooverShown) {
                            this.passwordPatternPopover.show()
                            this.isPasswordPopooverShown = true
                        }

                        this.setFieldError($field, this.errorMesages.password_pattern_error)

                        valid = false
                        continue
                    }
                }

                // Password confirmation
                if (name === 'password_confirm') {
                    const passwordFieldValue = this.container.find('.form-input[data-name="password"]').val()
                    if(value !== passwordFieldValue) {
                        this.setFieldError($field, this.errorMesages.password_match_error)

                        valid = false
                        continue
                    }
                }

                // Clear errors
                this.clearFieldError($field)
            }

            return valid
        } catch (err) {
            throw err
        }
    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        $feedback.text('')
        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }
}

export default CredentialsStep
