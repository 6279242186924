class FAQSearch {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            return
        }

        const { searchRoute, perPage } = this.container.data()
        this.searchRoute = searchRoute
        this.perPage = perPage
        this.searchInput = this.container.find('.search-input')
        this.searchButton = this.container.find('.search-button')

        this.setEventListeners()
    }

    setEventListeners() {
        this.searchButton.on('click', () => {
            this.initiateSearch()
        })

        this.searchInput.on('keypress', (event) => {
            if (event.key == 'Enter') {
                this.initiateSearch()
            }
        })
    }

    initiateSearch() {
        if (this.searchInput.val() && this.searchRoute) {
            const url = new URL(window.location.origin + this.searchRoute)
            if (this.perPage) {
                url.searchParams.set('perPage', this.perPage)
            }
            url.searchParams.set('q', this.searchInput.val())
            
            window.location = url.href
        }
    }
}

export default FAQSearch