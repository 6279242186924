import axios from 'axios'
import Input from '../../booking/main-booking-panel/parts/fields/LocationInput'

class LocationInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)
    }

    /**
     * Fetch new options by connected Id
     * @param {String} queryParams
     */
    async fetchNewOptions(queryParams = '') {
        try {

            // Fetch new options
            const requestUri = `${this.optionsRoute}?${queryParams}`
            const response = await axios.get(requestUri)

            const { data } = response

            // Update options
            this.updateOptions(data)

        } catch(err) {
            console.error(err)
            throw err
        }
    }
}

export default LocationInput