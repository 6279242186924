import Maureva from '../../../utils/maureva'
import { Offcanvas } from 'bootstrap'

class PasswordForgot {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for PasswordForgot class')
            return
        }

        // Offcanvas instance
        this.drawer = new Offcanvas(this.container[0], {})

        // Maureva utils init
        this.Maureva = new Maureva()

        const {
            passwordForgotMessages,
        } = window.errorMesages

        this.errorMesages = passwordForgotMessages

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        // Reset steps when closed
        this.container.on('hidden.bs.offcanvas', () => {
            this.setTabStep(1)
            this.clearValues()
        })

        this.container.on('submit', 'form', event => {
            event.preventDefault()
            event.stopPropagation()
        })

        this.container.on('click', '.btn-submit', this.handleSubmit.bind(this))
    }

    /**
     * Remove atatched event listeners
     */
    removeEventListeners() {
        this.container.off('click')
        this.container.off('submit')
        this.container.off('hidden.bs.offcanvas')
    }

    /**
     * Show login drawer
     */
    showDrawer() {
        this.drawer.show()
    }

    /**
     * Hide login drawer
     */
    hideDrawer() {
        this.drawer.hide()
    }

    /**
     * Submit handler
     * @param {Object} event
     */
    async handleSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        // Disable multiple requests
        const $button = $(event.currentTarget)
        if($button.hasClass('is-requesting')) {
            return
        }

        // Validation
        const isValid = this.validateFields()
        if(!isValid) {
            return
        }

        try {
            $button.addClass('is-requesting')

            const { userEmail } = this.getValues()
            await this.Maureva.passwordForgot(userEmail)

            $button.removeClass('is-requesting')
            this.setTabStep(2)

        } catch(err) {
            $button.removeClass('is-requesting')

            console.error(err)
            throw err
        }
    }

    /**
     * Form fields validation
     * @returns {Boolean} true - No for errors
     */
    validateFields() {
        let valid = true
        const fields = this.container.find('.form-input')

        fields.each((_, item) => {
            const $field = $(item)

            const { name } = $field.data()

            const value = $field.val()
            const isRequired = $field.prop('required')

            // Empty field
            if (isRequired && !value) {
                this.setFieldError($field, this.errorMesages.required_field)

                valid = false
                return true
            }

            // Email field
            if (name === 'userEmail') {
                // Pattern check
                if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.setFieldError($field, this.errorMesages.email_error)

                    valid = false
                    return true
                }
            }

            this.clearFieldError($field)
        })

        return valid
    }

    /**
     * Values getter
     */
    getValues() {
        const fieldValues = {}
        const fields = this.container.find('.form-input')

        fields.each((_, field) => {
            const $field = $(field)

            const { name } = $field.data()
            const value = $field.val()

            fieldValues[name] = value
        })

        return fieldValues
    }

    /**
     * Clear field values
     */
    clearValues() {
        const fields = this.container.find('.form-input')

        fields.each((_, field) => {
            const $field = $(field)

            $field.val('')
            this.clearFieldError($field)
        })
    }

    /**
     * Set step
     */
    setTabStep(step = 1) {
        const $step = this.container.find(`.tab-pane[data-tab="step-${step}"]`)

        this.container.find('.tab-pane').removeClass('show active')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 175)
    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()

        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-n5 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `

        // Each of tabs have it's own alert container
        const $activeTab = this.container.find('.tab-pane.active')
        $activeTab.find('.password-forgot-form--alert-container').html(markup)
    }
}

export default PasswordForgot
