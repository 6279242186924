import axios from 'axios'
import Input from '../../booking/main-booking-panel/parts/fields/LocationInput'

class LocationInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)
    }

    /**
     * Fetch new options by connected Id
     * @param {Number} locationId
     * @param {String} queryParams
     */
    async fetchNewOptions(locationId = null, queryParams = '') {
        try {

            // Fetch new options
            let requestUri = this.optionsRoute.replace('location_id', locationId)
            requestUri = `${requestUri}?${queryParams}`

            // Make request
            const response = await axios.get(requestUri)
            const { data } = response

            // Update options
            this.updateOptions(data)

        } catch(err) {
            console.error(err)
            throw err
        }
    }
}

export default LocationInput