import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class IPortCheckIn {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        this.defaults = {}
    }

    engage(payload = {}) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        window.open(redirectUrl, '_blank')
    }

    formatPayload(payload = {}) {
        const data = {}
        Object.keys(payload).forEach(inputName => {
            const value = payload[inputName]

            if (inputName === 'booking_code') {
                const isTicketNumber = /^\d+$/.test(value)

                if (isTicketNumber) {
                    data['request.EticketNumber'] = value
                } else {
                    data['request.BookingCode'] = value
                }
            } else if (inputName === 'location_from') {
                // Airport code
                const { code } = value
                data['request.BoardingPoint'] = code
            }
        })

        return {
            ...this.defaults,
            ...data,
        }
    }
}

export default IPortCheckIn
