/**
 * PersonalData step class component
 */
class PersonalData {
    /**
     * Constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.form = $(domElement)
        if(!this.form.length) {
            console.error('Please provide proper DOM element for Complaint/PersonalData class')
            return
        }

        this.setEventListeners()
        this.setSubmitButtonState()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('input', '[data-tab="step-3"] .email-input', event => {
            this.setSubmitButtonState(true)
        })

        this.form.on('input', '[data-tab="step-3"] .email-input-repeat', event => {
            this.setSubmitButtonState(true)
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('input')
    }

    /**
     * Set submit button state
     */
    setSubmitButtonState(showError = false) {
        const $submitButton = this.form.find('[data-tab="step-3"] button[type="submit"]')
        const $emailField = this.form.find('[data-tab="step-3"] .email-input')
        const $emailRepeatField = this.form.find('[data-tab="step-3"] .email-input-repeat')

        const validEmail = this.validateInput($emailField, showError)
        const validEmailRepeat = this.validateInput($emailRepeatField, showError)

        const isValid = validEmail && validEmailRepeat

        if(!isValid) {
            $submitButton.addClass('personal-step-disabled')
        } else {
            $submitButton.removeClass('personal-step-disabled')
        }

        //$submitButton.prop('disabled', !isValid)
    }

    /**
     * Single input validation
     * @param {Jquery} $input
     */
    validateInput($input, showError = true) {
        if (!this.validateNotBlank($input, showError)) {
            return false
        }

        if (!this.validateMailStructure($input, showError)) {
            return false
        }

        if (!this.validateEmailRepeat(showError)) {
            return false
        }

        return true
    }

    /**
     * Check if field is empty
     * @param {JQuery} $input
     * @returns {Boolean}
     */
    validateNotBlank($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')
        $inputErrorMsg.html('')

        if (!$input.val().length && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.html(msg.empty)

            return false
        }

        return true
    }

    /**
     * Check for e-mail structure
     * @param {Juqery} $input
     * @returns {Boolean}
     */
    validateMailStructure($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')
        $inputErrorMsg.html('')

        // 99.99% accuracy => RFC 5322 Format
        const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

        if (!regex.test($input.val()) && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.html(msg.invalid)

            return false
        }

        return true
    }

    /**
     * Check wheter values are same
     * @returns {Boolean}
     */
    validateEmailRepeat(showError = true) {
        const $emailField = this.form.find('[data-tab="step-3"] .email-input')
        const $emailRepeatField = this.form.find('[data-tab="step-3"] .email-input-repeat')

        const $inputContainer = $emailRepeatField.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        const isSame = $emailField.val().length && $emailField.val() === $emailRepeatField.val()

        if(!isSame && $emailRepeatField.val().length && showError) {
            $emailRepeatField.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.html(msg.no_match)
        } else {
            $emailRepeatField.removeClass('is-invalid')
            $inputContainer.removeClass('is-invalid')

            $inputErrorMsg.html('')
        }

        return isSame
    }
}

export default PersonalData
