import moment from 'moment'
import { Offcanvas } from 'bootstrap'
import { Tooltip } from 'bootstrap'

import FireBase from '../../../utils/firebase'
import Maureva from '../../../utils/maureva'

import DateInput from '../../../bricks/booking/quick-booking-panel/fields/DateInput'
import PhoneNumberInput from '../../../components/fields/PhoneNumber'

class ChangeDetails {
    constructor(domElement = null, {
        onSubmit = null,
        onLogout = null,
    }) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide proper DOM element for ChangeDetails class')
            return
        }

        // Offcanvas instance
        this.drawer = new Offcanvas(this.container[0], {
            backdrop: 'static' // Outside click won't close drawer
        })

        const {
            country,
            language,
            displayRegions,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.localizedRegions = Object.keys(displayRegions).reduce((acc, k) => {
            acc[k.toLowerCase()] = displayRegions[k]
            return acc
        }, {})

        const {
            profileUpdateMessages
        } = window.errorMesages

        this.errorMesages = profileUpdateMessages

        this.userData = null
        this.isSocialLogin = null
        this.submitButton = this.container.find('.btn-submit')

        this.tabFieldsContainer = this.container.find('.tab-content')
        this.firebaseReauthContainer = this.container.find('.firebase-reauth-error')

        /**
         * @type UserAuth
         */
        this.UserAuth = window.UserAuth

        this.Maureva = new Maureva()

        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        this.onSubmit = null
        if(onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.onLogout = null
        if(onLogout && typeof onLogout === 'function') {
            this.onLogout = onLogout
        }

        this.isPasswordPopooverShown = false
        const passwordInput = this.container.find('.form-input[data-name="password_change"]')
        this.passwordPatternPopover = new Tooltip(passwordInput.parent(), {
            html: true,
            sanitize: false,
            trigger: 'manual',
            placement: 'bottom',
            customClass: 'required-tooltip',
            title: this.errorMesages.password_pattern_text,
            container: passwordInput.parent().parent()
        })

        this.init()
        this.setUserData()
        this.setEventListeners()
    }

    setEventListeners() {
        this.container.on('show.bs.offcanvas', () => {
            // Hide password pattern
            this.passwordPatternPopover.hide()
            this.isPasswordPopooverShown = false

            // Clear form errors
            this.clearFormErrors()

            // Fill fields
            this.fillFormFields()

            // Reset field containers
            this.tabFieldsContainer.removeClass('d-none')
            this.firebaseReauthContainer.addClass('d-none')

            this.setTabStep(1)
        })

        this.container.on('click', '.btn-submit', this.handleSubmit.bind(this))
        this.container.on('click', '.form-input[data-name="password_change"], .form-input[data-name="password_confirm_change"]', () => {
            if(this.isPasswordPopooverShown) {
                this.passwordPatternPopover.hide()
                this.isPasswordPopooverShown = false
            }
        })

        // Logout if firebase needs recent logout
        this.container.on('click', '.btn-logout', this.handleLogout.bind(this))

        // Listen for Auth changes
        this.UserAuth.on('onAuthChange', (event) => {
            // Set new user data
            const { data } = event
            this.setUserData(data)
        })
    }

    init() {
        const phoneField = this.container.find('.form-input[data-input-type="phone-input"]')
        this.PhoneNumberInput = new PhoneNumberInput(phoneField[0], {
            initialCountry: this.userCountry, // Selected country from header
            localizedCountries: this.localizedRegions, // Localized regions
            options: {} // Extra options
        })

        const dateInput = this.container.find('.form-input[data-input-type="date-input"]')
        this.DateInput = new DateInput(dateInput[0], {
            popoverValidation: false,
            extraOptions: {
                minDate: null,
                position: 'above',
                dateFormat: 'd/m/Y',
            }
        })

        this.checkIfUserIsSocialLogged()
    }

    /**
     * Check if logged user is social logged
     */
    async checkIfUserIsSocialLogged() {
        this.isSocialLogin = await this.UserAuth.isUserSocialLogged()

        // Handle visibility of credential fields
        this.setFieldsByUserType()
    }

    /**
     * Logout handler
     */
    handleLogout(event) {
        const $button = $(event.currentTarget)
        $button.addClass('is-requesting')

        // Trigger CB function
        if(this.onLogout){
            this.onLogout()
        }
    }

    /**
     * Submit handler
     */
    async handleSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const $button = $(event.currentTarget)

        if($button.hasClass('is-requesting')) {
            return
        }

        $button.addClass('is-requesting')

        const isValid = await this.validateFields()
        if(isValid) {
            this.handleSave()
        } else {
            $button.removeClass('is-requesting')
        }
    }

    /**
     * Save action
     */
    async handleSave() {
        const $button = this.container.find('.btn-submit')

        try {
            $button.addClass('is-requesting')

            // Create payload
            const payload = this.createPayload()

            // Call Auth handler
            const response = await this.UserAuth.updateUserData(payload)
            this.handleMaurevaResponse(response.data)

            $button.removeClass('is-requesting')
        } catch(error) {
            if(error.response && error.response.status) {
                // Generic server error/Maureva response
                this.showAlert(this.errorMesages.firebase_generic_error)
            }

            // Firebase needs recent login
            if(error && error.code === 'auth/requires-recent-login') {
                this.showFirebaseSessionError()
            }

            $button.removeClass('is-requesting')
            throw error
        }
    }

    /**
     * Set current User data or fetch from localStorage
     */
    setUserData(user = null) {
        let userData = user
        if(!userData) {
            userData = localStorage.getItem('user_data')
                ? JSON.parse(localStorage.getItem('user_data'))
                : null
        }

        if (!userData) {
            return
        }

        this.userData = this.mapUserDataForFormFields(userData)
    }

    /**
     * Form fields validation
     * @returns {Promise}
     */
    async validateFields() {
        let validCredentials = true
        if (!this.isSocialLogin) {
            validCredentials = await this.validateCredentialFields()
        }

        const validDetails = this.validateDetailFields()

        return validCredentials && validDetails
    }

    /**
     * Credential Fields validation
     */
    async validateCredentialFields() {
        try {
            let valid = true
            const fields = this.container.find('.credential-fields .form-input')

            // Use "old" classic for loop aproach so we can use "await"
            for (let index = 0; index < fields.length; index++) {
                const $field = $(fields[index])

                const { name } = $field.data()
                const inputName = name.replace('_change', '')

                const value = $field.val()
                const isRequired = $field.prop('required')

                // Empty field
                if (isRequired && !value) {
                    this.setFieldError($field, this.errorMesages.required_field)

                    valid = false
                    continue
                }

                // Email field
                if (inputName === 'email') {
                    // Pattern check
                    if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                        this.setFieldError($field, this.errorMesages.email_error)

                        valid = false
                        continue
                    }

                    // Availability check on Firebase (if user changed it's initial email)
                    if (value !== this.userData.email) {
                        const isEmailUsed = await this.FireBase.checkEmailAvailability(value)
                        if (!isEmailUsed) {
                            this.setFieldError($field, this.errorMesages.email_response_error)

                            valid = false
                            continue
                        }
                    }
                }

                // Email confirmation
                if (inputName === 'email_confirm') {
                    const emailFieldValue = this.container.find('.form-input[data-name="email_change"]').val()
                    if(value !== emailFieldValue) {
                        this.setFieldError($field, this.errorMesages.email_match_error)

                        valid = false
                        continue
                    }
                }

                // Password
                if (inputName === 'password') {
                    if(!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}$/)) {
                        // Show Popover
                        if(!this.isPasswordPopooverShown) {
                            this.passwordPatternPopover.show()
                            this.isPasswordPopooverShown = true
                        }

                        this.setFieldError($field, this.errorMesages.password_pattern_error)

                        valid = false
                        continue
                    }
                }

                // Password confirmation
                if (inputName === 'password_confirm') {
                    const passwordFieldValue = this.container.find('.form-input[data-name="password_change"]').val()
                    if(value !== passwordFieldValue) {
                        this.setFieldError($field, this.errorMesages.password_match_error)

                        valid = false
                        continue
                    }
                }

                // Clear errors
                this.clearFieldError($field)
            }

            return valid

        } catch(err) {
            throw err
        }
    }

    /**
     * Validate detail fields
     */
    validateDetailFields() {
        let valid = true
        const fields = this.container.find('.detail-fields .form-input[type="text"], .detail-fields .form-input[type="tel"]')

        fields.each((_, field) => {
            const $field = $(field)
            const { name } = $field.data()
            const inputName = name.replace('_change', '')

            const isRequired = $field.prop('required')

            if(inputName === 'firstname' || inputName === 'lastname') {
                const value = $field.val()

                if(isRequired && !value) {
                    this.setFieldError($field, this.errorMesages.required_field)

                    valid = false
                    return true
                }
            }

            if(inputName === 'phone_number') {
                const { isValid } = this.PhoneNumberInput.validate()
                if(!isValid) {
                    valid = false
                    return true
                }
            }

            if(inputName === 'date_of_birth') {
                const isValidDate = this.DateInput.validate()
                if(!isValidDate) {
                    valid = false
                    return true
                }
            }

            // Clear errors
            this.clearFieldError($field)
        })

        return valid
    }

    /**
     * Create payload for save action
     */
    createPayload() {
        const payload = {}

        // Credential fields
        const credentialFields = this.container.find('.credential-fields .form-input')
        credentialFields.each((_, item) => {
            const $field = $(item)
            const value = $field.val()
            const { name } = $field.data()
            const inputName = name.replace('_change', '')

            if(value) {
                payload[inputName] = value
            }
        })

        // Detail fields
        const detailFields = this.container.find('.detail-fields .form-input[type="text"], .detail-fields .form-input[type="tel"], .detail-fields .form-input[type="checkbox"], .detail-fields .form-input[type="radio"]:checked')
        detailFields.each((_, field) => {
            const $field = $(field)
            const { name } = $field.data()
            const inputName = name.replace('_change', '')

            if(inputName === 'phone_number') {
                const phoneValues = this.PhoneNumberInput.getValue()
                payload['mobilePhone'] = phoneValues

            } else if(inputName === 'date_of_birth') {
                const dobValue = this.DateInput.getValue()
                if(dobValue.length) {
                    const dobDate = dobValue[0]
                    const dobFormatted = moment(dobDate).toISOString()

                    payload['dateOfBirth'] = dobFormatted
                }

            } else {
                if ($field.is(':checkbox')) {
                    payload[inputName] = $field.is(':checked')
                } else {
                    const value = $field.val()
                    payload[inputName] = value.replace('_change', '')
                }
            }
        })

        // Extra fields
        payload['login'] = payload['email']
        payload['displayName'] = `${payload['firstname']} ${payload['lastname']}`

        // Merge current data with new one
        return {
            ...this.userData,
            ...payload,
        }
    }

    /**
     * Set fields by user type (social/not)
     */
    setFieldsByUserType() {
        const fields = this.container.find('.credential-fields .form-input')
        fields.each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()
            const inputName = name.replace('_change', '')

            if (inputName === 'email') {
                this.isSocialLogin ? $field.prop('disabled', true) : $field.prop('disabled', false)
            } else {
                this.isSocialLogin
                    ? $field.parent().addClass('d-none').removeClass('d-block')
                    : $field.parent().addClass('d-block').removeClass('d-none')
            }
        })
    }

    /**
     * Fill form fields with provided user data
     */
    fillFormFields() {
        const fields = this.container.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)

            // Remove "_change" prefix
            const { name } = $field.data()
            const inputName = name.replace('_change', '')

            const prefillValue = this.userData[inputName]

            // Fill values
            if (inputName === 'phone_number') {
                this.PhoneNumberInput.setValue(prefillValue)
            } else if(inputName === 'date_of_birth') {
                this.DateInput.setValue(prefillValue)
            } else if(inputName === 'title' || inputName === 'gender') {
                const inputValue = $field.val()
                if (prefillValue === inputValue.replace('_change', '')) {
                    $field.prop('checked', true)
                } else {
                    $field.prop('checked', false)
                }
            } else if (inputName === 'commercialNotificationActivated') {
                $field.prop('checked', prefillValue)
            } else {
                $field.val(prefillValue)
            }
        })
    }

    /**
     * Map user data for form fields
     * @param {Object} data
     * @returns {Object}
     */
    mapUserDataForFormFields(data = {}) {
        // Create new object
        const userData = { ...data }

        const {
            mobilePhone,
            dateOfBirth,
            commercialNotificationActivated
        } = userData

        let phone_number = ''
        if (mobilePhone && mobilePhone.number) {
            phone_number = `${mobilePhone.areaCode ? mobilePhone.areaCode : ''}${mobilePhone.number}`
        }

        delete userData.mobilePhone

        let date_of_birth = []
        if (dateOfBirth) {
            date_of_birth = [ moment(dateOfBirth).toDate() ]
        }

        delete userData.dateOfBirth

        return {
            ...userData,
            phone_number,
            date_of_birth,
            commercialNotificationActivated: commercialNotificationActivated || false
        }
    }

    /**
     * Maureva response handler
     */
    handleMaurevaResponse(responseData = {}) {
        const { successfulReply } = responseData

        if(!successfulReply) {
            // Show alert from Maureva API
            this.showAlert(this.errorMesages.maureva_profile_update_error)
        } else {
            // Go to success step
            this.setTabStep(2)
        }
    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        $feedback.text('')
        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }

    /**
     * Clear all errors
     */
    clearFormErrors() {
        this.container.find('.form-input').each((_, item) => {
            const $field = $(item)
            this.clearFieldError($field)
        })
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-5 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `
        this.container.find('.change-details-form--alert-container').html(markup)
    }

    /**
     * Show firebase session error
     */
    showFirebaseSessionError() {
        this.tabFieldsContainer.addClass('d-none')
        this.firebaseReauthContainer.removeClass('d-none')
    }

    /**
     * Set step
     */
    setTabStep(step = 1) {
        const $step = this.container.find(`.tab-pane[data-tab="step-${step}"]`)

        this.container.find('.tab-pane').removeClass('show active')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 175)
    }
}

export default ChangeDetails
