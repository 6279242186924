import clamp from 'clamp-js'

/**
 * ImageGallery component
 */

class ImageGallery {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM Element for ImageGallery')
            return
        }

        this.isEditmode = this.container.hasClass('editmode')
        if(!this.isEditmode) {
            this.setBannerLayout()
        }
    }

    /**
     * Layout initialization
     */
    setBannerLayout() {
        this.contentContainer = this.container.find('.banner-item .banner-item--inner')

        this.bannerTitle = this.container.find('.banner-item h5')
        this.bannerLink = this.container.find('.banner-item .btn-link')
        this.bannerDescription = this.container.find('.banner-item .description-text')

        // Set 2 rows max for title
        if(this.bannerTitle.length) {
            clamp(this.bannerTitle[0], { clamp: '2' })
        }

        let totalHeight = 0
        if(this.bannerTitle.length) {
            totalHeight += this.bannerTitle.outerHeight(true)
        }

        if(this.bannerDescription.length) {
            totalHeight += this.bannerDescription.outerHeight(true)
        }

        if(this.bannerLink.length) {
            totalHeight += this.bannerLink.outerHeight(true)
        }

        // Overflowing
        if (totalHeight >= (this.contentContainer.height())) {
            if (this.bannerTitle.length && this.bannerDescription.length) {
                if((this.bannerTitle.outerHeight(true) / this.contentContainer.height()) > 0.4) {
                    clamp(this.bannerDescription[0], { clamp: '1' })
                } else {
                    clamp(this.bannerDescription[0], { clamp: '2' })
                }
            } else {
                clamp(this.bannerDescription[0], { clamp: '4' })
            }
        }
    }
}

export default ImageGallery
