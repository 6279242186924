import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class AlteaCheckIn {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        const {
            country,
            language,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.defaults = {
            locale: this.localeFormatter(this.userLanguage),
        }
    }

    engage(payload = {}) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        window.open(redirectUrl, '_blank')
    }

    formatPayload(payload = {}) {
        const data = {}
        Object.keys(payload).forEach(inputName => {
            const value = payload[inputName]

            if (inputName === 'booking_code') {
                const isTicketNumber = /^\d+$/.test(value)

                data['IIdentification'] = value
                data['IFormOfIdentification'] = isTicketNumber ? 'EKT' : 'PNR'
            } else if (inputName === 'surname') {
                data['ISurname'] = value
            }
        })

        return {
            ...this.defaults,
            ...data,
        }
    }

    /**
     * Locale formatter
     * [ISO 2 code letter]
     * @param {String} language
     */
    localeFormatter(language = null) {
        if(language) {
            const locale = language.split('_')
            return locale[0]
        } else {
            return 'en'
        }
    }
}

export default AlteaCheckIn
