import moment from 'moment'

import DateInput from './fields/DateInput'
import LocationInput from './fields/LocationInput'
import SimpleInput from '../booking/main-booking-panel/parts/fields/SimpleInput'

class TopPanel {
    constructor(domElement = null, {
        routes = {},
        locations: {
            origins = [],
            destinations = []
        },
        onSubmit = null,
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for TopPanel class')
            return
        }

        // Routes
        this.routes = routes

        // Locations
        this.originsData = origins
        this.destinationsData = destinations

        const {
            country,
            language,
        } = window.localeData

        this.userCountry = country
        this.userLanguage = language

        this.onSubmit = null
        if(onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.activeTab = 'departure'

        this.init()
        this.setEventListener()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        this.FromInput.destroy()
        this.ToInput.destroy()
        this.DateInput.destroy()
        this.FlightNumberInput.destroy()

        this.FromInput = null
        this.ToInput = null

        this.DateInput = null
        this.FlightNumberInput = null
    }

    /**
     * Attach event listeners
     */
    setEventListener() {
        this.container.on('click', '.btn-search', this.handleSubmit.bind(this))
        this.container.on('click', '.flight-status--list-nav .nav-link', this.handleTabChange.bind(this))
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Set requesting flag
     */
    setRequestingFlag() {
        this.container.find('.btn-search').addClass('is-requesting')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.container.find('.btn-search').removeClass('is-requesting')
    }

    /**
     * Initialize method
     */
    init() {
        const fromInput = this.container.find('.form-select[data-name="location_from"]')
        const toInput = this.container.find('.form-select[data-name="location_to"]')
        const dateInput = this.container.find('.form-input[data-name="date"]')
        const flightNumberInput = this.container.find('.form-input[data-name="flight_number"]')

        this.FromInput = this.initLocationInput(fromInput[0])
        this.ToInput = this.initLocationInput(toInput[0])

        this.DateInput = this.initDateInput(dateInput[0])
        this.FlightNumberInput = new SimpleInput(flightNumberInput[0], {})
    }

    /**
     * Location input initialization
     * @param {HTMLElement} input
     */
    initLocationInput(input) {
        const $input = $(input)
        const { type } = $input.data()

        const inputInstance = new LocationInput(input, {
            options: type === 'from' ? this.originsData : this.destinationsData,
            optionsRoute: type === 'from' ? this.routes.origins : this.routes.destinations,
            onChange: data => this.handleLocationInputChange(input, data)
        })

        $input.addClass('input-initialized')

        return inputInstance
    }

    /**
     * Date input initialization
     * @param {HTMLElement} input
     */
    initDateInput(input) {
        const inputInstance = new DateInput(input, {
            datesRoute: this.routes.dates, // Won't be used here
            extraOptions: {
                minDate: moment().subtract(1, 'day').toDate(), // Yesterday
                maxDate: new Date().fp_incr(3), // => 3 days from today
            }
        })

        $(input).addClass('input-initialized')

        return inputInstance
    }

    /**
     * Fields validation
     */
    validateFields() {
        let valid = true

        if (!this.FromInput.validate()) {
            valid = false
        }

        if (!this.ToInput.validate()) {
            valid = false
        }

        if (!this.DateInput.validate()) {
            valid = false
        }

        if (!this.FlightNumberInput.validate()) {
            valid = false
        }

        return valid
    }

    /**
     * Values getter
     */
    getValues() {
        return {
            origin: this.FromInput.getValue(),
            destination: this.ToInput.getValue(),
            date: this.DateInput.getValue(),
            flightNumber: this.FlightNumberInput.getValue(),
            arrivalDeparture: this.activeTab === 'departure' ? 'D' : 'A',
        }
    }

    /**
     * Formated date values getter
     * @returns {Array} array of formatted dates
     */
    getFormattedDateValues() {
        return this.DateInput.getFormattedValue()
    }

    /**
     * Create proper payload
     * @returns {Object} Key-value pair
     */
    createPayload() {
        const values = this.getValues()
        return {
            ...values,
            origin: values.origin.code || null,
            destination: values.destination.code || null,
            date: values.date ? moment(values.date[0]).format('Y-MM-DD') : null,
        }
    }

    /**
     * Location inputs change handlers
     */
    handleLocationInputChange(input, data) {
        // Set options to connected location inputs
        const {
            name,
            selected
        } = data

        if (selected) {
            if (name === 'location_from') {
                this.ToInput.fetchNewOptions(selected.id, `lang=${this.userLanguage}&country=${this.userCountry}`)
            }

            if (name === 'location_to') {
                this.FromInput.fetchNewOptions(selected.id, `lang=${this.userLanguage}&country=${this.userCountry}`)
            }
        } else {
            if (name === 'location_from') {
                this.ToInput.resetOptions()
            }

            if (name === 'location_to') {
                this.FromInput.resetOptions()
            }
        }

        /*
        // Set available flight dates
        const toInputValue = this.ToInput.getValue()
        const fromInputValue = this.FromInput.getValue()

        if (toInputValue && fromInputValue) {
            this.DateInput.fetchEnabledDates(`origin=${fromInputValue.code}&destination=${toInputValue.code}&lang=${this.userLanguage}`)
        }
        */
    }

    /**
     * Tab change event handler
     * @param {Object} event
     */
    handleTabChange(event) {
        const $button = $(event.currentTarget)
        if($button.hasClass('active')) {
            return
        }

        this.container.find('.flight-status--list-nav .nav-link').removeClass('active')
        $button.addClass('active')

        const { tab } = $button.data()
        this.activeTab = tab
    }

    /**
     * Handler for submission
     * @param {Object} event
     */
    async handleSubmit(event) {
        const $button = $(event.currentTarget)

        // Validate
        const isValid = this.validateFields()
        if (!isValid) {
            return
        }

        // Disable multiple submissions
        if ($button.hasClass('is-requesting')) {
            return
        }

        // Trigger callback
        if(this.onSubmit) {
            this.onSubmit()
        }
    }
}

export default TopPanel
