import Forms from '../../index'
import Preview from './Preview'
import FlightInfo from './FlightInfo'
import BankAccount from './BankAccount'
import PersonalData from './PersonalData'

class ComplaintForm extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.topCategory = null
        this.subCategory = null
        this.selectedCategoryId = null

        this.howYouApplyValue = null
        this.forWhoYouApplyValue = null

        this.bankAccountContainer = this.form.find('.bank-account--container')
        this.BankAccount = new BankAccount(this.bankAccountContainer[0])

        this.PersonalData = new PersonalData(this.form[0])
        this.FlightInfo = new FlightInfo(this.form[0])

        this.previewStepContainer = this.form.find('.tab-pane.preview-step')
        this.Preview = new Preview(this.form)

        this.setInitialValues()
        this.setEventListeners()
    }

    /**
     * Destructor
     */
    destroy() {
        this.BankAccount.destroy()
        this.BankAccount = null

        this.Preview.destroy()
        this.Preview = null

        this.FlightInfo.destroy()
        this.FlightInfo = null

        this.PersonalData.destroy()
        this.PersonalData = null

        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('click touch', '.btn-back', () => this.toPreviousStep())

        this.form.on('click touch', '[data-tab="step-1"] .category-button', this.handleCategoryChange.bind(this))
        this.form.on('click touch', '[data-tab="step-2"] .category-choice-button', this.handleSubCategoryChange.bind(this))

        this.form.on('change', '[data-tab="step-3"] .how-you-apply-radio-input', this.handleHowYouApplyChange.bind(this))
        this.form.on('change', '[data-tab="step-3"] .for-who-you-apply-radio-input', this.handleForWhoYouApplyChange.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('click touch')
        this.form.off('change')
    }

    /**
     * Set form initial values
     */
    setInitialValues() {
        // Personal data step radio buttons logic
        const $step3 = this.form.find('[data-tab="step-3"]')

        const $howInput = $step3.find('.how-you-apply-radio-input:checked')
        const $forWhoInput = $step3.find('.for-who-you-apply-radio-input:checked')

        this.howYouApplyValue = $howInput.val()
        this.forWhoYouApplyValue = $forWhoInput.val()

        this.setYourInfoFileFields()
    }

    /**
     * Parent category select handler
     * @param {Object} event
     */
    handleCategoryChange(event) {
        const $button = $(event.currentTarget)
        const $step1 = this.form.find('[data-tab="step-1"]')
        const $step2 = this.form.find('[data-tab="step-2"]')

        $step1.find('.category-button').removeClass('active')
        $button.addClass('active')

        const { categoryName } = $button.data()
        const categoryTitle = $button.attr('title')

        this.topCategory = categoryTitle

        $step2.find('.selected-category-title').text(categoryTitle)
        $step2.find('.category-choices').addClass('d-none')
        $step2.find(`.category-choices[data-category="${categoryName}"]`).removeClass('d-none')

        this.toNextStep()
    }

    /**
     * SubCategory select handler
     * @param {Object} event
     */
    handleSubCategoryChange(event) {
        const $button = $(event.currentTarget)
        const { value, name } = $button.data()

        this.subCategory = name
        this.selectedCategoryId = value

        const $step2 = this.form.find('[data-tab="step-2"]')

        // Set proper active class
        $step2.find('.category-choice-button').removeClass('active')
        $button.addClass('active')

        // Set value to hidden input
        $step2.find('.sub-category-input').val(value)

        this.setFormNavTitle()
        this.setDocumentationStepFields()

        this.toNextStep()
    }

    /**
     * "How you apply" change handler
     * @param {Object} event
     */
    handleHowYouApplyChange(event) {
        const $input = $(event.currentTarget)
        const $step3 = this.form.find('[data-tab="step-3"]')

        const $forWhoInput = $step3.find('.for-who-you-apply-radio-input')

        const $myselfRadio = $step3.find('.for-who-you-apply-radio-input[value="myself"]')
        const $myselfRadioLabel = $myselfRadio.next('label')

        const selectedValue = $input.val()
        if (selectedValue === 'as_a_person') {
            $myselfRadio.removeClass('d-none')
            $myselfRadioLabel.removeClass('d-none')
        } else {
            $myselfRadio.addClass('d-none')
            $myselfRadioLabel.addClass('d-none')

            if ($forWhoInput.val() === 'myself') {
                $step3.find('.for-who-you-apply-radio-input[value="adult"]').prop('checked', true)
                $forWhoInput.trigger('change')
            }
        }

        this.howYouApplyValue = selectedValue

        this.setYourInfoFileFields()
    }

    /**
     * "For who you apply" change handler
     * @param {Object} event
     */
    handleForWhoYouApplyChange(event) {
        const $step3 = this.form.find('[data-tab="step-3"]')
        const $input = $step3.find('.for-who-you-apply-radio-input:checked')

        const selectedValue = $input.val()

        this.forWhoYouApplyValue = selectedValue

        this.setYourInfoFileFields()
    }

    /**
     * Set "Your info" file fields by radio button combinations
     */
    setYourInfoFileFields() {
        const $step3 = this.form.find('[data-tab="step-3"]')

        // "Myself radio"
        const $myselfRadio = $step3.find('.for-who-you-apply-radio-input[value="myself"]')
        const $myselfRadioLabel = $myselfRadio.next('label')

        if(this.howYouApplyValue) {
            if (this.howYouApplyValue === 'as_a_person') {
                $myselfRadio.removeClass('d-none')
                $myselfRadioLabel.removeClass('d-none')
            } else {
                $myselfRadio.addClass('d-none')
                $myselfRadioLabel.addClass('d-none')
            }
        }

        // Dynamic file fields
        $step3.find('.file-fields-container').each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()

            if(name === 'travelers_consent_certified_by_a_notary') {
                if (this.howYouApplyValue === 'as_a_person' && this.forWhoYouApplyValue === 'adult') {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }

            if(name === 'birth_certificate') {
                if (this.howYouApplyValue === 'as_a_person' && this.forWhoYouApplyValue === 'a_minor') {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }

            if(name === 'power_of_attorney') {
                if (
                    this.howYouApplyValue === 'as_company' &&
                    (this.forWhoYouApplyValue === 'adult' || this.forWhoYouApplyValue === 'a_minor')
                ) {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }
        })
    }

    /**
     * Set navigation title via selected top category & category
     */
    setFormNavTitle() {
        const navTitle = `${this.topCategory}/${this.subCategory}`
        this.form.find('.nav-subtitle').text(navTitle)
    }

    /**
     * Set visible fields via selected category Id
     */
    setDocumentationStepFields() {
        const $step5 = this.form.find('[data-tab="step-5"]')
        const { fieldsMapping } = $step5.data()

        $step5.find('.field-container').each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()

            const categoryIds = fieldsMapping[name] || []
            if(categoryIds.includes(this.selectedCategoryId)) {
                $field.removeClass('d-none')
            } else {
                $field.addClass('d-none')
            }
        })
    }

    /**
     * Re-use method
     */
    afterSubmit() {
        this.setFormNavTitle()
        this.setInitialValues()

        // Set documents logic
        this.setDocumentationStepFields()

        this.FlightInfo.setFlightNumberOptions()

        this.PersonalData.setSubmitButtonState()

        // Re-init bank account
        this.BankAccount.destroy()

        this.bankAccountContainer = this.form.find('.bank-account--container')
        this.BankAccount = new BankAccount(this.bankAccountContainer[0])

        this.Preview.setContent()
    }

    toNextStep() {
        this.step = this.step + 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        /*
        if ($step.hasClass('preview-step')) {
            this.Preview.setContent()
        }

        if ($step.hasClass('documentation-step')) {
            this.setDocumentationStepFields()
        }
        */

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 175)
    }

    toPreviousStep() {
        this.step = this.step - 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 125)
    }
}

export default ComplaintForm
