import { Tooltip } from 'bootstrap'

class TableBrick {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for TableBrick class')
            return
        }

        this.initTooltips()
    }

    /**
     * Destructor method
     * [Clear Tooltip instances]
     */
    destroy() {
        this.toolTipInstances.forEach(instance => instance.dispose())
        this.toolTipInstances = []
    }

    /**
     * Initialize tooltips
     */
    initTooltips() {
        this.toolTipInstances = []
        this.container.find('.bootstrap-tooltip-container').each((_, item) => {
            // Re-init
            if (Tooltip.getInstance(item)) {
                Tooltip.getInstance(item).dispose()
            }

            const tooltipInstance = new Tooltip(item, {
                container: 'body', // Append to body so it won't be cutted
            })

            this.toolTipInstances.push(tooltipInstance)
        })
    }
}

export default TableBrick