/**
 * User profile dashboard page
 */

class Dashboard {
    constructor(domElement = null, {
        onLogout = null,
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for UserProfile/Dashboard class')
            return
        }

        this.userData = null
        this.userNameContainer = this.container.find('.user-name-holder')

        this.onLogout = null
        if(onLogout && typeof onLogout === 'function') {
            this.onLogout = onLogout
        }

        this.setUserData()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-logout', () => {
            if(this.onLogout) {
                this.onLogout()
            }
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * userData setter
     */
    setUserData() {
        const userData = localStorage.getItem('user_data')
            ? JSON.parse(localStorage.getItem('user_data'))
            : null

        this.userData = userData

        if(!this.userData) {
            return
        }

        // Set welcome message
        const userName = `${this.userData.firstname} ${this.userData.lastname}`
        this.userNameContainer.text(userName)
    }
}

export default Dashboard
