/**
 * Notifications component
 */
import moment from 'moment'
import { Toast } from 'bootstrap'

class Notifications {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for Notifications class')
            return
        }

        // FCM util
        this.FCM = window.FCM

        // Since older iOS devices won't support FCM
        if (this.FCM.isSupportedByDevice) {
            this.setEventListeners()
        }
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.FCM.on('onMessage', ({ data }) => this.showNotification(data))
    }

    /**
     * Show Notification
     */
    showNotification(notificationData = {}) {
        const { notification } = notificationData

        const toastId = this.generateUniqueId()
        const notificationMarkup = this.createNotificationMarkup(notification, toastId)

        this.container.append(notificationMarkup)

        const toast = new Toast(this.container.find(`#${toastId}`)[0], {
            autohide: false
        })

        toast.show()
    }

    /**
     * Crate notification markup
     * @returns {String}
     */
    createNotificationMarkup({ title = '', body = '' }, toastId = null) {
        const renderHeader = title => title ? `<strong class="me-auto">${title}</strong>` : ''
        const renderBody = body => {
            if(body) {
                return `<div class="toast-body">${body.replace(/(?:\r\n|\r|\n)/g, '<br>')}</div>`
            } else {
                return ''
            }
        }

        return `
            <div
                id="${toastId}"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="toast text-white bg-primary border-0 rounded-1"
            >
                <div class="toast-header text-white bg-primary">
                    ${renderHeader(title)}
                    <small class="text-white">${moment().format('HH:mm')}</small>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                ${renderBody(body)}
            </div>
        `
    }

    /**
     * Generate unique Id
     * @returns {String}
     */
    generateUniqueId() {
        return Math.random().toString(36).substr(2, 9)
    }
}

export default Notifications
