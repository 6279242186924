/**
 * HeroBanner class component
 */
class HeroBanner {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for HeroBanner class')
            return
        }

        this.contentWrapper = this.container.find('.hero-banner--inner-wrapper')
        this.bannerWrapper = this.container.find('.hero-banner--content--wrapper')

        this.bookingPanel = this.container.find('.quick-booking-panel--container')
        this.bookingWrapper = this.container.find('.quick-booking-panel--wrapper')

        // Set height of wrapper
        this.setLayoutHeight()

        // Attach event listeners
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        if(this.bookingPanel.length) {
            this.container.on('click touch', '.quick-booking-panel--toggle', this.toggleBookingPanel.bind(this))
        }

        $(window).on('resize.hero-banner', () => this.setLayoutHeight())
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.container.off('click touch')
        $(window).off('resize.hero-banner')
    }

    /**
     * Set height of layout (Mobile only)
     */
    setLayoutHeight() {
        if (window.innerWidth < 600) {
            const headerHeight = $('header.header').height()
            const calculatedHeight = window.innerHeight - headerHeight

            this.bannerWrapper.css('minHeight', `${calculatedHeight}px`)

            if (this.bookingWrapper.length) {
                this.bookingPanel.css('minHeight', `${window.innerHeight}px`)
                this.bookingWrapper.css('minHeight', `${window.innerHeight}px`)
            }
        } else {
            this.bannerWrapper.css('minHeight', '')

            if (this.bookingWrapper.length) {
                this.bookingPanel.css('minHeight', '')
                this.bookingWrapper.css('minHeight', '')
            }
        }
    }

    /**
     * Show/Hide quick booking panel
     */
    toggleBookingPanel() {
        if (this.bookingPanel.hasClass('active')) {
            this.bookingPanel.removeClass('active')
            this.contentWrapper.removeClass('booking-visible')

            $('body').removeClass('disable-scroll')
        } else {
            this.bookingPanel.addClass('active')
            this.contentWrapper.addClass('booking-visible')

            $('body').addClass('disable-scroll')
        }
    }
}

export default HeroBanner
