/**
 * User details page component
 */
import moment from 'moment'

class UserDetails {
    constructor(domElement = null, {

    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for UserProfile/Dashboard class')
            return
        }

        const { index } = this.container.data()
        const { translations } = window[`profile_details_${index}_data`]

        this.userData = null
        this.translations = translations

        /**
         * @type UserAuth
         */
        this.UserAuth = window.UserAuth

        this.setUserData()
        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        // Listen for Auth changes
        this.UserAuth.on('onAuthChange', (event) => {
            // Set new user data
            const { data } = event
            this.setUserData(data)
        })
    }

    /**
     * Set current User data or fetch from localStorage
     */
    setUserData(user = null) {
        let userData = user
        if(!userData) {
            userData = localStorage.getItem('user_data')
                ? JSON.parse(localStorage.getItem('user_data'))
                : null
        }

        if (!userData) {
            return
        }

        // Get propper data
        this.userData = this.mapUserData(userData)

        // Set data to holders
        this.container.find('.holder').each((_, item) => {
            const $holder = $(item)
            const $display = $holder.find('[data-holder]')
            const { holder } = $display.data()

            if (this.userData.hasOwnProperty(holder) && this.userData[holder] !== null) {
                $display.text(this.userData[holder])
                $holder.removeClass('d-none')
            } else {
                $holder.addClass('d-none')
            }
        })
    }

    /**
     * User data mapper
     * @param {Object} data
     * @returns {Object}
     */
    mapUserData(data = {}) {
        // Create new object
        const userData = { ...data }

        // Title
        const userTitle = userData.title ? this.translations.titles[userData.title] : null
        userData.title = userTitle

        // User name + title
        const userName = `${userTitle ? `${userTitle} ` : ''}${userData.firstname} ${userData.lastname}`
        userData.userName = userName

        // Gender
        const gender = userData.gender ? this.translations.genders[userData.gender] : null
        userData.gender = gender

        // Newsletter
        const newsletter = userData.hasOwnProperty('commercialNotificationActivated')
            ? this.translations.activated[userData.commercialNotificationActivated]
            : null

        userData.newsletter = newsletter

        // DOB
        const dob = userData.dateOfBirth ? moment(userData.dateOfBirth).format('DD.MM.YYYY') : null
        userData.dob = dob

        // Mobile phone
        let phoneNumber = null
        if (userData.mobilePhone) {
            phoneNumber = `${userData.mobilePhone.areaCode ? userData.mobilePhone.areaCode : ''}${userData.mobilePhone.number}`
        }

        userData.phoneNumber = phoneNumber

        return userData
    }
}

export default UserDetails
