import BaseBookingTab from './BaseBookingTab'

class RoundTripBookingTab extends BaseBookingTab {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.tabType = 'round-trip'

        this.init()
    }
}

export default RoundTripBookingTab