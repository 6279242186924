/**
 * DateInput field class
 * [Extends DateInput class from MainBooking]
 */
import Input from "../../../../components/fields/DateInput"

class DateInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.requiredPopover = null
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.inputContainer.on('click', () => {
            this.input.removeClass('is-invalid')
            this.inputContainer.removeClass('is-invalid')
        })

        // QFIX for feature to select range over disabled dates
        if (this.type === 'range') {
            $("[class^=flatpickr]").on('mousemove click', () => {
                $('.flatpickr-day').removeClass('notAllowed')
            })
        }
    }

    /**
     * Field validation
     * @returns {Boolean}
     */
    validate() {
        const { selectedDates } = this.fpInstance
        const isFilled = this.type === 'range'
            ? selectedDates.length === 2
            : selectedDates.length === 1

        if (this.isRequired && !isFilled) {
            this.input.addClass('is-invalid')
            this.inputContainer.addClass('is-invalid')

            return false
        }

        return true
    }
}

export default DateInput
