/**
 * Icon selector component
 */

import { Modal } from 'bootstrap'

class IconSelector {
    constructor({
        onSelect = null
    }) {
        this.modalContainer = $('#iconSelectModal')
        this.modal = new Modal(this.modalContainer, {
            //backdrop: 'static'
        })

        // Store for event listeners
        this._triggers = {}

        // Callback function
        this.onSelect = null
        if(onSelect && typeof onSelect === 'function') {
            this.onSelect = onSelect
        }
    }

    // "on" event listeners on object
    on(event, callBack) {
        if(!this._triggers[event]) {
            this._triggers[event] = []
        }

        this._triggers[event].push(callBack)
    }

    // Event dispatcher for "on" events
    triggerHandler(event, eventData) {
        if(this._triggers[event]) {
            this._triggers[event].forEach(listener => {
                listener({
                    event,
                    data: eventData
                })
            })
        }
    }

    addEventListeners() {
        this.modalContainer.on('click', '.icon-select-button', this.handleIconSelect.bind(this))
        this.modalContainer.on('click', '[data-bs-dismiss="modal"]', () => this.hide())

        // Will Triggered also by "outside/backdrop" click
        this.modalContainer.on('hide.bs.modal', () => {
            this.removeEventListeners()
            this.modalContainer.find('.icon-select-button').removeClass('active')
        })
    }

    removeEventListeners() {
        this.modalContainer.off()
    }

    /**
     * Handler for selecting icon
     * @param {Object} event
     */
    handleIconSelect(event) {
        const $button = $(event.currentTarget)

        // Already selected
        if ($button.hasClass('active')) {
            return
        }

        $button.addClass('active')
        const { path } = $button.data()

        // Trigger event listener
        this.triggerHandler('onSelect', { iconPath: path })

        // Callback
        if(this.onSelect) {
            this.onSelect({ iconPath: path })
        }

        // Hide & Remove event listeners
        this.hide()
    }

    /**
     * Show Selector modal
     * @param {String} activeIcon
     */
    show(activeIcon = null) {
        // Add event listeners
        this.addEventListeners()

        // Set active icon
        if (activeIcon) {
            this.modalContainer.find(`.icon-select-button[data-path="${activeIcon}"]`).addClass('active')
        }

        // Show modal
        this.modal.show()
    }

    /**
     * Hide selector modal
     */
    hide() {
        this.modal.hide()
    }
}

export default IconSelector
