/**
 * Maureva util class
 */
import axios from "axios"

class Maureva {
    constructor() {
        const {
            passwordForgot,
            deactivateAccount,
            updateProfileRoute,
            userProfileRetrieve,
            userProfileCreateFromEmail,
            userProfileCreateFromSocial,
        } = window.apiRoutes.maureva

        const {
            country,
            language,
        } = window.localeData

        this.userCountry = country
        this.userLanguage = language

        this.passwordForgotRoute = passwordForgot
        this.updateProfileRoute = updateProfileRoute
        this.deactivateAccountRoute = deactivateAccount
        this.retrieveProfileRoute = userProfileRetrieve
        this.createFromEmailRoute = userProfileCreateFromEmail
        this.createFromSocialRoute = userProfileCreateFromSocial
    }

    /**
     * Retrieve user data by Firebase user uid
     * @param {String} uid
     * @returns {Promise}
     */
    async retrieveUserData(uid = null) {
        try {
            // Contact Maureva API with UID
            // testUid EzMmGLf9aOOfqo3izaBe3Z8ntao2
            const requestUri = this.retrieveProfileRoute.replace('uuid', uid)
            const userResponse = await axios.post(requestUri, {
                lang: this.userLanguage
            })

            return userResponse
        } catch(err) {
            throw err
        }
    }

    /**
     * Create user via email and password by provided data
     * @param {Object} userData
     * @returns {Promise}
     */
    async createUserFromEmail(userData = {}) {
        try {
            const payload = {
                userData,
                lang: this.userLanguage,
            }

            const response = await axios.post(this.createFromEmailRoute, payload)

            return response
        } catch(err) {
            throw err
        }
    }

    /**
     * Create user from social login by provided data
     * @param {Object} userData
     * @returns {Promise}
     */
    async createUserFromSocial(userData = {}) {
        try {
            const payload = {
                userData,
                lang: this.userLanguage,
            }

            const response = await axios.post(this.createFromSocialRoute, payload)

            return response
        } catch(err) {
            throw err
        }
    }

    /**
     * Update user profile
     * @param {Object} userData
     * @returns {Promise}
     */
    async updateUserProfile(userData = {}) {
        try {
            const payload = {
                userData,
                lang: this.userLanguage,
            }

            const response = await axios.post(this.updateProfileRoute, payload)

            return response
        } catch(err) {
            throw err
        }
    }

    /**
     * Deactivate user account route
     * @param {Object} payload
     * @returns {Promise}
     */
    async deactivateAccount(payload = {}) {
        try {
            const response = await axios.post(this.deactivateAccountRoute, {
                lang: this.userLanguage,
                ...payload
            })

            return response
        } catch(err) {
            throw err
        }
    }

    /**
     * Password forgot action
     * @param {String} userEmail
     * @returns {Promise}
     */
    async passwordForgot(userEmail = null) {
        try {
            const payload = {
                userEmail,
                lang: this.userLanguage,
            }

            const response = await axios.post(this.passwordForgotRoute, payload)

            return response
        } catch(err) {
            throw err
        }
    }
}

export default Maureva
