import Input from '../../main-booking-panel/parts/fields/LocationInput'

class LocationInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.requiredPopover = null
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.inputContainer.on('click', () => {
            // Remove invalid classes
            this.inputContainer.removeClass('is-invalid')
            this.inputContainer.find('.form-select').removeClass('is-invalid')
        })

        // Outside click
        $('html').on(`click.${this.inputId}`, this.handleOutsideClick.bind(this))
    }

    /**
     * Field validation
     */
    validate() {
        // Empty field
        if (this.isRequired && !this.getValue()) {
            // Set invalid classes
            this.inputContainer.addClass('is-invalid')
            this.inputContainer.find('.form-select').addClass('is-invalid')

            return false
        }

        return true
    }
}

export default LocationInput
