import axios from 'axios'
import Input from '../../../components/fields/DateInput'

class DateInput extends Input {
    constructor(domElement = null, data = {}) {
        super(domElement, data)
    }

    /**
     * Get available dates by origin & destination codes
     * @param {String} params
     */
    async fetchEnabledDates(queryParams = '') {
        try {
            const requestUri = `${this.datesRoute}?${queryParams}`

            const response = await axios.get(requestUri)
            const { data } = response

            this.setAvailableDates(data)

        } catch(err) {
            console.error(err)
            throw err
        }
    }
}

export default DateInput
