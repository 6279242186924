/**
 * Recaptcha V3 Class component
 */

class RecaptchaV3 {
    siteKey = null;
    token = null;
    reCaptchaField = null;

    constructor(form, options = {}) {
        this.setConstants()

        this.options = Object.assign(this.RECAPTCHA_V3_DEFAULTS, options)
        this.form = form

        this.init()
    }

    setConstants() {
        this.RECAPTCHA_V3_DEFAULTS = {
            disableFormWhileLoading: false,
            reCaptchaFieldClass: 'input.re-captcha-v3',
        }

        this.EVENTS = {
            success: 'formbuilder.success',
            error: 'formbuilder.error',
            errorForm: 'formbuilder.error-form',
            errorField: 'formbuilder.error-field',
            done: 'formbuilder.done',
            fatal: 'formbuilder.fatal',
            fatalCaptcha: 'formbuilder.fatal-captcha',
            requestDone: 'formbuilder.request-done',
            repeaterContainerUpdate: 'formbuilder.repeater.container.update',
            layoutPostAdd: 'formbuilder.layout.post.add',
            layoutPreAdd: 'formbuilder.layout.pre.add',
            layoutPostRemove: 'formbuilder.layout.post.remove',
            layoutPreRemove: 'formbuilder.layout.pre.remove',
            dynamicMultiFileInit: 'formbuilder.dynamic_multi_file.init',
            dynamicMultiFileDropzoneInit: 'formbuilder.dynamic_multi_file.dropzone.init'
        }
    }

    init() {
        const field = this.form.querySelector(this.options.reCaptchaFieldClass)
        if (!this.isNode(field)) {
            return
        }

        this.disableFormSubmission()

        document.body.classList.add('form-builder-rec3-available')

        this.siteKey = field.dataset.siteKey
        this.action = field.dataset.actionName

        this.form.addEventListener(this.EVENTS.success, () => this.onReset())
        this.form.addEventListener(this.EVENTS.error, () => this.onReset())

        this.bindDependency()

        // Refresh token each 90 seconds
        setInterval(() => {
            this.injectTokenToForm()
        }, 90 * 1000)
    }

    bindDependency() {
        // Re-use "grecaptcha" global variable
        if (typeof window.grecaptcha !== 'undefined') {
            grecaptcha.ready(() => this.injectTokenToForm())
            return
        }

        // Inject script
        this.getScript(`https://www.google.com/recaptcha/api.js?render=${this.siteKey}`)
            .then(() => {
                grecaptcha.ready(() => this.injectTokenToForm())
            })
            .catch(() => {
                console.error('formbuilder error: unable to load recaptcha script');
            })
    }

    onReset() {
        const field = this.form.querySelector(this.options.reCaptchaFieldClass)

        if (this.token === null) {
            return
        }

        if (!window.grecaptcha) {
            return
        }

        if (!field) {
            return
        }

        this.disableFormSubmission()
        this.injectTokenToForm()
    }

    injectTokenToForm() {
        try {
            grecaptcha
                .execute(this.siteKey, { action: this.action })
                .then((token) => this.onTokenGenerated(token))

        } catch (error) {
            console.error(error)
            this.form.dispatchEvent(new CustomEvent(this.EVENTS.fatalCaptcha))
        }
    }

    onTokenGenerated(tokenGoogleRecaptchaV3) {
        this.token = tokenGoogleRecaptchaV3
        const field = this.form.querySelector(this.options.reCaptchaFieldClass)

        field.value = tokenGoogleRecaptchaV3

        this.enableFormSubmission()
    }

    disableFormSubmission() {
        if (this.options.disableFormWhileLoading !== true) {
            return;
        }

        this.form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');
    }

    enableFormSubmission() {
        if (this.options.disableFormWhileLoading !== true) {
            return;
        }

        this.form.querySelector('[type="submit"]').removeAttribute('disabled');
    }

    /**
     * Check wheter provided element exists and it is inside DOM
     * @param {HTMLElement} el
     * @returns {Boolean}
     */
    isNode(el) {
        return !!(el && el.nodeType && el.nodeType === 1)
    }

    /**
     * Append and execute JS script
     * @param {String} url
     * @returns {Promise}
     */
    getScript(url) {
        return new Promise((resolve, reject) => {
            const s = document.createElement('script')

            s.src = url
            s.async = true
            s.onerror = reject

            s.onload = s.onreadystatechange = function () {
                const loadState = this.readyState;
                if (loadState && loadState !== 'loaded' && loadState !== 'complete') {
                    return;
                }
                s.onload = s.onreadystatechange = null
                resolve()
            }

            document.head.appendChild(s)
        })
    }
}

// Call for each recaptcha of formBuilder form
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('form.formbuilder').forEach((form) => {
        new RecaptchaV3(form)
    })
})

export default RecaptchaV3
