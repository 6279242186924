/**
 * HTML DOM helpers
 */

/**
 * Check if HTML element is overflowing
 * @param {HTMLElement} element
 * @returns {Boolean}
 */
const isElementOverflowing = (element) => {
    const elemDimensions = element.getBoundingClientRect()
    const parentDimensions = element.parentElement.getBoundingClientRect()

    return elemDimensions.height > parentDimensions.height || elemDimensions.width > parentDimensions.width
}

/**
 * Check if element is visible inside scrollable div
 * @param {HTMLElement} el
 * @param {HTMLElement} holder
 * @returns {Boolean}
 */
const isElementVisible = (el, holder = document.body) => {
    const { top, bottom, height } = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()

    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height
}

export {
    isElementVisible,
    isElementOverflowing,
}
