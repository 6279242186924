/**
 * Preview class component
 * [Show already filled fields in previous steps]
 */
class Preview {

    /**
     * Constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.formContainer = $(domElement)
        if(!this.formContainer.length) {
            console.error('Please provide proper DOM element for Complaint/Preview class')
            return
        }
    }

    /**
     * Set preview content
     */
    setContent() {
        const content = this.createPreviewContent()
        this.formContainer.find('.tab-pane.preview-step .preview-container').html(content)
    }

    /**
     * Clear content of preview container
     * [Trigger before submission]
     */
    clearContent() {
        this.formContainer.find('.tab-pane.preview-step .preview-container').html('')
    }

    /**
     * Create full markup of already filled fields
     * @returns {String}
     */
    createPreviewContent() {
        const personalDataContent = this.getPersonalDataContent()
        const flightInformationContent = this.getFlightInformationContent()
        const documentationContent = this.getDocumentationContent()
        const bankAccountContent = this.getBankAccountContent()

        return `
            ${personalDataContent}
            ${flightInformationContent}
            ${documentationContent}
            ${bankAccountContent}
        `
    }

    /**
     * Create markup for personal data step
     * @returns {String}
     */
    getPersonalDataContent() {
        let markup = ''
        const $personalStep = this.formContainer.find('[data-tab="step-3"]')

        const $howYouApply = $personalStep.find('.how-you-apply-inputs--container')
        if($howYouApply.length) {
            markup += this.createRadioButtonGroupPreview($howYouApply)
        }

        const $forWhoYouApply = $personalStep.find('.for-who-you-apply-inputs--container')
        if($forWhoYouApply.length) {
            markup += this.createRadioButtonGroupPreview($forWhoYouApply)
        }

        $personalStep.find('.file-fields-container:not(.d-none)').each((_, item) => {
            const $field = $(item)
            markup += this.createFileUploadPreview($field)
        })

        $personalStep.find('.form-input, .step-subtitle').each((_, item) => {
            const $field = $(item)

            let fieldMarkup = ''

            if($field.is('label')) {
                fieldMarkup = this.createSubtitlePreview($field)
            }

            if($field.is('select')) {
                fieldMarkup = this.createSelectFieldPreview($field)
            }

            if($field.is('input[type="text"]') || $field.is('input[type="email"]') || $field.is('input[type="number"]') || $field.is('textarea')) {
                fieldMarkup = this.createTextInputPreview($field)
            }

            markup += fieldMarkup
        })

        return markup
    }

    /**
     * Create markup for Flight info step
     * @returns {String}
     */
    getFlightInformationContent() {
        let markup = ''

        const $flightInforStep = this.formContainer.find('[data-tab="step-4"]')

        const $title = $flightInforStep.find('.top-title h4')
        markup += this.createSubtitlePreview($title)

        $flightInforStep.find('.form-input').each((_, item) => {
            const $field = $(item)

            let fieldMarkup = ''

            if($field.is('select')) {
                fieldMarkup = this.createSelectFieldPreview($field)
            }

            if($field.is('input[type="text"]') || $field.is('input[type="number"]')) {
                fieldMarkup = this.createTextInputPreview($field)
            }

            markup += fieldMarkup
        })

        return markup
    }

    /**
     * Get documentation step content
     * @returns {String}
     */
    getDocumentationContent() {
        let markup = ''

        const $documentationStep = this.formContainer.find('[data-tab="step-5"]')

        const $title = $documentationStep.find('.top-title h4')
        markup += this.createSubtitlePreview($title)

        $documentationStep.find('.field-container:not(.d-none)').each((_, item) => {
            const $field = $(item)

            let fieldMarkup = ''

            const isDropzone = $field.find('.dropzone').length > 0
            if (isDropzone) {
                fieldMarkup = this.createFileUploadPreview($field)
            } else {
                const $input = $field.find('input')
                fieldMarkup = this.createTextInputPreview($input)
            }

            markup += fieldMarkup
        })

        $documentationStep.find('textarea').each((_, item) => {
            const $field = $(item)
            const fieldMarkup = this.createTextInputPreview($field)

            markup += fieldMarkup
        })

        return markup
    }

    /**
     * Create markup for Bank account fields
     * @returns {String}
     */
    getBankAccountContent() {
        let markup = ''

        const $bankAccountData = this.formContainer.find('[data-tab="step-5"] .bank-account--container')

        // Title
        const $title = $bankAccountData.find('h4')
        markup += this.createSubtitlePreview($title)

        // Country holder
        const $countryHolderSelect = $bankAccountData.find('.country-holder-select')
        markup += this.createSelectFieldPreview($countryHolderSelect)

        // Dynamic fields
        $bankAccountData.find('.bank-field-container:not(.d-none)').each((_, item) => {
            const $field = $(item).find('input, select')

            let fieldMarkup = ''

            if($field.is('label')) {
                fieldMarkup = this.createSubtitlePreview($field)
            }

            if($field.is('select')) {
                fieldMarkup = this.createSelectFieldPreview($field)
            }

            if($field.is('input[type="text"]') || $field.is('input[type="email"]') || $field.is('input[type="number"]') || $field.is('textarea')) {
                fieldMarkup = this.createTextInputPreview($field)
            }

            markup += fieldMarkup
        })

        return markup
    }

    /**
     * Create preview markup for subtitle
     * @param {JQuery} $field
     * @returns {String}
     */
    createSubtitlePreview($field = null) {
        let markup = ''
        const text = $field.html()

        if(text) {
            return `<h4 class="mb-5">${text}</h4>`
        }

        return markup
    }

    /**
     * Create preview markup for radio buttons group
     * @param {JQuery} $field
     * @returns {String}
     */
    createRadioButtonGroupPreview($field = null) {
        let markup = ''

        const selectedInput = $field.find('input[type="radio"]:checked')

        if(selectedInput.length) {
            const labelText = $field.find('label.body-1').html()
            const selectedText = selectedInput.next('label').html()

            markup = `
                <div class="d-flex flex-column mb-5">
                    <label class="subtitle-1">${labelText}</label>
                    <p class="body-1">${selectedText}</p>
                </div>
            `
        }

        return markup
    }

    /**
     * Create preview markup for text inputs
     * @param {JQuery} $field
     * @returns {String}
     */
    createTextInputPreview($field = null) {
        let markup = ''

        const value = $field.val()
        if(value) {
            const label = $field.parent().find('.form-label')
            const labelText = label.length ? label.html().replace('*', '') : ''

            markup = `
                <div class="d-flex flex-column mb-5">
                    <label class="subtitle-1">${labelText}</label>
                    <p class="body-1">${value}</p>
                </div>
            `
        }

        return markup
    }

    /**
     * Create preview markup for select inputs
     * @param {JQuery} $field
     * @returns {String}
     */
    createSelectFieldPreview($field = null) {
        let markup = ''

        const value = $field.val()
        if(value) {
            const label = $field.parent().find('.form-label')
            const labelText = label.length ? label.html().replace('*', '') : ''

            const selectedOption = $field.find(`option[value=${value}]`)
            const selectedOptionText = selectedOption.length ? selectedOption.html() : ''

            markup = `
                <div class="d-flex flex-column mb-5">
                    <label class="subtitle-1">${labelText}</label>
                    <p class="body-1">${selectedOptionText}</p>
                </div>
            `
        }

        return markup
    }

    /**
     * Create preview markup for file upload input (Dropzone)
     * @param {JQuery} $field
     * @returns {String}
     */
    createFileUploadPreview($field = null) {
        let markup = ''

        const $filePreviews = $field.find('.dropzone-previews .dz-preview')
        if($filePreviews.length) {
            const labelText = $field.find('label.body-1').html()
            const filesMarkup = $field.find('.dropzone-previews').html()

            markup = `
                <div class="d-flex flex-column mb-5">
                    <label class="subtitle-1 mb-3">${labelText}</label>
                    ${filesMarkup}
                </div>
            `
        }

        return markup
    }
}

export default Preview
