import { initSingleSelect } from '../../components/selects'
import { Repeater } from 'js-pimcore-formbuilder';
import Forms from '../index'

class GroupReservation extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.numberOfAddedCities = 0
    }

    initFields() {
        // Check if value is prefilled
        const fligtType = this.form.find('input[type="radio"][name="flight_type"]').val()
        this.formRepeater = new Repeater(this.form[0], {
            addClass: 'add-city btn btn-outline-primary border-0 my-3 p-2' + (fligtType != 'multi_city' ? 'd-none' : ''),
            removeClass: 'remove-city btn btn-outline-danger border-0 my-3 p-2',
            containerClass: '.formbuilder-container.formbuilder-container-repeater',
            containerBlockClass: '.formbuilder-container-block',
            onAdd: (container, newForm, cb) => {
                let newBlock = new DOMParser().parseFromString(newForm, 'text/html').body.firstChild
                container.insertBefore(newBlock, container.lastChild);
                cb(newBlock); // always trigger the callback action!
                this.initSelect()
                this.initDates()
            }
        })

        // Fix for form repeater adding remove button to every formbuilder container including first which shouldn't be removed ever
        this.form.find('.formbuilder-container.formbuilder-container-repeater .formbuilder-container-block').first().find('.remove-block').remove()

        this.initPhone()
        this.initSelect()
        this.initDates()
        this.initPassengersInputs()
        this.addEventListeners()
    }

    addEventListeners() {
        this.form.on('change', 'input[type="radio"]', (event) => {
            if ($(event.currentTarget).attr('name').includes('flight_type')) {
                if ($(event.currentTarget).val() == 'one_way') {
                    this.form.find('.return-data').addClass('d-none')
                } else {
                    this.form.find('.return-data').removeClass('d-none')
                }

                if ($(event.currentTarget).val() == 'multi_city') {
                    this.form.find('.add-city').removeClass('d-none')
                } else {
                    this.form.find('.add-city').addClass('d-none')
                }

                if ($(event.currentTarget).val() != 'multi_city') {
                    this.form.find('.formbuilder-container.formbuilder-container-repeater .formbuilder-container-block').each((index, element) => {
                        if (index != 0) {
                            $(element).remove()
                        }
                    })
                    this.formRepeater.verifyButtonStates(this.form.find('.formbuilder-container.formbuilder-container-repeater')[0])
                }
            }
        })
    }

    afterSubmit() {
        
    }

    // initSelect() {
    //     const selectFields = this.form.find('.form-input[data-input-type="select"]')
    //     if (selectFields.length) {
    //         this.destroySelects()
    //         selectFields.each((_, select) => {
    //             initSingleSelect(select, {
    //                 render: {
    //                     optgroup_header: function(data, escape) {
    //                         return `<div class="optgroup-header bordered">${escape(data.label)}<span class="divider"></span></div>`
    //                     },
    //                     option: function(data, escape) {
    //                         return `
    //                         <div class="option d-flex flex-row align-items-center">
    //                             ${escape(data.text)}
    //                         </div>
    //                         `
    //                     },
    //                     item: function(data, escape) {
    //                         return `<div class="item">${escape(data.text)}</div>`
    //                     }
    //                 },
    //                 onChange: val => {
    //                     if ($(select).attr('name').includes('travel_class')) {
    //                         if (val == 'economy') {
    //                             this.form.find('.return-data').addClass('d-none')
    //                         } else {
    //                             this.form.find('.return-data').removeClass('d-none')
    //                         }
    //                     }
    //                 },
    //             })

    //             this.selects.push(select.selectize)
    //         })
    //     }
    // }
}

export default GroupReservation