import moment from 'moment'

import FireBase from '../../../utils/firebase'
import Maureva from '../../../utils/maureva'

import { Offcanvas } from 'bootstrap'

import DetailsStep from './steps/DetailsStep'
import SuccessStep from './steps/SuccessStep'
import CredentialsStep from './steps/CredentialsStep'
import axios from 'axios'

class UserRegister {
    constructor(domElement = null, {
        onAuthChange = null
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for UserRegister class')
            return
        }

        // Offcanvas instance
        this.drawer = new Offcanvas(this.container[0], {
            backdrop: 'static' // Outside click won't close drawer
        })

        // Firebase & Maureva utils init
        this.Maureva = new Maureva()

        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        this.form = this.container.find('.user-register-form')

        const {
            registerMessages,
        } = window.errorMesages

        this.errorMesages = registerMessages

        this.onAuthChange = null
        if(onAuthChange && typeof onAuthChange === 'function') {
            this.onAuthChange = onAuthChange
        }

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        this.DetailsStep.destroy()
        this.DetailsStep = null

        this.CredentialsStep.destroy()
        this.CredentialsStep = null

        this.SuccessStep.destroy()
        this.SuccessStep = null
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        // Reset steps when closed
        this.container.on('hidden.bs.offcanvas', () => {
            //this.clearFieldValues()
            this.setTabStep(1)
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
        this.container.off('hidden.bs.offcanvas')
    }

    init() {
        const credentialsContainer = this.container.find('.tab-pane[data-tab="step-1"]')
        const detailsContainer = this.container.find('.tab-pane[data-tab="step-2"]')
        const successContainer = this.container.find('.tab-pane[data-tab="step-3"]')

        this.CredentialsStep = new CredentialsStep(credentialsContainer[0], {
            onSubmit: () => this.setTabStep(2)
        })

        this.DetailsStep = new DetailsStep(detailsContainer[0], {
            onSubmit: () => this.setTabStep(3)
        })

        this.SuccessStep = new SuccessStep(successContainer[0], {
            onSubmit: ($button) => {
                this.handleFormSubmit($button)
            }
        })
    }

    /**
     * Show login drawer
     */
    showDrawer() {
        this.drawer.show()
    }

    /**
     * Hide login drawer
     */
    hideDrawer() {
        this.drawer.hide()
    }

    /**
     * Clear all field values
     */
    clearFieldValues() {
        this.CredentialsStep.clearValues()
        this.DetailsStep.clearValues()
    }

    /**
     * Form submission handler
     * @param {jQuery} $button
     */
    async handleFormSubmit($button) {
        if($button.hasClass('is-requesting')) {
            return
        }

        try {
            $button.addClass('is-requesting')

            // Validate recaptcha
            const recaptchaValid = await this.validateRecaptcha()
            if (!recaptchaValid) {
                $button.removeClass('is-requesting')
                return
            }

            // Create user on Firebase
            const { email, password } = this.CredentialsStep.getValues()
            const userUuid = await this.FireBase.registerUser({ email, password })

            // Create user on Maureva
            const userDetails = this.DetailsStep.getValues()
            const userData = this.createUserData(email, userUuid, userDetails)

            const userResponse = await this.Maureva.createUserFromEmail(userData)
            this.handleMaurevaResponse(userResponse.data)

            $button.removeClass('is-requesting')
        } catch(err) {
            this.showAlert(this.errorMesages.firebase_generic_error)
            $button.removeClass('is-requesting')
            throw err
        }
    }

    /**
     * Handler for Maureva response
     * @param {Object} responseData
     */
    handleMaurevaResponse(responseData = {}) {
        const { successfulReply } = responseData

        if (successfulReply) {
            // Handle user data
            const { response } = responseData

            // Set user data to localStorage
            window.localStorage.setItem('user_data', JSON.stringify(response))

            // Trigger CB function
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            // Close drawer
            this.drawer.hide()
        } else {
            // Logout user from Firebase => Because we created it via Firebase API call
            this.FireBase.logOutUser()

            // Show alert
            this.showAlert(this.errorMesages.maureva_profile_create_error)
        }
    }

    /**
     * Create proper user data
     * @param {String} email
     * @param {String} userUuid
     * @param {Object} userDetails
     * @returns {Object}
     */
    createUserData(email, userUuid, userDetails) {
        const {
            firstname,
            lastname,
            title,
            gender,
            mobilePhone,
            dateOfBirth,
            commercialNotificationActivated,
        } = userDetails

        const displayName = `${firstname} ${lastname}`

        const userData = {
            email,
            userUuid,
            login: email,
            firstname,
            lastname,
            displayName,
            gender,
            title,
            mobilePhone,
            dateOfBirth: dateOfBirth,
            createdAt: moment().toISOString(), // Use current time
            //photoUrl: 'https://i.pravatar.cc/300',
            commercialNotificationActivated,
        }

        return userData
    }

    /**
     * Set active (visible) step
     */
    async setTabStep(step = 1) {
        const $step = this.container.find(`.tab-pane[data-tab="step-${step}"]`)

        this.container.find('.tab-pane').removeClass('show active')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 175)
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-n5 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `

        // Each of tabs have it's own alert container
        const $activeTab = this.container.find('.tab-pane.active')
        $activeTab.find('.user-register-form--alert-container').html(markup)
    }

    /**
     * Recaptcha validation method
     * @returns {Promise}
     */
    async validateRecaptcha() {
        const { validate, siteKey } = window.recaptcha
        let action = 'register_form'
        if (grecaptcha && validate && siteKey) {
            try {
                const token = await grecaptcha.execute(siteKey, { action: action })
                try {
                    const response = await axios.post(validate, {
                        'g-recaptcha-response': token,
                        'action': action
                    })
                    const { isValid } = response.data
                    if (!isValid) {
                        this.showAlert(this.errorMesages.recaptcha_validation_error)
                        return false
                    }
                    return true
                } catch (error) {
                    console.error(error)
                    this.showAlert(this.errorMesages.firebase_generic_error)

                    return false
                }
            } catch (error) {
                console.error(error)
                this.showAlert(this.errorMesages.recaptcha_couldnt_get_token)

                return false
            }
        }
    }
}

export default UserRegister
