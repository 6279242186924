/**
 * PhoneNumber input class component
 */

class PhoneNumberInput {
    constructor(domElement = null, {
        initialCountry = null,
        localizedCountries = {},
        options = {},
    }) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide propper DOM input element for PhoneNumberInput class')
            return
        }

        this.instance = null
        this.options = options
        this.initialCountry = initialCountry
        this.localizedCountries = localizedCountries

        const { phoneNumberErrorMessages } = window.errorMesages
        this.errorMesages = phoneNumberErrorMessages

        this.preferedCountries = ['rs', 'hr', 'ba', 'us','gb','ru']
        this.excludeCountries = ['xk']

        this.fieldOptions = {
            nationalMode: false,
            autoInsertDialCode: true,
            preventInvalidDialCodes: false,
            preferredCountries: this.preferedCountries,
            excludeCountries: this.excludeCountries,
            utilsScript: '/static/js/intl-tel-input-utils.js?1687509211722'
        }

        this.init()
    }

    /**
     * Destructor method
     */
    destroy() {
        if(this.instance) {
            this.instance.destroy()
        }
    }

    init() {
        this.setConfigOptions()

        const label = this.input.next('label')
        const oldParent = this.input.parent()
        const feedback = oldParent.next('.invalid-feedback')

        // Initialize + get iti instance
        this.input.intlTelInput(this.fieldOptions)
        this.instance = this.input.data('plugin_intlTelInput')

        if(label.length) {
            label.insertAfter(this.input)
        }

        if(feedback.length) {
            oldParent.append(feedback)
        }

        this.input.parent().addClass('form-floating')
    }

    setConfigOptions() {
        // Selected country from header
        if (this.initialCountry) {
            this.fieldOptions = {
                ...this.fieldOptions,
                initialCountry: this.initialCountry,
            }
        }

        // Localized options
        if (Object.keys(this.localizedCountries).length) {
            this.fieldOptions = {
                ...this.fieldOptions,
                localizedCountries: this.localizedCountries,
            }
        }

        this.fieldOptions = {
            ...this.fieldOptions,
            ...this.options // Override with extra options provided
        }
    }

    /**
     * Value getter
     * @returns {Object}
     */
    getValue() {
        let number = this.instance.getNumber()
        const countryData = this.instance.getSelectedCountryData()

        number = number.replace(`+${countryData.dialCode}`, '')

        const areaCode = countryData.dialCode ? `+${countryData.dialCode}` : null
        const countryCode = countryData.iso2 ? countryData.iso2.toUpperCase() : null

        return {
            number,
            areaCode,
            countryCode,
        }
    }

    /**
     * Value setter
     * @param {String} value
     */
    setValue(value = null) {
        this.instance.setNumber(value)
    }

    /**
     * Clear value method
     */
    clearValue() {
        this.instance.setNumber('')
        this.instance.setCountry(this.initialCountry)
    }

    /**
     * Validate field function
     * @returns {Object}
     */
    validate() {
        const $parent = this.input.parent()
        const $feedback = $parent.next('.invalid-feedback')

        let isValid = this.instance.isValidNumber()
        let errorCode

        if (isValid) {
            $parent.removeClass('is-invalid')
        } else {
            errorCode = this.instance.getValidationError()
            if(errorCode === -99 || errorCode === 2) {
                const errMsg = this.errorMesages.required_field

                $feedback.text(errMsg)
                $parent.addClass('is-invalid')
            } else {
                isValid = true
                errorCode = undefined
            }

            /*
            @todo can't be used since we have different data set
            const errMsg = errorCode === -99
                ? this.errorMesages.required_field
                : this.errorMesages.invalid_number
            */
        }

        return {
            isValid,
            errorCode
        }
    }
}

export default PhoneNumberInput
