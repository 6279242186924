/**
 * MainBookingPanel class component
 * https://www.figma.com/file/rf9zGBZaEplQkBZ6I9bvVc/Air-Serbia---UI-Design?type=design&node-id=150-29714&t=7kWaXMFeezzOsom7-0
 */
import { Tab } from 'bootstrap'
import OneWayBookingTab from './parts/tabs/OneWayBookingTab'
import RoundTripBookingTab from './parts/tabs/RoundTripBookingTab'
import MultiCityBookingTab from './parts/tabs/MultiCityBookingTab'
class MainBookingPanel {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for MainBookingPanel Class')
            return
        }

        // Fetch data
        const { index } = this.container.data()
        const { options, routes } = window[`booking_panel_${index}_data`]

        this.routes = routes
        this.locations = options

        this.searchHistoryItems = []

        this.init()
        this.setEventListeners()
        this.renderStoredBookings()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.search-history-item', this.handleHistoryItemClick.bind(this))
    }

    /**
     * Tabs initialization
     */
    init() {
        const roundTripTab = this.container.find('.flights-tab-content .tab-pane[data-type="round-trip"]')
        const oneWayTab = this.container.find('.flights-tab-content .tab-pane[data-type="one-way"]')
        const multiCityTab = this.container.find('.flights-tab-content .tab-pane[data-type="multi-city"]')

        const passengerPopoverTemplate = this.container.find('template.passengers-popover-template').html()
        const fromInputTemplate = this.container.find('template.location-from-input-template').html()
        const toInputTemplate = this.container.find('template.location-to-input-template').html()
        const dateInputTemplate = this.container.find('template.date-input-template').html()
        const removeFlightButtonTemplate = this.container.find('template.remove-flight-btn-template').html()

        const options = {
            locations: this.locations,
            templates: {
                fromInput: fromInputTemplate,
                toInput: toInputTemplate,
                dateInput: dateInputTemplate,
                passengerPopover: passengerPopoverTemplate,
                removeFlightButton: removeFlightButtonTemplate
            },
            routes: this.routes,
            onSubmit: data => this.handleOnSubmit(data),
            onPassengersChange: data => this.handlePassengersChange(data),
            onPromoCodeChange: data => this.handlePromoCodeChange(data)
        }

        this.oneWayTab = new OneWayBookingTab(oneWayTab[0], options)
        this.roundTripTab = new RoundTripBookingTab(roundTripTab[0], options)
        this.multiCityTab = new MultiCityBookingTab(multiCityTab[0], options)
    }

    /**
     * Save search to localStorage
     * @param {Object} data
     */
    handleOnSubmit(data = {}) {
        // Fetch values from storage
        const storageValues = localStorage.getItem('stored_bookings')
            ? JSON.parse(localStorage.getItem('stored_bookings'))
            : []

        // Add & remove item (if needed, max 5)
        storageValues.push(data)
        if (storageValues.length > 5) {
            storageValues.shift()
        }

        // Set new values
        localStorage.setItem('stored_bookings', JSON.stringify(storageValues))

        // Re-render items
        this.renderStoredBookings()
    }

    /**
     * History item click handler
     * @param {Object} event
     */
    handleHistoryItemClick(event) {
        const $button = $(event.currentTarget)
        const { index } = $button.data()

        const values = this.searchHistoryItems[index]
        const { tripType, payloadValues } = values

        switch(tripType) {
            case 'round-trip':
                this.roundTripTab.setFieldValues(payloadValues)
                break

            case 'one-way':
                this.oneWayTab.setFieldValues(payloadValues)
                break

            case 'multi-city':
                this.multiCityTab.setFieldValues(payloadValues)
                break
        }

        const tabTrigger = this.container.find(`.flights-navigation .nav-link[data-tab="${tripType}"]`)
        Tab.getOrCreateInstance(tabTrigger).show()
    }

    /**
     * Global handler for passenger changes
     * @param {Object} data
     */
    handlePassengersChange(data = {}) {
        const { tabType, values } = data

        switch (tabType) {
            case 'one-way':
                this.roundTripTab.setPassengersInputValue(values)
                this.multiCityTab.setPassengersInputValue(values)

                break

            case 'round-trip':
                this.oneWayTab.setPassengersInputValue(values)
                this.multiCityTab.setPassengersInputValue(values)

                break

            default:
                this.oneWayTab.setPassengersInputValue(values)
                this.roundTripTab.setPassengersInputValue(values)

                break
        }
    }

    /**
     * Global handler for promo code changes
     * @param {Object} data
     */
    handlePromoCodeChange(data = {}) {
        const { tabType, value } = data

        switch (tabType) {
            case 'one-way':
                this.roundTripTab.setPromoCodeValue(value)
                this.multiCityTab.setPromoCodeValue(value)

                break

            case 'round-trip':
                this.oneWayTab.setPromoCodeValue(value)
                this.multiCityTab.setPromoCodeValue(value)

                break

            default:
                this.oneWayTab.setPromoCodeValue(value)
                this.roundTripTab.setPromoCodeValue(value)

                break
        }
    }

    /**
     * Render saved searches
     */
    renderStoredBookings() {
        const storageValues = localStorage.getItem('stored_bookings')
            ? JSON.parse(localStorage.getItem('stored_bookings'))
            : []

        // Last item is the "newest" one
        this.searchHistoryItems = storageValues.reverse()

        const searchHistoryContainer = this.container.find('.main-booking-panel--search-history-container')
        const markup = this.createSearchHistoryMarkup()

        searchHistoryContainer.html(markup)
    }

    /**
     * Crate HTML markup for search history
     */
    createSearchHistoryMarkup() {
        const items = this.searchHistoryItems.map((item, index) => {
            const { payloadValues: {
                row_0: {
                    location_from,
                    location_to,
                } }
            } = item

            return `
                <button class="btn text-white p-0 border-0 me-sm-6 me-4 mt-sm-6 mt-4 search-history-item" data-index="${index}">
                    ${location_from.cityName} &mdash; ${location_to.cityName}
                </button>
            `
        })

        return items.join(' ')
    }
}

export default MainBookingPanel
