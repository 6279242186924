/**
 * BidForBusinessClass component
 */
class BidForBusinessClass {
    /**
     * Contructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for BidForBusinessClass')
            return
        }

        //this.setEventListeners()
    }

    destroy() {
        this.removeEventListeners()
    }

    removeEventListeners() {
        this.container.off('click touch')
    }

    setEventListeners() {
        this.container.on('click touch', '.btn-submit', this.handleSubmit.bind(this))
    }

    handleSubmit(event) {
        /*
        event.preventDefault()
        event.stopPropagation()

        console.log('aa');
        */
    }
}

export default BidForBusinessClass
