class HeaderTicker {
    constructor(domElement = null) {
        this.container = $(domElement)
        this.isEditmode = this.container.hasClass('editmode')

        if (this.isEditmode) {
            return
        }

        this.initCloseButton()
        this.initKeyboardControlls()
    }

    initCloseButton() {
        $(document).on('click touch', '.ticker-close-button', () => {
            this.container.addClass('d-none')
        })
    }

    // bootstrap doesn't register keyboard controls until you manually press the button at least once for some reason
    initKeyboardControlls() {
        $(document).on('keydown', function(e) {
            if (e.keyCode == 37) {
                $('.carousel-control-prev').click()
            }

            if (e.keyCode == 39) {
                $('.carousel-control-next').click()
            }
        })
    }
}

export default HeaderTicker
