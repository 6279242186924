import Dashboard from './dashboard'
import UserDetails from './details'
import ChangeDetails from './change-details'
import DeactivateAccount from './deactivate-account'

class UserProfile {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for UserProfile class')
            return
        }

        /**
         * @type UserAuth
         */
        this.UserAuth = window.UserAuth

        this.isEditmode = this.container.hasClass('editmode')
        this.homePagePath = $('header a.logo-container').attr('href')

        // Dashboard page
        this.DashboardPage = null
        this.UserDetailsPage = null

        this.init()
    }

    /**
     * Initialize function
     */
    async init() {
        const isUserLogged = await this.checkUserSession()
        if(isUserLogged) {
            // Show page content
            this.container.addClass('show')

            // Dashboard page
            this.dashboardContainer = this.container.find('.user-profile-dashboard')
            if(this.dashboardContainer.length) {
                this.DashboardPage = new Dashboard(this.dashboardContainer[0], {
                    onLogout: () => this.handleUserLogout(),
                })
            }

            // User details page
            this.userDetailsContainer = this.container.find('.user-profile-details')
            if(this.userDetailsContainer.length) {
                this.UserDetailsPage = new UserDetails(this.userDetailsContainer[0], {

                })
            }

            // Change details form
            this.changeDetailsContainer = this.container.find('.change-details-drawer')
            if(this.changeDetailsContainer.length) {
                this.ChangeDetailsDrawer = new ChangeDetails(this.changeDetailsContainer[0], {
                    onSubmit: () => {},
                    onLogout: () => this.handleUserLogout(true),
                })
            }

            // Deactivate account
            this.deactivateAccountContainer = this.container.find('.deactivate-account-drawer')
            if(this.deactivateAccountContainer.length) {
                this.DeactivateAccountDrawer = new DeactivateAccount(this.deactivateAccountContainer[0], {
                    onSubmit: () => {},
                })
            }
        }
    }

    /**
     * Logout action
     */
    async handleUserLogout(withLogin = false) {
        try {
            await this.UserAuth.logoutUser()

            if (!this.isEditmode) {
                if(!withLogin) {
                    this.redirectToHome()
                } else {
                    this.redirectToHomeWithLogin()
                }
            }

            return
        } catch(err) {
            throw err
        }
    }

    /**
     * Check wheter user is already logged in firebase
     */
    async checkUserSession() {
        const isUserLogged = await this.UserAuth.isUserLogged()
        if(!isUserLogged && !this.isEditmode) {
            this.redirectToHome()
            return false
        }

        return true
    }

    /**
     * Redirect to homepage
     * */
    redirectToHome() {
        if (this.homePagePath) {
            window.location.href = this.homePagePath
        } else {
            console.error('Homepage path can not be found')
        }
    }

    /**
     * Redirect to homepage with login query string (Show login drawer)
     */
    redirectToHomeWithLogin() {
        if (this.homePagePath) {
            window.location.href = `${this.homePagePath}?login=1`
        } else {
            console.error('Homepage path can not be found')
        }
    }
}

export default UserProfile
