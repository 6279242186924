/**
 * Manage booking class component
 */
import SabreMYB from './SabreMYB'
import TextInput from '../../fields/TextInput'

class ManageBooking {
    constructor(domElement = null, {
        options = {},
        templates = {},
        routes = {},
    }) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Booking Class')
            return
        }

        this.routes = routes
        this.options = options
        this.templates = templates

        this.instances = {}
        this.SabreMYB = new SabreMYB(this.routes.sabre.bookings)

        this.init()
        this.setEventListeners()
    }

    /**
     * Destroy method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-submit', this.handleSubmit.bind(this))
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    init() {
        const inputs = this.container.find('.form-input')
        inputs.each((_, input) => {
            const { name } = $(input).data()
            const inputInstance = new TextInput(input)

            this.instances[name] = inputInstance
        })
    }

    /**
     * Handle form submission
     * @param {Object} event
     */
    handleSubmit(event) {
        event.stopPropagation()

        let isValid = true
        const payload = {}

        Object.keys(this.instances).forEach(inputName => {
            // Validate
            const inputInstance = this.instances[inputName]
            if(!inputInstance.validate()) {
                isValid = false
            }

            // Get value
            const value = inputInstance.getValue()
            payload[inputName] = value
        })

        if (isValid) {
            this.SabreMYB.engage(payload)
        }
    }
}

export default ManageBooking
