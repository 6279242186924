import axios from 'axios'
import Dropzone from 'dropzone'

// We will attach dropzone automatically
Dropzone.autoDiscover = false


/**
 * FileUpload class component
 */
class FileUploadInput {
    /**
     * Constructor
     * @param {HTMLElement} domElement
     * @param {Object} options
     */
    constructor(domElement = null, options = {
        action: null,
        endPoints: null,
        extraOptions: {}
    }) {
        this.fileUploadInput = $(domElement)
        this.options = options

        if(!this.fileUploadInput.length) {
            console.error('Please provide proper DOM element for FileUploadInput class')
            return
        }

        this.formbuilderInput = null
        this.dropzoneInstance = null

        this.inputContainer = this.fileUploadInput.closest('.form-floating')
        //const { name } = this.inputContainer.data()
        //this.inputJSError = this.inputContainer.next(`.invalid-feedback-js[data-connected-input="${name}"]`)

        this.button = this.inputContainer.find('.custom-file-input button')
        this.customText = this.inputContainer.find('.custom-file-input .custom-file-input-text')
        this.dropzonePreviewElement = this.inputContainer.find('.dropzone-previews')

        const { fileIcon, deleteIcon } = this.dropzonePreviewElement.data()

        this.fileIcon = fileIcon
        this.deleteIcon = deleteIcon

        this.inputTopContainer = this.fileUploadInput.closest('.tab-pane').length
                ? this.fileUploadInput.closest('.tab-pane')
                : this.fileUploadInput.closest('form.formbuilder')

        this.submitButton = this.inputTopContainer.find('button[type="submit"]')

        this.storageField = null
        this.formbuilderInput = false
        this.suspendFileRemoval = false

        if(this.fileUploadInput.data('formbuilderInput')) {
            this.formbuilderInput = true

            const storageFieldId = `#${this.fileUploadInput.attr('id')}_data`
            this.storageField = this.inputTopContainer.find(storageFieldId)

            this.storageField.val('')
        }

        this.init()
        this.addEventListeners()
    }

    /**
     * Initialize method
     */
    init() {
        // Extra options for Formbuilder file input
        let dynamicInputOptions = {}
        const { engineOptions } = this.fileUploadInput.data()
        if(engineOptions && this.formbuilderInput) {
            dynamicInputOptions = this.setDynamicFormBuilderOptions(engineOptions)
        }

        // Create Dropzone instance
        this.dropzoneInstance = new Dropzone(this.fileUploadInput[0], {
            url: this.options.action,
            autoProcessQueue: false,
            paramName: this.fileUploadInput[0].name,
            uploadMultiple: true,
            clickable: this.button[0],
            hiddenInputContainer: this.fileUploadInput.closest('.form-floating')[0],
            previewsContainer: this.dropzonePreviewElement[0],
            previewTemplate: this.dropzonePreview(),
            ...dynamicInputOptions,
            ...this.options.extraOptions
        })

        //console.log(this.dropzoneInstance);
    }

    /**
     * Attach event listeners on dropzone instance
     */
    addEventListeners() {
        this.dropzoneInstance.on('addedfile', (file) => {
            const { maxFiles } = this.dropzoneInstance.options
            if(maxFiles) {
                const count = this.dropzoneInstance.files.length
                if(count > maxFiles) {
                    this.dropzoneInstance.removeFile(file)
                    return false
                }

                if(count === maxFiles) {
                    this.button.prop('disabled', true)
                }
            }

            if (this.customText) {
                this.customText.removeClass('invalid')

                const { fileChoosen, filesChoosen } = this.customText.data()
                if (fileChoosen && filesChoosen) {
                    this.customText.empty().html(`${this.dropzoneInstance.files.length} ${this.dropzoneInstance.files.length > 1 ? filesChoosen : fileChoosen}`)
                }
            }

            this.dropzonePreviewElement.addClass('py-3')

            // Remove errors
            //this.inputContainer.removeClass('is-invalid')

            // Check file sizes
            //this.validateMaxFileSizes()
        })

        this.dropzoneInstance.on('removedfile', (file) => {
            if (this.suspendFileRemoval) {
                return
            }

            const { maxFiles } = this.dropzoneInstance.options
            if(maxFiles) {
                const count = this.dropzoneInstance.files.length
                if(count < maxFiles) {
                    this.button.prop('disabled', false)
                }
            }

            if (this.customText) {
                const { fileChoosen, filesChoosen, fileNotChoosen } = this.customText.data()
                if (fileChoosen && filesChoosen && fileNotChoosen) {
                    if (this.dropzoneInstance.files.length) {
                        this.customText.empty().html(`${this.dropzoneInstance.files.length} ${this.dropzoneInstance.files.length > 1 ? filesChoosen : fileChoosen}`)
                    } else {
                        this.customText.empty().html(fileNotChoosen)
                    }
                }
            }

            if (!this.dropzoneInstance.files.length) {
                this.dropzonePreviewElement.removeClass('py-3')
            }

            // Remove uploaded file
            if (this.formbuilderInput && this.options.endPoints) {
                this.removeUploadedFile(file)
            }

            // Remove errors
            //this.inputContainer.removeClass('is-invalid')

            // Check file sizes
            //this.validateMaxFileSizes()
        })

        if (this.formbuilderInput) {
            // Crate && append generated file uuid
            this.dropzoneInstance.on('sending', (file, xhr, formData) => {
                this.submitButton.addClass('requesting').addClass('file-upload-disabled')
                formData.append('uuid', file.upload.uuid)
            })

            // Enable submit button
            this.dropzoneInstance.on('complete', () => {
                this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')
            })

            this.dropzoneInstance.on('cancel', () => {
                this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')
            })

            // Add file to storage field input values
            this.dropzoneInstance.on('success', (file, response) => {
                this.addToStorageField({
                    id: response.uuid,
                    fileName: response.fileName,
                })
            })

            // Suspend file removal
            this.dropzoneInstance.on('reset', () => {
                this.suspendFileRemoval = false
            })
        }

        /*
        this.dropzoneInstance.on('error', (file, message) => {
            console.error('Upload error:', file, message);
        })
        */
    }

    /**
     * Uploaded file preview markup
     * @returns {String}
     */
    dropzonePreview() {
        return `
            <div class="dz-preview dz-file-preview d-flex align-items-center justify-content-between my-2">
                <div class="dz-details d-flex align-items-center">
                    <div class="file-icon me-3">${this.fileIcon}</div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div data-dz-remove>${this.deleteIcon}</div>
            </div>
        `
    }

    /**
     * File size validation (Will be throwed by backend)
     */
    /*
    validateMaxFileSizes() {
        const { engineOptions } = this.fileUploadInput.data()
        if(engineOptions && this.formbuilderInput) {
            const { max_file_size } = engineOptions
            if(max_file_size) {
                const maxFileSize = max_file_size * 1024 * 1024

                let combineFileSize = 0
                this.dropzoneInstance.files.forEach(file => {
                    combineFileSize += file.size
                })

                if(maxFileSize < combineFileSize) {
                    this.inputContainer.addClass('is-invalid')
                    this.submitButton.addClass('file-error-disabled')

                    if (this.inputJSError.length) {
                        const { file_size_exceeded }  = this.inputJSError.data('msg')
                        this.inputJSError.html(file_size_exceeded)
                    }*
                } else {
                    this.inputContainer.removeClass('is-invalid')
                    this.submitButton.removeClass('file-error-disabled')

                    if (this.inputJSError.length) {
                        this.inputJSError.html('')
                    }*
                }
            }
        }
    }
    */

    /**
     * Map engine options provided by FormBuilder
     */
    setDynamicFormBuilderOptions(engineOptions = {}) {
        let options = {}

        // Url options for file upload ("file_add")
        if (this.options.endPoints) {
            options = {
                paramName: 'dmfData',
                url: this.options.endPoints['file_add'],
                autoProcessQueue: true,
                ...options,
            }
        }

        // Engine options
        const { allowed_extensions, item_limit, max_file_size, multiple } = engineOptions

        if(allowed_extensions !== undefined) {
            options = {
                ...options,
                acceptedFiles: allowed_extensions
            }
        }

        if(multiple !== undefined) {
            options = {
                ...options,
                uploadMultiple: multiple
            }
        }

        // "Empty" or "0" => no limit
        /*
        if(max_file_size) {
            options = {
                ...options,
                maxFilesize: max_file_size
            }
        }
        */

        if(item_limit !== undefined) {
            options = {
                ...options,
                maxFiles: item_limit
            }
        }

        return options
    }

    /**
     * Remove uploaded file action handler
     * @param {Object} file
     */
    async removeUploadedFile(file) {
        try {
            const requestUri = `${this.options.endPoints['file_delete']}/${file.upload.uuid}`
            const response = await axios.delete(requestUri)
            const { data: { uuid } } = response

            this.removeFromStorageField({ id: uuid })
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Append file to "fileStorage" input values
     */
    addToStorageField({ id, fileName }) {
        if(this.storageField) {
            let value = this.storageField.val()

            let data = typeof value === 'string' && value !== ''
                ? JSON.parse(value)
                : []

            data.push({
                id,
                fileName
            })

            this.storageField.val(JSON.stringify(data))
        }
    }

    /**
     * Remove file from "fileStorage" input values
     */
    removeFromStorageField({ id }) {
        if(this.storageField) {
            let value = this.storageField.val()

            let data = typeof value === 'string' && value !== ''
                ? JSON.parse(value)
                : []

            data = data.filter((d) => d.id !== id)

            this.storageField.val(JSON.stringify(data))
        }
    }

    /**
     * Fetch Dropzone instance
     * @returns {Dropzone}
     */
    getInstance() {
        if (this.dropzoneInstance) {
            return this.dropzoneInstance
        } else {
            return null
        }
    }

    /**
     * Destroy dropzone instance
     */
    destroy() {
        if (this.dropzoneInstance) {
            this.dropzoneInstance.destroy()
        }
    }
}

export default FileUploadInput
