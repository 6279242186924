/**
 * Query-string utils functions
 */

/**
 * Handler/Helper for uri with params & hashes
 * *Note*: value: undefined, null and 0 will remove sent key from string
 */
const updateUrlParameter = (uri, key, value) => {
    // Remove the hash part before operating on the uri
    let i = uri.indexOf('#')
    let hash = i === -1 ? ''  : uri.substr(i)
    uri = i === -1 ? uri : uri.substr(0, i)

    // Key is array-like
    // E.g "filter[name]"...
    let escapedKey = key
    if (/\[(.*?)\]/.test(key)) {
        // We need to escape "[ & ]" characters
        escapedKey = key.replace(/\[|\]/g, function(x) {
            return `\\${x}`
        })
    }

    // Regex for existing key
    let re = new RegExp("([?&])" + escapedKey + "=.*?(&|$)", "i")
    let removeReg = new RegExp("([?&]?)" + escapedKey + "=[^&]*", "i")

    // Separator logic
    let separator = uri.indexOf('?') !== -1 ? "&" : "?"

    // Remove key-value pair if value is empty
    if (!value) {
        uri = uri.replace(removeReg, '')

        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1)
        }

        // Replace first occurrence of & by ? if no ? is present
        if (uri.indexOf('?') === -1) {
            uri = uri.replace(/&/, '?')
        }

    } else if (uri.match(re)) {
        // Key already exists - replace
        uri = uri.replace(re, '$1' + key + "=" + value + '$2')
    } else {
        // Simple "add" logic
        uri = uri + separator + key + "=" + value
    }

    return uri + hash
}

/**
 * Create query string from object [Structured like return from "updateUrlParameter"]
 * @param {object} urlParamsObject
 * @returns {String}
 */
const createUrlParamsFromObject = (urlParamsObject) => {

    let queryString = buildQueries(urlParamsObject)
    queryString = sanitizeQuery(queryString)

    return queryString

    // Create query-string like url with new params
    function buildQueries(urlParams) {
        let queries = ''

        Object.keys(urlParams).forEach(key => {
            const value = urlParams[key]

            // We have array in param
            if (Array.isArray(value)) {
                const paramsArray = value.map(param => `${key}[]=${param}`)

                if (paramsArray.length > 0) {
                    // Add array-like ones
                    queries = `${queries}&${paramsArray.join('&')}`
                }

            } else {
                // Simple, key-value pair
                // Take care that null or '' will clear it
                if (value) {
                    queries = `${queries}&${key}=${value}`
                }
            }
        })

        return queries
    }

    // Remove unwanted charachters
    function sanitizeQuery(queries) {
        // Remove duplicated [if they exist]
        queries = queries.replace('&&', '&')

        // Remove '&' from 1st place
        if (queries.charAt(0) === '&') {
            queries = queries.substr(1)
        }

        // Remove '&' from last place
        if (queries.charAt(queries.length - 1) === '&') {
            queries = queries.substr(0, queries.length - 1)
        }

        return queries
    }
}

/**
 *  Get all query params as object
 * @param {String} url
 * @returns {Object}
 */
const getUrlParams = (url = null) => {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1)
    // we'll store the parameters here
    let obj = {}
    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0]
        // split our query string into its component parts
        let arr = queryString.split('&')
        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=')
            // set parameter name and value (use 'true' if empty)
            let paramName = a[0]
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]
            // (optional) keep case consistent
            paramName = paramName.toLowerCase()
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()
            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                let key = paramName.replace(/\[(\d+)?\]/, '')
                if (!obj[key]) obj[key] = []
                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    let index = /\[(\d+)\]/.exec(paramName)[1]
                    obj[key][index] = paramValue
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue)
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]]
                    obj[paramName].push(paramValue)
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue)
                }
            }
        }
    }

    return obj
}

export {
    getUrlParams,
    updateUrlParameter,
    createUrlParamsFromObject,
}
