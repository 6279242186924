import { Offcanvas } from 'bootstrap'
import FireBase from '../../../utils/firebase'

class DeactivateAccount {
    constructor(domElement = null, {
        onSubmit = null
    }) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide proper DOM element for DeactivateAccount class')
            return
        }

        // Offcanvas instance
        this.drawer = new Offcanvas(this.container[0])

        this.userData = null
        this.isSocialLogin = null
        this.submitButton = this.container.find('.btn-submit')

        const {
            deactivateAccountMessages
        } = window.errorMesages

        this.errorMesages = deactivateAccountMessages

        /**
         * @type UserAuth
         */
        this.UserAuth = window.UserAuth

        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        this.onSubmit = null
        if(onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        //this.drawer.show()

        this.setUserData()
        this.checkIfUserIsSocialLogged()
        this.setEventListeners()

        this.setSubmitButtonState()
    }

    setEventListeners() {
        // Reset steps when closed
        this.container.on('hidden.bs.offcanvas', () => {
            this.setTabStep(1)
            this.clearValues()
        })

        this.container.on('submit', 'form', event => {
            event.preventDefault()
            event.stopPropagation()
        })

        this.container.on('click', '.btn-submit', this.handleSubmit.bind(this))

        // Listen for Auth changes
        this.UserAuth.on('onAuthChange', (event) => {
            // Set new user data
            const { data } = event
            this.setUserData(data)
        })

        this.container.on('change', '.form-input', this.setSubmitButtonState.bind(this))
    }

    /**
     * Check if logged user is social logged
     */
    async checkIfUserIsSocialLogged() {
        this.isSocialLogin = await this.UserAuth.isUserSocialLogged()

        // Handle visibility of credential fields
        this.setFieldsByUserType()
    }

    /**
     * Submit handler
     * @param {Object} event
     */
    async handleSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const $button = $(event.currentTarget)
        if($button.hasClass('is-requesting')) {
            return
        }

        try {
            const isValid = this.validateFields()
            if (isValid) {
                $button.addClass('is-requesting')
                const isValidCredentials = await this.validateCredentials()

                if (isValidCredentials) {
                    // Get deactivate radio value
                    const deactivate = this.container.find('input[name="deactivate"]:checked').val()

                    // Call Maureva
                    await this.UserAuth.deleteUserProfile(this.userData, deactivate)

                    // Go to success step
                    this.setTabStep(2)
                } else {
                    $button.removeClass('is-requesting')
                }
            }

        } catch(err) {
            this.showAlert(this.errorMesages.maureva_profile_delete_error)
            throw err
        }
    }

    /**
     * Set enabled/disabled state of button
     */
    setSubmitButtonState() {
        let disabled = false

        const fields = this.container.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()
            const value = $field.val()
            const type = $field.attr('type')
            const isRequired = $field.prop('required')

            if(type === 'checkbox') {
                const isChecked = $field.is(':checked')
                if(isRequired && !isChecked) {
                    disabled = true
                }
            } else if(type === 'radio') {
                const isChecked = this.container.find(`input[name="${name}"]`).is(':checked')
                if(isRequired && !isChecked) {
                    disabled = true
                }
            } else {
                // Empty field
                if (isRequired && !value) {
                    if(name === 'password' && this.isSocialLogin) {
                        return true
                    }

                    disabled = true
                }
            }
        })

        this.submitButton.prop('disabled', disabled)
    }

    /**
     * Validate user credentials
     */
    async validateCredentials() {
        let valid = true

        const $emailField = this.container.find('.form-input[data-name="email"]')
        const $passwordField = this.container.find('.form-input[data-name="password"]')

        const email = $emailField.val()
        const password = $passwordField.val()

        if(this.isSocialLogin) {
            if(email !== this.userData.email) {
                valid = false
                this.setFieldError($emailField, this.errorMesages.email_response_error)
            }
        } else {
            try {
                await this.FireBase.reauthenticateUserByEmailAndPassword({ email, password })
            } catch(error) {
                this.handleFirebaseCredentialErrors(error)
                valid = false
            }
        }

        return valid
    }

     /**
     * Handler for Firebase login error
     * @param {Object} error
     */
    handleFirebaseCredentialErrors(error) {
        const { code } = error

        if (code === 'auth/user-mismatch') {
            // User not found
            const $emailField = this.container.find('.form-input[data-name="email"]')
            this.setFieldError($emailField,  this.errorMesages.email_response_error)
        } else if(code === 'auth/wrong-password') {
            // Wrong password
            const $passwordField = this.container.find('.form-input[data-name="password"]')
            this.setFieldError($passwordField,  this.errorMesages.password_response_error)
        } else {
            // Generic server error
            this.showAlert(this.errorMesages.firebase_generic_error)
        }
    }

    /**
     * Validate method
     */
    validateFields() {
        let valid = true

        const fields = this.container.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()
            const value = $field.val()
            const isRequired = $field.prop('required')

            // Empty field
            if (isRequired && !value) {
                if(name === 'password' && this.isSocialLogin) {
                    return true
                }

                this.setFieldError($field, this.errorMesages.required_field)

                valid = false
                return true
            }

            // Email pattern
            if (name === 'email') {
                if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.setFieldError($field, this.errorMesages.email_error)

                    valid = false
                    return true
                }
            }

            // Clear errors
            this.clearFieldError($field)
        })

        return valid
    }

    /**
     * Set current User data or fetch from localStorage
     */
    setUserData(user = null) {
        let userData = user
        if(!userData) {
            userData = localStorage.getItem('user_data')
                ? JSON.parse(localStorage.getItem('user_data'))
                : null
        }

        this.userData = userData
    }

    /**
     * Show/Hide password field if user is "Social logged"
     */
    setFieldsByUserType() {
        const $passwordField = this.container.find('.form-input[data-name="password"]')
        const $fieldContainer = $passwordField.closest('.password-input-container')

        if (this.isSocialLogin) {
            $fieldContainer.addClass('d-none').removeClass('d-block')
        } else {
            $fieldContainer.removeClass('d-none').addClass('d-block')
        }
    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        $feedback.text('')
        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }

    /**
     * Clear field values & errors
     */
    clearValues() {
        const fields = this.container.find('.form-input')

        fields.each((_, field) => {
            const $field = $(field)

            if($field.is(':checkbox')) {
                $field.prop('checked', false)
            } else {
                $field.val('')
            }

            this.clearFieldError($field)
        })
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-n5 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `
        this.container.find('.deactivate-account-form--alert-container').html(markup)
    }

    /**
     * Set step
     */
    setTabStep(step = 1) {
        const $step = this.container.find(`.tab-pane[data-tab="step-${step}"]`)

        this.container.find('.tab-pane').removeClass('show active')

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 175)
    }
}

export default DeactivateAccount
