/**
 * Check-in class component
 */
import AlteaCheckIn from '../booking/quick-booking-panel/components/check-in/DeepLinks/AlteaCheckIn'
import SabreCheckIn from '../booking/quick-booking-panel/components/check-in/DeepLinks/SabreCheckIn'
import IPortCheckIn from '../booking/quick-booking-panel/components/check-in/DeepLinks/IPortCheckIn'

// import TextInput from '../booking/main-booking-panel/parts/fields/SimpleInput'
import TextInput from '../../components/fields/TextInput'
import LocationInput from '../booking/main-booking-panel/parts/fields/LocationInput'

class CheckInPanel {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Check-in Class')
            return
        }

        // Fetch data
        const { index } = this.container.data()
        const { options, routes } = window[`check_in_panel_${index}_data`]

        this.instances = {}
        this.routes = routes
        this.checkInOptions = options.checkInLocations

        this.AlteaCheckIn = new AlteaCheckIn(this.routes.altea.checkIn)
        this.SabreCheckIn = new SabreCheckIn(this.routes.sabre.checkIn)
        this.IPortCheckIn = new IPortCheckIn(this.routes.iPort.checkIn)

        this.init()
        this.setEventListeners()
    }

    /**
     * Destroy method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-submit', this.handleSubmit.bind(this))
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Fields initialization
     */
    init() {
        const selects = this.container.find('select.form-input')
        selects.each((_, input) => {
            const { name } = $(input).data()
            const inputInstance = new LocationInput(input, {
                options: this.checkInOptions,
                optionsRoute: this.routes.checkins,
                popoverValidation: true,
            })

            inputInstance.fetchNewOptions()

            this.instances[name] = inputInstance
        })

        const textInputs = this.container.find('input.form-input')
        textInputs.each((_, input) => {
            const { name } = $(input).data()
            const inputInstance = new TextInput(input, {
                popoverValidation: true
            })

            this.instances[name] = inputInstance
        })
    }

    /**
     * Handle form submission
     * @param {Object} event
     */
    handleSubmit(event) {
        event.stopPropagation()

        let isValid = true
        const payload = {}

        // @Todo validate booking code/ticket number values
        // https://gist.github.com/eightyknots/4372d1166a192d5e9754

        Object.keys(this.instances).forEach(inputName => {
            // Validate
            const inputInstance = this.instances[inputName]
            if(!inputInstance.validate()) {
                isValid = false
            }

            // Get value
            const value = inputInstance.getValue()
            payload[inputName] = value
        })

        if (isValid) {
            this.handleCheckIn(payload)
        }
    }

    /**
     * Check in handler
     * @param {Object} payload
     */
    handleCheckIn(payload = {}) {
        const { location_from: {
            checkIn
        } } = payload

        switch (checkIn){
            case 'Altea':
                this.AlteaCheckIn.engage(payload)
                break

            case 'Sabre':
                this.SabreCheckIn.engage(payload)
                break

            case 'iPort':
                this.IPortCheckIn.engage(payload)
        }
    }
}

export default CheckInPanel
