import BaseBookingTab from './BaseBookingTab'

class OneWayBookingTab extends BaseBookingTab {
    constructor(domElement = null, data = {}) {
        super(domElement, data)

        this.tabType = 'one-way'

        this.init()
    }
}

export default OneWayBookingTab
