/**
 * Button Component
 */

import IconSelector from './IconSelector'

class ButtonBrick {
    constructor(domElement = null) {
        this.container = $(domElement)

        // All of the logic is in "editmode"
        this.isEditmode = this.container.hasClass('editmode')
        if(!this.isEditmode) {
            return
        }

        this.brickId = this.container.data('brickId')
        this.IconSelector = new IconSelector({})

        this.iconHiddenInput = this.container.find('.icon-hidden-input')
        this.previewButton = this.container.find('.btn-editmode-preview')

        this.initializeEventListeners()
    }

    initializeEventListeners() {
        this.IconSelector.on('onSelect', ({ data }) => this.handleIconSelection(data))

        $(document).on('click touch', `.toggle-icon-modal-button[data-brick="${this.brickId}"]`, (event) => this.showIconModal(event))
        $(document).on('click touch', `.remove-icon-button[data-brick="${this.brickId}"]`, (event) => this.removeIcon(event))
    }

    /**
     * Show selection modal
     */
    showIconModal() {
        const currentIcon = this.iconHiddenInput.html().replace(/(?:&nbsp;|<br>)/g,'')
        this.IconSelector.show(currentIcon)
    }

    /**
     * Update Pimcore DialogBox contents
     * @param {String} iconPath
     */
    setDialogBoxContent(iconPath = '') {
        const dialogBoxContainer = $(`.dialogbox-icon-selector-container[data-brick="${this.brickId}"]`)

        const modalButton = dialogBoxContainer.find('.toggle-icon-modal-button')
        const removeIconButton = dialogBoxContainer.find('.remove-icon-button')
        const iconContainer = dialogBoxContainer.find('.preview-icon-container')
        const iconPreview = iconContainer.find('img')

        const { addText, changeText } = modalButton.data()

        if (iconPath) {
            iconPreview.attr('src', iconPath)
            iconContainer.removeClass('d-none')

            modalButton.find('.button-inner-text').text(changeText)
            removeIconButton.removeClass('d-none')
        } else {
            iconPreview.attr('src', '')
            iconContainer.addClass('d-none')

            modalButton.find('.button-inner-text').text(addText)
            removeIconButton.addClass('d-none')
        }
    }

    /**
     * Set icon content to preview button
     * @param {String} iconContent
     */
    setIconPreview(iconContent = '') {
        const currentIcon = this.previewButton.find('svg')
        if(currentIcon.length > 0) {
            currentIcon.remove()
        }

        if (iconContent) {
            this.previewButton.addClass('btn-icon')
            this.previewButton.prepend(iconContent)
        } else {
            this.previewButton.removeClass('btn-icon')
        }
    }

    /**
     * Remove icon action
     */
    removeIcon() {
        this.iconHiddenInput.html('')

        this.setIconPreview(null)
        this.setDialogBoxContent(null)
    }

    /**
     * Handler for Icon select action
     * @param {Object} iconData
     */
    async handleIconSelection(iconData = {}) {
        try {
            // Set value to hidden EXT.js input
            const { iconPath } = iconData
            this.iconHiddenInput.html(iconPath)

            // Update content of Pimcore's dialogBox
            this.setDialogBoxContent(iconPath)

            // Fetch icon svg content (for preview only)
            const response = await $.ajax({
                url: iconPath,
                dataType: 'html',
                type: 'GET'
            })

            // Set icon
            this.setIconPreview(response)

        } catch (err) {
            throw err
        }
    }
}

export default ButtonBrick
