/**
 * Flights list component
 */
import moment from 'moment'
import DynamicDrawer from '../../components/DynamicDrawer'
import { formatDates as formatHeadingDates } from '../../utils/date-formatter'

class FlightsList {
    constructor(domElement = null, {
        onTabChange = null,
        icons = {},
        translations = {},
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for FlightsList class')
            return
        }

        this.filters = null

        this.onTabChange = null
        if(onTabChange && typeof onTabChange === 'function') {
            this.onTabChange = onTabChange
        }

        this.icons = icons
        this.translations = translations
        this.activeTab = 'departure'

        this.DetailsDrawer = null

        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.flight-schedule--list-nav .nav-link', this.handleTabChange.bind(this))
        this.container.on('click', '.flight-schedule--list .btn-details', this.handleFlightDetails.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Set filter values
     * @param {Object} filters
     */
    setFiltersData(filters = {}) {
        this.filters = filters
    }

    /**
     * Set data to list
     * @param {String} listData
     */
    setListData(listData = '') {
        this.container.html(listData)

        this.setTabs()
    }

    /**
     * Set active tab
     * @param {String} type
     */
    setActiveTab(type = 'departure') {
        this.activeTab = type
    }

    /**
     * Set requesting class
     */
    setRequestingFlag() {
        this.container.addClass('is-requesting')
        this.container.find('.loader-container').removeClass('d-none')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.container.removeClass('is-requesting')
        this.container.find('.loader-container').addClass('d-none')
    }

    /**
     * Tab change event handler
     * @param {Object} event
     */
    handleTabChange(event) {
        const $button = $(event.currentTarget)
        if($button.hasClass('active') || this.container.hasClass('is-requesting')) {
            return
        }

        this.container.find('.flight-schedule--list-nav .nav-link').removeClass('active')
        $button.addClass('active')

        const { tab } = $button.data()
        this.activeTab = tab

        if(this.onTabChange) {
            this.onTabChange(tab)
        }
    }

    /**
     * Show flight details drawer
     * @param {Object} event
     */
    handleFlightDetails(event) {
        const $button = $(event.currentTarget)
        const { flightData } = $button.data()

        const detailsContent = this.createDetailsMarkup(flightData)
        this.DetailsDrawer = new DynamicDrawer({
            className: 'flight-details-drawer',
            content: detailsContent,
            onHide: () => {
                this.DetailsDrawer.destroy()
                this.DetailsDrawer = null
            }
        })

        this.DetailsDrawer.show()
    }

    /**
     * Set tabs
     */
    setTabs() {
        const { origin, destination, returnDate, departureDate } = this.filters

        const activeTab = this.container.find(`.flight-schedule--list-nav .nav-link[data-tab="${this.activeTab}"]`)
        activeTab.addClass('active')

        const departureTab = this.container.find('.flight-schedule--list-nav .nav-link[data-tab="departure"]')
        const returnTab = this.container.find('.flight-schedule--list-nav .nav-link[data-tab="return"]')

        departureTab.find('.text').html(`${origin.cityName}&nbsp;&mdash;&nbsp;${destination.cityName}`)
        returnTab.find('.text').html(`${destination.cityName}&nbsp;&mdash;&nbsp;${origin.cityName}`)

        // Hide tabs if date not provided
        departureDate ? departureTab.removeClass('d-none') : departureTab.addClass('d-none')
        returnDate ? returnTab.removeClass('d-none') : returnTab.addClass('d-none')

        // Call js formater for dates
        formatHeadingDates()
    }

    /**
     * Create markup for details drawer
     * @param {Array|null} data
     * @returns {String} HTML markup
     */
    createDetailsMarkup(data = null) {
        const { departure, arrival, details } = data

        // Re-map response data
        const flightsData = this.mapFlightsData(details)

        // Flight
        const renderFlight = (flightData) => {
            const { aircraft, flightNumber, arrival, departure } = flightData

            const renderTime = flightData => flightData.time ? `${flightData.time}&nbsp;&nbsp;` : ''

            const renderCity = flightData => {
                const { airportCode, cityName } = flightData
                return `${cityName ? `${cityName}&nbsp;` : ''}${cityName ? `(${airportCode})` : airportCode}`
            }

            const renderAirport = flightData => {
                const { airportName, countryName } = flightData

                const value = [ airportName, countryName ].filter(item => !!item).join(', ')
                return value ? `<p class="small text-black mb-4">${value}</p>` : ''
            }

            return `
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-items-center me-3">
                        <div class="icon-container">
                            ${this.icons.takeOffIcon}
                        </div>
                        <div class="divider"></div>
                    </div>
                    <div class="d-flex flex-column mb-6">
                        <h5 class="mb-0">${renderTime(departure)}${renderCity(departure)}</h5>
                        ${renderAirport(departure)}
                        ${flightNumber ? `<p class="text-black">${flightNumber}</p>` : ''}
                        ${aircraft ? `<p class="small text-black mb-0">${this.translations.aircraft}: ${aircraft}</p>` : ''}
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-items-center me-3">
                        <div class="icon-container">
                            ${this.icons.landingIcon}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <h5 class="mb-0">${renderTime(arrival)}${renderCity(arrival)}</h5>
                        ${renderAirport(arrival)}
                    </div>
                </div>
            `
        }

        const renderStop = (stopData = {}) => {
            const { arrival, departure } = stopData

            if (!arrival.dateTime || !departure.dateTime) {
                return ''
            }

            // Eg. "07-17T07:50"
            const arrivalTime = moment(arrival.dateTime, 'MM-DDTHH:mm')
            const departureTime = moment(departure.dateTime, 'MM-DDTHH:mm')

            const duration = moment.duration(departureTime.diff(arrivalTime))
            const durationStr = `${duration.hours()}h ${duration.minutes()}min`

            return `
                <div class="d-flex flex-row mb-4">
                    <div class="d-flex flex-column align-items-center me-3">
                        <div class="icon-container bg-transparent"></div>
                    </div>
                    <div class="d-flex flex-column">
                        <h5 class="layover-text mb-0 text-primary">${this.translations.layover} ${durationStr}</h5>
                    </div>
                </div>
            `
        }

        return `
            <h3 class="mb-7">${this.translations.detailsTitle}</h3>
            <h4 class="mb-5">${departure.cityName}&nbsp;&mdash;&nbsp;${arrival.cityName}</h4>
            <div class="details-container">
                ${flightsData.map(flight => {
                    return flight.type === 'flight' ? renderFlight(flight) : renderStop(flight)
                }).join('')}
            </div>
        `
    }

    /**
     * Create proper flights data with stops
     * @param {Array} flightsData
     * @returns {Array}
     */
    mapFlightsData(flightsData = []) {
        return flightsData.reduce((accumulator, flightData, index) => {
            // Add flag for flight
            const currentData = {
                type: 'flight',
                ...flightData
            }

            const nextFlight = flightsData[index + 1]
            if (nextFlight) {
                // Create data for stop
                const stopData = {
                    type: 'stop',
                    arrival: flightData.arrival,
                    departure: nextFlight.departure
                }

                return [
                    ...accumulator,
                    currentData,
                    stopData,
                ]
            } else {
                return [
                    ...accumulator,
                    currentData
                ]
            }
        }, [])
    }
}

export default FlightsList
