/**
 * CategoryFilter component
 */

class CategoryFilter {
    constructor(domElement = null, {
        onChange = null
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for CategoryFilter class')
            return
        }

        // Cb function
        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        // Store for event listeners
        this._triggers = {}

        // Debounce (reduce number of requests)
        this.categoryChangeDelay = 350
        this.categoryChangeTimeout = null

        this.addEventListeners()
    }

    destroy() {
        this.container.off()
    }

    enable() {
        this.container.find('.content-pages-list--category-button').removeClass('disabled')
    }

    disable() {
        this.container.find('.content-pages-list--category-button').addClass('disabled')
    }

    addEventListeners() {
        this.container.on('click touch', '.content-pages-list--category-button', event => this.handleCategoryChange(event))
    }

    // "on" event listeners on object
    on(event, callBack) {
        if(!this._triggers[event]) {
            this._triggers[event] = []
        }

        this._triggers[event].push(callBack)
    }

    // Event dispatcher for "on" events
    triggerHandler(event, eventData) {
        if(this._triggers[event]) {
            this._triggers[event].forEach(listener => {
                listener({
                    event,
                    data: eventData
                })
            })
        }
    }

    /**
     * Category change event handler
     * @param {Object} event
     */
    handleCategoryChange(event) {
        const $tag = $(event.currentTarget)
        const { categoryId } = $tag.data()

        if ($tag.hasClass('active')) {
            return
        }

        // Set active classes => Only one category can be active at time
        const $tags = this.container.find('.content-pages-list--category-button')
        $tags.removeClass('active')
        $tag.addClass('active')

        // Debounce
        clearTimeout(this.categoryChangeTimeout)
        this.categoryChangeTimeout = setTimeout(() => {
            // Cb function
            if (this.onChange) {
                this.onChange(categoryId)
            }

            // Trigger event listener
            this.triggerHandler('categoryChange', categoryId)
        }, this.categoryChangeDelay)
    }

    /**
     * Fetch selected category IDs
     * @returns {Array}
     */
    getSelectedCategory() {
        let selectedCategory = null
        const $tag = this.container.find('.content-pages-list--category-button.active')

        if ($tag.length) {
            const { categoryId } = $tag.data()
            selectedCategory = categoryId === 'all' ? null : categoryId
        }

        return selectedCategory
    }
}

export default CategoryFilter
