import { Offcanvas } from 'bootstrap'

class RegularDrawerBrick {
    constructor(domElement = null) {
        this.container = $(domElement)

        this.isOpen = false
        this.drawerInstance = null
        this.isEditmode = this.container.hasClass('editmode')

        this.toggleButton = this.container.find('.toggle-button')
        this.backdrop = this.container.find('.offcanvas-backdrop')
        this.drawerContainer = this.container.find('.offcanvas')
        this.closeButton = this.drawerContainer.find('.offcanvas-header .btn-close')

        this.drawerId = this.drawerContainer.data('realId')

        if (this.isEditmode) {
            this.initializeEventListeners()
        } else {
            this.initializeOffcanvas()
        }
    }

    initializeEventListeners() {
        this.toggleButton.on('click', () => {
            this.toggleDrawer()
        })

        this.closeButton.on('click', () => {
            this.toggleDrawer()
        })

        $(document).on('click', `.trigger-drawer-link[href="#${this.drawerId}"]`, event => {
            event.preventDefault()
            event.stopPropagation()

            this.toggleDrawer()
        })
    }

    toggleDrawer() {
        if (this.isOpen) {
            this.isOpen = false

            this.backdrop.addClass('d-none')
            this.drawerContainer.removeClass('show')

            $('body').removeClass('disable-scroll')
        } else {
            this.isOpen = true

            this.backdrop.removeClass('d-none')
            this.drawerContainer.addClass('show')

            $('body').addClass('disable-scroll')
        }
    }

    initializeOffcanvas() {
        this.drawerInstance = new Offcanvas(this.drawerContainer[0])
    }
}

export default RegularDrawerBrick
