/**
 * Links util class component
 */
class LinksUtil {

    /**
     * Check & set proper markups to links
     * @param {Boolean} disableForEditmode Disable feature for Pimcore editmode
     * @returns {void}
     */
    setLinks(disableForEditmode = true) {
        if(disableForEditmode && $('body').data('pimcoreEditmode')) {
            return
        }

        // All links with target="_blank"
        $('body a[target="_blank"]:not(.btn):not(.link-ext-excluded):not(.link-ext-rendered)').each((_, item) => {
            const $link = $(item)
            const url = $link.attr('href')

            if (this.isExternalLink(url) && !this.isIconOnlyLink($link)) {
                this.setExternalLinkMarkup($link)
            }
        })
    }

    /**
     * Set proper markup with screen reader feature to external link
     * @param {jQuery} $link
     * @param {Boolean} addNoopener Append "rel=noopener"
     */
    setExternalLinkMarkup($link, addNoopener = true) {
        // Set inner content
        $link.append(this.getExternalLinkContent())

        // Set "rel=noopener"
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noopener
        if(addNoopener) {
            $link.attr('rel', 'noopener')
        }

        // Set flag class
        $link.addClass('link-ext-rendered')
    }

    /**
     * Check if provided url is external
     * *(https://www.designcise.com/web/tutorial/how-to-check-if-a-string-url-refers-to-an-external-link-using-javascript)*
     * @param {String} url
     * @returns {Boolean}
     */
    isExternalLink(url = '') {
        const tmp = document.createElement('a')
        tmp.href = url

        return tmp.host !== window.location.host
    }

    /**
     * Check if provided link element has only icon as its contents
     * @param {jQuery} $link
     * @returns {Boolean}
     */
    isIconOnlyLink($link) {
        const iconTags = ['svg', 'img']
        let onlyLink = false

        const countOfElements = $link.contents().filter(function() {
            return this.nodeType == Node.ELEMENT_NODE || (this.nodeType == Node.TEXT_NODE && !!$.trim(this.nodeValue))
        }).length

        const hasIcon = $link.contents().filter(function() {
            return this.nodeType == Node.ELEMENT_NODE && (this.tagName && iconTags.includes(this.tagName))
        }).length

        if (countOfElements && countOfElements == 1 && hasIcon) {
            onlyLink = true
        }

        return onlyLink
    }

    /**
     * Extra markup content
     * *(Since Boostrap 5, sr-only classes are not used anymore)*
     * @returns {String}
     */
    getExternalLinkContent() {
        const { externalLinkSrText } = window.localeData.translations

        const icon = `
            <svg class="icon-external-link" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512" aria-hidden="true">
                <g>
                    <path d="M483.6,227.6c-15.7,0-28.4,12.7-28.4,28.4v170.7c0,15.7-12.7,28.4-28.4,28.4H85.3c-15.7,0-28.4-12.7-28.4-28.4
                        V85.3c0-15.7,12.7-28.4,28.4-28.4H256c15.7,0,28.4-12.7,28.4-28.4S271.7,0,256,0H85.3C38.2,0,0,38.2,0,85.3v341.3
                        C0,473.8,38.2,512,85.3,512h341.3c47.1,0,85.3-38.2,85.3-85.3V256C512,240.3,499.3,227.6,483.6,227.6z"
                    />
                    <path d="M369.8,56.9h44.9L235.8,235.5c-11.2,11.1-11.2,29.1-0.2,40.2l0.2,0.2c11.1,11.2,29.1,11.2,40.2,0.2l0.2-0.2
                        L455.1,97.3v44.9c0,15.7,12.7,28.4,28.4,28.4c15.7,0,28.4-12.7,28.4-28.4V28.4C512,12.7,499.3,0,483.6,0H369.8
                        c-15.7,0-28.4,12.7-28.4,28.4S354.1,56.9,369.8,56.9z"
                    />
                </g>
            </svg>
        `

        return `
            ${icon}
            <span class="visually-hidden-focusable">
                ${externalLinkSrText}
            </span
        `
    }
}

export default LinksUtil
