import { Tooltip } from 'bootstrap'

class TextInput {
    constructor(domElement = null, {
        popoverValidation = false,
        onChange = null,
    }) {
        this.input = $(domElement)
        if(!this.input) {
            console.error('Please provide proper DOM element for DateInput class')
            return
        }

        const {
            requiredMessage,
        } = this.input.data()

        this.isRequired = this.input.prop('required')
        this.inputContainer = this.input.parent()

        // Required Tooltip
        this.isValidated = false
        this.requiredPopover = null
        if (this.isRequired && popoverValidation) {
            this.requiredPopover = new Tooltip(this.inputContainer, {
                trigger: 'manual',
                placement: 'bottom',
                customClass: 'required-tooltip',
                title: requiredMessage,
                //container: this.input.closest('.input-group')
            })
        }

        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        if (this.requiredPopover) {
            this.requiredPopover.dispose()
        }

        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.inputContainer.on('click', () => {
            // Hide popover if shown
            if(this.requiredPopover && this.isValidated) {
                this.isValidated = false
                this.requiredPopover.hide()
            }
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners()  {
        this.inputContainer.off('click')
    }

    /**
     * Value getter
     * @returns {String}
     */
    getValue() {
        return this.input.val()
    }

    /**
     *
     * @param {String} value input value
     * @param {Boolean} triggerChange extra option for triggering "change" event on input
     */
    setValue(value = null, triggerChange = false) {
        this.input.val(value)

        if (triggerChange) {
            this.input.trigger('change')
        }
    }

    /**
     * Get name of input
     * @returns {String}
     */
    getName() {
        const { name } = this.input.data()

        return name
    }

    /**
     * Field validator method
     * @returns {Boolean}
     */
    validate() {
        // Empty field
        if (this.isRequired && !this.getValue()) {
            if(!this.requiredPopover) {
                // Set invalid classes
                this.input.addClass('is-invalid')
                this.inputContainer.addClass('is-invalid')
            } else {
                // Show popover if not shown already
                if(!this.isValidated) {
                    this.isValidated = true
                    this.requiredPopover.show()
                }
            }

            return false
        }

        return true
    }
}

export default TextInput
