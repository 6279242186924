import { Tab } from 'bootstrap'

class Header {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Header class')
            return
        }

        this.languageSelects = this.container.find('.header-languages-select')
        this.countrySelects = this.container.find('.header-countries-select')

        this.actionsContainer = this.container.find('.actions-container')
        this.loggedTemplate = this.actionsContainer.find('template.logged-template').html()
        this.notLoggedTemplate = this.actionsContainer.find('template.not-logged-template').html()

        this.setEventListeners()
        this.initSelects()
        this.setLastActiveTab()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click touch', '.header-hamburger', () => {
            const $navigation = this.container.find('.header-navigation')

            if($navigation.hasClass('menu-open')) {
                $navigation.removeClass('menu-open')
                $('body').removeClass('disable-scroll')
            } else {
                $navigation.addClass('menu-open')
                $('body').addClass('disable-scroll')
            }
        })

        this.container.on('click touch', '.accordion-link', function() {
            if ($(this).attr('target') == '_blank') {
                window.open($(this).attr('href'), '_blank')

            } else {
                window.location.href = $(this).attr('href')
            }
        })

        this.container.on('show.bs.tab', function(e) {
            localStorage.setItem('activeHeaderTabId', $(e.target).attr('id'))
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
        this.container.off('show.bs.tab')
    }

    initSelects() {
        this.initLanguageSelect(this.countrySelects)
        this.initCountrySelect(this.languageSelects)
    }

    setLastActiveTab() {
        let activeHeaderTabId = localStorage.getItem('activeHeaderTabId')

        if (activeHeaderTabId) {
            let lastActiveTabButton = $(`#${activeHeaderTabId}`)

            if (lastActiveTabButton.length) {
                let lastActiveTab = new Tab(lastActiveTabButton)
                lastActiveTab.show()
            }
        }
    }

    initLanguageSelect(countrySelect) {
        this.languageSelects.each(function() {
            $(this).selectize({
                maxItems: 1,
                onChange: val => {
                    window.location.href = val
                },
                onDropdownOpen: () => {
                    countrySelect.each(function() {
                        this.selectize.close()
                    })
                }
            })
        })
    }

    initCountrySelect(languageSelect) {
        this.countrySelects.each(function() {
            const isMobile = $(this).hasClass('header-countries-select-mobile')

            $(this).selectize({
                maxItems: 1,
                openOnFocus: isMobile,
                onChange: val => {
                    window.location.href = val
                },
                onDropdownOpen: () => {
                    languageSelect.each(function() {
                        this.selectize.close()
                    })
                },
                render: {
                    option: function(data, escape) {
                        let option = ''

                        if (data.disabled) {
                            option = `
                                <div class="option">
                                    <span>${escape(data.text)}</span>
                                </div>`

                        } else {
                            option = `
                                <div class="option">
                                    <span class="img-container">${data.icon}</span>
                                    <span class="body-1">${escape(data.text)}</span>
                                </div>`
                        }

                        return option
                    },
                    item: function(data, escape) {
                        let item = ''

                        if (isMobile) {
                            item = `<div class="item">${escape(data.name)}</div>`

                        } else {
                            item = `<div class="item ${data.shorthand == 'global' ? 'global-icon' : ''}">${data.icon}</div>`
                        }
                        return item
                    }
                }
            })
        })
    }

    /**
     * Update user data in header
     */
    updateUserData() {
        const userData = localStorage.getItem('user_data')
            ? JSON.parse(localStorage.getItem('user_data'))
            : null

        if (userData) {
            const profileImage = userData.photoUrl
                ? `<img src="${userData.photoUrl}">`
                : userData.firstname.charAt(0).toUpperCase()

            const $template = $('<div />').append(this.loggedTemplate)
            $template.find('.btn-profile').append(profileImage)

            this.actionsContainer.html('').append($template)
        } else {
            this.actionsContainer.html(this.notLoggedTemplate)
        }
    }
}

export default Header