/**
 * Simple input component class
 * [Promo code input]
 */
class SimpleInput {
    constructor(domElement = null, {
        onChange = null,
    }) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide propper DOM element for SimpleInput class')
            return
        }

        this.name = this.input.data('name')
        this.isRequired = this.input.prop('required')

        this.onChange = null
        if(onChange && typeof onChange === 'function') {
            this.onChange = onChange
        }

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Value getter
     * @returns {String}
     */
    getValue() {
        return this.input.val()
    }

    /**
     *
     * @param {String} value input value
     * @param {Boolean} triggerChange extra option for triggering "change" event on input
     */
    setValue(value = null, triggerChange = false) {
        this.input.val(value)

        if (triggerChange) {
            this.input.trigger('change')
        }
    }

    /**
     * Get name of input
     * @returns {String}
     */
    getName() {
        const { name } = this.input.data()

        return name
    }

    /**
     * Field validation
     * @returns {Boolean}
     */
    validate() {
        if (this.isRequired && !this.getValue()) {
            return false
        }

        return true
    }

    /**
     * Add event listeners
     */
    setEventListeners() {
        this.input.on('change', this.handleInputOnChange.bind(this))
    }

    /**
     * Remove attached Event listeners
     */
    removeEventListeners() {
        this.input.off('change')
    }

    /**
     * OnChange handler
     * @param {Object} event
     */
    handleInputOnChange(event) {
        // Trigger
        if (this.onChange) {
            this.onChange({
                name: this.name,
                value: this.input.val()
            })
        }
    }
}

export default SimpleInput