/**
 * Pagination component
 * For behaviour, check: https://codepen.io/pen?&editors=001
 */
class Pagination {
    constructor(domElement = null, {
        onPageChange = null,
    }) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Pagination class')
            return
        }

        this.pagination = this.container.find('.pagination')

        const { currentPage, pageCount } = this.pagination.data()

        this.pageCount = pageCount
        this.currentPage = currentPage

        this.maxVisibleItems = 8
        this.visibleItemsCount = 5

        this.templates = {
            pageItem: this.container.find('template.normal-page-item'),
            prevItem: this.container.find('template.prev-page-item'),
            nextItem: this.container.find('template.next-page-item'),
            prevGroupItem: this.container.find('template.prev-group-page-item'),
            nextGroupItem: this.container.find('template.next-group-page-item'),
        }

        this.paginationItems = []

        // Store for event listeners
        this._triggers = {}

        // Callback function
        this.onPageChange = null
        if(onPageChange && typeof onPageChange === 'function') {
            this.onPageChange = onPageChange
        }

        // Debounce (reduce number of requests)
        this.pageChangeDelay = 350
        this.pageChangeTimeout = null

        this.setPagination()
        this.addEventListeners()
    }

    destroy() {
        this.pagination.off('click')
        this.pagination.html('')
    }

    addEventListeners() {
        this.pagination.on('click touch', '.page-link', event => this.handlePageClick(event))
    }

    // "on" event listeners on object
    on(event, callBack) {
        if(!this._triggers[event]) {
            this._triggers[event] = []
        }

        this._triggers[event].push(callBack)
    }

    // Event dispatcher for "on" events
    triggerHandler(event, eventData) {
        if(this._triggers[event]) {
            this._triggers[event].forEach(listener => {
                listener({
                    event,
                    data: eventData
                })
            })
        }
    }

    enable() {
        // Just re-render
        this.setPagination()
    }

    disable() {
        this.pagination.find('.page-link').addClass('disabled')
    }

    /**
     * Current page getter
     */
    getCurrentPage() {
        return this.currentPage
    }

    /**
     * Current page setter
     * @param {Number} currentPage
     * @param {Bool} triggerOnChange
     */
    setCurrentPage(currentPage = null, triggerOnChange = false) {
        if(!currentPage || currentPage === this.currentPage) {
            return
        }

        // Update pagination
        this.currentPage = currentPage

        // Check if current page is properly set
        this.checkCurrentPageValue()

        // Update pagination state & re-render
        this.setPagination()

        // Trigger handler (if needed)
        if (triggerOnChange) {
            this.handlePageChange()
        }
    }

    /**
     * Page count setter
     * @param {Number} pageCount
     */
    setPageCount(pageCount = null) {
        if (!pageCount || pageCount === this.pageCount) {
            return
        }

        this.pageCount = pageCount

        // Check if current page is now properly set
        this.checkCurrentPageValue()

        // Update pagination state & re-render
        this.setPagination()
    }

    /**
     * Page click action handler
     * @param {Object} event
     */
    handlePageClick(event) {
        const $page = $(event.currentTarget)
        if($page.hasClass('disabled')) {
            return
        }

        const { action, page } = $page.data()

        switch (action) {
            case 'next-page':
                this.currentPage ++
                break

            case 'prev-page':
                this.currentPage --
                break

            case 'next-group':
                this.currentPage = this.currentPage + this.visibleItemsCount
                break

            case 'prev-group':
                this.currentPage = this.currentPage - this.visibleItemsCount
                break

            default:
                this.currentPage = page
        }

        // Check if current page is properly set
        this.checkCurrentPageValue()

        // Update pagination
        this.setPagination()

        // Trigger handler
        this.handlePageChange()
    }

    /**
     * Page change handler
     */
    handlePageChange() {
        // Debounce
        clearTimeout(this.pageChangeTimeout)
        this.pageChangeTimeout = setTimeout(() => {
            // Call Cb function
            if (this.onPageChange) {
                this.onPageChange(this.currentPage)
            }

            // Trigger event listener
            this.triggerHandler('pageChange', this.currentPage)
        }, this.pageChangeDelay)
    }

    /**
     * Render pagination items
     */
    renderPagination() {
        this.pagination.html('')

        this.paginationItems.forEach(pageItem => {
            const item = this.renderPaginationItem(pageItem)
            this.pagination.append(item)
        })
    }

    /**
     * Pagination item renderer
     * @param {String|Number} pageItem
     * @returns {HTMLElement}
     */
    renderPaginationItem(pageItem = null) {
        if (Number.isInteger(pageItem)) {
            // Find page template & clone it
            const pageTemplate = this.templates.pageItem[0]
            const clonedTemplate = pageTemplate.content.cloneNode(true)

            // Set attributes
            const $item = $(clonedTemplate).find('.page-link')
            $item.text(pageItem)
            $item.attr('data-page', pageItem)

            // Active state
            if (pageItem === this.currentPage) {
                $item.addClass('active')
            }

            return clonedTemplate
        } else {
            // Find page template & clone it
            const template = this.templates[pageItem][0]
            const clonedTemplate = template.content.cloneNode(true)

            if (pageItem === 'prevItem' && this.currentPage === 1) {
                $(clonedTemplate).find('.page-link').addClass('disabled')
            }

            if (pageItem === 'nextItem' && this.currentPage === this.pageCount) {
                $(clonedTemplate).find('.page-link').addClass('disabled')
            }

            return clonedTemplate
        }
    }

    /**
     * Set pagination items "blueprint", used for render
     */
    setPagination() {
        this.paginationItems = []

        if (this.pageCount >= this.maxVisibleItems) {
            // Items with grouped pages
            if (this.currentPage >= this.visibleItemsCount && this.currentPage <= this.pageCount - (this.visibleItemsCount - 1)) {
                // Grouped by both sides
                const length = (this.visibleItemsCount - 1) / 2
                const leftVisiblePages = Array.from({ length }, (_, i) => i + this.currentPage - 2)
                const rightVisiblePages = Array.from({ length }, (_, i) => i + this.currentPage + 1)

                this.paginationItems = [
                    'prevItem',
                    1,
                    'prevGroupItem',
                    ...leftVisiblePages,
                    this.currentPage,
                    ...rightVisiblePages,
                    'nextGroupItem',
                    this.pageCount,
                    'nextItem',
                ]
            } else {
                if (this.currentPage >= this.visibleItemsCount) {
                    // Grouped by left side only
                    const rightVisiblePages = Array.from({ length: this.visibleItemsCount }, (_, i) => i + this.pageCount - this.visibleItemsCount)
                    this.paginationItems = [
                        'prevItem',
                        1,
                        'prevGroupItem',
                        ...rightVisiblePages,
                        this.pageCount,
                        'nextItem',
                    ]
                }

                if (this.currentPage <= this.pageCount - (this.visibleItemsCount - 1)) {
                    // Grouped by right side only
                    const leftVisiblePages = Array.from({ length: this.visibleItemsCount }, (_, i) => i + 1)
                    this.paginationItems = [
                        'prevItem',
                        ...leftVisiblePages,
                        'nextGroupItem',
                        this.pageCount,
                        'nextItem',
                    ]
                }
            }
        } else {
            // "Normal" items
            const pages = Array.from({ length: this.pageCount }, (_, i) => i + 1)
            this.paginationItems = [
                'prevItem',
                ...pages,
                'nextItem',
            ]
        }

        // Render pagination with this new "blueprint"
        this.renderPagination()
    }

    /**
     * Extra checks for setting current page value
     * Must be in interval => [1 ... this.pageCount]
     */
    checkCurrentPageValue() {
        if (this.currentPage < 1) {
            this.currentPage = 1
        }

        if (this.currentPage > this.pageCount) {
            this.currentPage = this.pageCount
        }
    }
}

export default Pagination
