
import axios from 'axios'
import moment from 'moment'

import DateInput from './fields/DateInput'
import LocationInput from './fields/LocationInput'

import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class TopPanel {
    constructor(domElement = null, {
        routes = {},
        locations: {
            origins = [],
            destinations = []
        },
        onSubmit = null,
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for TopPanel class')
            return
        }

        // Routes
        this.routes = routes

        // Locations
        this.originsData = origins
        this.destinationsData = destinations

        const {
            language,
        } = window.localeData

        this.userLanguage = language

        this.onSubmit = null
        if(onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.init()
        this.setEventListener()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        this.FromInput.destroy()
        this.ToInput.destroy()
        this.DepartureDate.destroy()
        this.ReturnDate.destroy()

        this.FromInput = null
        this.ToInput = null
        this.DepartureDate = null
        this.ReturnDate = null
    }

    /**
     * Attach event listeners
     */
    setEventListener() {
        this.container.on('click', '.btn-search', this.handleSubmit.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Set requesting flag
     */
    setRequestingFlag() {
        this.container.find('.btn-search').addClass('is-requesting')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.container.find('.btn-search').removeClass('is-requesting')
    }

    init() {
        const fromInput = this.container.find('.form-select[data-name="location_from"]')
        const toInput = this.container.find('.form-select[data-name="location_to"]')

        const departureDateInput = this.container.find('.form-input[data-name="departure_date"]')
        const returnDateInput = this.container.find('.form-input[data-name="return_date"]')

        this.FromInput = this.initLocationInput(fromInput[0])
        this.ToInput = this.initLocationInput(toInput[0])

        this.DepartureDate = this.initDateInput(departureDateInput[0])
        this.ReturnDate = this.initDateInput(returnDateInput[0])
    }

    /**
     * Location input initialization
     * @param {HTMLElement} input
     */
    initLocationInput(input) {
        const $input = $(input)
        const { type } = $input.data()

        const inputInstance = new LocationInput(input, {
            options: type === 'from' ? this.originsData : this.destinationsData,
            optionsRoute: type === 'from' ? this.routes.origins : this.routes.destinations,
            onChange: data => this.handleLocationInputChange(input, data)
        })

        $input.addClass('input-initialized')

        return inputInstance
    }

    /**
     * Date input initialization
     * @param {HTMLElement} input
     */
    initDateInput(input) {
        const inputInstance = new DateInput(input, {
            datesRoute: this.routes.dates,
            extraOptions: {
                minDate: moment().subtract(2, 'year').toDate(), // Minus 2 yrs from now
                maxDate: new Date().fp_incr(365), // => Year from now
            }
        })

        $(input).addClass('input-initialized')

        return inputInstance
    }

    /**
     * Handler for location input change
     */
    handleLocationInputChange(input, data) {
        // Set options to connected location inputs
        const {
            name,
            selected
        } = data

        if (selected) {
            if (name === 'location_from') {
                this.ToInput.fetchNewOptions(`origin=${selected.code}&lang=${this.userLanguage}`)
            }

            if (name === 'location_to') {
                this.FromInput.fetchNewOptions(`destination=${selected.code}&lang=${this.userLanguage}`)
            }
        } else {
            if (name === 'location_from') {
                this.ToInput.resetOptions()
            }

            if (name === 'location_to') {
                this.FromInput.resetOptions()
            }
        }
    }

    /**
     * Fields validation
     */
    validateFields() {
        let valid = true

        if (!this.FromInput.validate()) {
            valid = false
        }

        if (!this.ToInput.validate()) {
            valid = false
        }

        if (!this.DepartureDate.validate()) {
            valid = false
        }

        if (!this.ReturnDate.validate()) {
            valid = false
        }

        return valid
    }

    /**
     * Create proper payload for fetching flights data
     */
    getValues() {
        const origin = this.FromInput.getValue()
        const destination = this.ToInput.getValue()

        const departureDate = this.DepartureDate.getValue()[0]
        const returnDate = this.ReturnDate.getValue().length ? this.ReturnDate.getValue()[0] : null

        if (origin && destination && departureDate) {
            return {
                origin,
                destination,
                departureDate: moment(departureDate).format('Y-MM-DD'),
                returnDate: returnDate ? moment(returnDate).format('Y-MM-DD') : null,
            }
        } else {
            return null
        }
    }

    /**
     * Create proper payload for fetching flights
     * @param {Boolean} returnFlight
     * @returns {String} query parameters
     */
    createPayload(returnFlight = false) {
        // Get values from filter
        const { origin, destination, departureDate, returnDate } = this.getValues()

        let params
        if (!returnFlight) {
            params = {
                origin: origin.code,
                destination: destination.code,
                date: departureDate
            }
        } else {
            params = {
                origin: destination.code,
                destination: origin.code,
                date: returnDate
            }
        }

        return createUrlParamsFromObject({
            ...params,
            lang: this.userLanguage
        })
    }

    /**
     * Handler for submission
     * @param {Object} event
     */
    async handleSubmit(event) {
        const $button = $(event.currentTarget)

        // Validate
        const isValid = this.validateFields()
        if (!isValid) {
            return
        }

        // Disable multiple submissions
        if ($button.hasClass('is-requesting')) {
            return
        }

        // Trigger callback
        if(this.onSubmit) {
            this.onSubmit()
        }
    }

    /**
     * Fetch flights data
     * @param {Boolean} returnFlight
     */
    async fetchFlightData(returnFlight = false) {
        try {
            const queryParams = this.createPayload(returnFlight)
            const requestUri = `${this.routes.flights}?${queryParams}`

            const response = await axios.get(requestUri)
            const { data } = response

            return data
        } catch(err) {
            throw err
        }
    }
}

export default TopPanel
