import axios from 'axios'
import Pagination from 'ContentBundle/js/frontend/partials/Pagination'

import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class EmergencyPage {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper element for EmergencyPage class')
            return
        }

        this.listContainer = this.container.find('.announcements-list--items')
        this.Pagination = null

        this.init()
    }

    init() {
        this.initPagination()
    }

    /**
     * (Re)Initialize pagination component
     */
    initPagination() {
        // Remove pagination
        if (this.Pagination) {
            this.Pagination.destroy()
            this.Pagination = null
        }

        // Re-init
        this.paginationContainer = this.container.find('.announcements-list--pagination .pagination-container')
        if (this.paginationContainer.length) {
            this.Pagination = new Pagination(this.paginationContainer[0], {
                onPageChange: page => this.handlePaginationChange(page)
            })
        }
    }

    /**
     * Set requesting class
     */
    setRequestingFlag() {
        this.listContainer.addClass('is-requesting')
        this.listContainer.find('.loader-container').removeClass('d-none')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.listContainer.removeClass('is-requesting')
        this.listContainer.find('.loader-container').addClass('d-none')
    }

    /**
     * Pagination page change
     * @param {Number} page
     */
    handlePaginationChange(page = 1) {
        // Scroll to top
        $([document.documentElement, document.body]).animate({
            scrollTop: this.container.offset().top - $('header.header').height()
        }, 400)

        // Fetch new list data
        this.fetchListData(page)
    }

    /**
     * Make request for new list data
     * @param {String} queryParams
     * @returns {Promise}
     */
    async fetchListData(page = 1) {
        try {
            this.setRequestingFlag()

            const requestUri = this.createRequestUri({ page })

            const response = await axios.get(requestUri)
            const { data } = response

            this.setListData(data)

            this.removeRequestingFlag()

            return
        } catch(err) {
            this.removeRequestingFlag()
            console.error(err)
            throw err
        }
    }

    /**
     * Update list content
     * @param {String} responseData
     */
    setListData(responseData = '') {
        // Re-init Pagination
        const $responseMarkup = $('<div />').append(responseData)

        const $listResponse = $responseMarkup.find('.announcements-list--items')
        const listMarkup = $listResponse.html()

        this.listContainer.html(listMarkup)
        this.initPagination()
    }

    /**
     * Create proper requestUri
     * @param {page} Number
     * @returns {String}
     */
    createRequestUri({ page = 1 }) {
        const queryParams = createUrlParamsFromObject({
            page
        })

        let requestUri = window.location.href.split(/[?#]/)[0]
        requestUri = `${requestUri}?${queryParams}`

        // Update browser url
        if (typeof window.history.pushState != 'undefined') {
            window.history.pushState(null, null, requestUri)
        }

        return requestUri
    }
}

export default EmergencyPage
