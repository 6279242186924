class SearchNewsWidget {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for SearchNewsWidget')
            return
        }

        this.formInput = this.container.find('#searchInput')

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('submit', (event) => {
            const { previousPage } = this.container.data()
            if (previousPage) {
                event.preventDefault()

                const url = new URL(window.location.origin + previousPage)
                url.searchParams.append(this.formInput.attr('name'), this.formInput.val())

                window.location = url.href
            }
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('submit')
    }
}

export default SearchNewsWidget
