/*
* Flights list component
*/
import axios from 'axios'

import Pagination from 'ContentBundle/js/frontend/partials/Pagination'
import DynamicDrawer from '../../components/DynamicDrawer'

import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class FlightsList {
    constructor(domElement = null, {
        icons = {},
        routes = {},
        templates = {},
        translations = {},
    }) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide proper DOM element for FlightsList class')
            return
        }

        this.icons = icons
        this.routes = routes
        this.templates = templates
        this.translations = translations

        // Provided filter values
        this.filtersData = {}
        this.selectedDate = null

        // Instances
        this.Pagination = null
        this.DetailsDrawer = null

        // Fetching mechanism
        this.fetchRequests = 0
        this.maxFetchRequests = 4
        this.delayStep = 650

        // Localization
        const {
            language,
        } = window.localeData

        this.userLanguage = language

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
        if (this.DetailsDrawer) {
            this.DetailsDrawer.destroy()
            this.DetailsDrawer = null
        }
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.flight-status--list-items .btn-details', this.handleFlightDetails.bind(this))
    }

    /**
     * Remove atatched event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Set requesting class
     */
    setRequestingFlag() {
        this.container.addClass('is-requesting')
        this.container.find('.loader-container').removeClass('d-none')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.container.removeClass('is-requesting')
        this.container.find('.loader-container').addClass('d-none')
    }

    /**
     * Filters data setter
     * @param {Object} filtersData
     */
    setFiltersData(filtersData = {}) {
        this.filtersData = filtersData
    }

    /**
     * Selected date formatted string setter
     * @param {String} dateStr
     */
    setSelectedDate(dateStr = null) {
        this.selectedDate = dateStr
    }

    /**
     * Set data to list
     * @param {String} listData
     */
    setListData(listData = '') {
        // Remove pagination
        if (this.Pagination) {
            this.Pagination.destroy()
            this.Pagination = null
        }

        // Manipulate markup with formatted date
        let listMarkup = listData
        if(this.selectedDate) {
            const $listResponse = $('<div />').html(listData)
            $listResponse.find('h5.selected-date').text(this.selectedDate)

            listMarkup = $listResponse.html()
        }

        // Set content
        this.container.html(listMarkup)

        // Re-init Pagination
        this.paginationContainer = this.container.find('.flight-status--list--pagination .pagination-container')
        if (this.paginationContainer.length) {
            this.Pagination = new Pagination(this.paginationContainer[0], {
                onPageChange: page => this.handlePaginationChange(page)
            })
        }
    }

    /**
     * Show flight details drawer
     * @param {Object} event
     */
    handleFlightDetails(event) {
        const $button = $(event.currentTarget)
        const { flightData } = $button.data()

        const detailsContent = this.createDetailsMarkup(flightData)
        this.DetailsDrawer = new DynamicDrawer({
            className: 'flight-details-drawer',
            content: detailsContent,
            onHide: () => {
                this.DetailsDrawer.destroy()
                this.DetailsDrawer = null
            }
        })

        this.DetailsDrawer.show()
    }

    /**
     * Pagination page change
     * @param {Number} page
     */
    handlePaginationChange(page = 1) {
        // Scroll to top
        $([document.documentElement, document.body]).animate({
            scrollTop: this.container.offset().top - $('header.header').height()
        }, 400)

        // Just fetch new list data
        this.fetchListData(this.filtersData, page)
    }

    /**
     * Fetch list data
     * @param {Object} filtersData
     * @param {Number} page
     * @returns {Promise}
     */
    async fetchListData(filtersData = {}, page = 1) {
        try {
            // Update saved values (Because of pagination change action)
            this.filtersData = filtersData

            // Query params
            const assertQueryParams = createUrlParamsFromObject({
                ...filtersData,
                lang: this.userLanguage,
            })

            const queryParams = createUrlParamsFromObject({
                ...filtersData,
                lang: this.userLanguage,
                perPage: 10,
                page
            })

            this.setRequestingFlag()

            // Assert data from SITA
            await this.assertFlightsData(assertQueryParams)

            // Try fetching list data
            const data = await this.fetchFlightsData(queryParams)
            this.setListData(data)

            this.removeRequestingFlag()

            return

        } catch (err) {
            this.removeRequestingFlag()
            throw err
        }
    }

    /**
     * Make request for fetching flights data
     * @param {String} queryParams
     * @returns {Promise}
     */
    async fetchFlightsData(queryParams = '') {

        /**
         * Simple "delay function"
         * @param {Number} timeout
         * @returns {Promise}
         */
        const delay = (timeout) => new Promise(resolve => setTimeout(resolve, timeout))

        /**
         * Recursive calls with dynamic timeouts
         * @param {String} queryParams
         */
        const fetchData = async (queryParams = '') => {
            try {
                const requestUri = `${this.routes.info}?${queryParams}`

                const response = await axios.get(requestUri)
                const { data } = response

                return data
            } catch (err) {
                // "To Early (425) status code handler"
                if(err.response && err.response.status === 425) {
                    if(this.fetchRequests < this.maxFetchRequests) {
                        // Recursive call
                        this.fetchRequests ++
                        return delay(this.fetchRequests * this.delayStep).then(() => {
                            return fetchData(queryParams)
                        })
                    } else {
                        // Reset counter
                        this.fetchRequests = 0

                        // Set error alert
                        this.setListData(this.templates.sitaFetchingError)

                        console.warn(`Timeouted call for fetching flights with: ${queryParams}`)

                        // Throw error now so "reject" can catch it
                        throw err
                    }
                }

                // Server error other than "425" status
                console.error(err)

                // Set error alert
                this.setListData(this.templates.serverError)

                throw err
            }
        }

        try {
            const data = await fetchData(queryParams)
            return data
        } catch (err) {
            throw err
        }
    }

    /**
     * Assert (request) data from SITA API
     * @param {String} queryParams
     */
    async assertFlightsData(queryParams = '') {
        try {
            const assertRequestUri = `${this.routes.assert}?${queryParams}`
            await axios.get(assertRequestUri)

            return
        } catch(err) {
            throw err
        }
    }

    /**
     * Create markup for details drawer
     * @param {Array|null} data
     * @returns {String} HTML markup
     */
    createDetailsMarkup(data = null) {
        const { departure, arrival } = data

        const renderCity = flightData => {
            const { airportCode, cityName } = flightData
            return `${cityName ? `${cityName}&nbsp;` : ''}${cityName ? `(${airportCode})` : airportCode}`
        }

        const renderAirport = flightData => {
            const { airportName, countryName } = flightData

            const value = [ airportName, countryName ].filter(item => !!item).join(', ')
            return value ? `<p class="small text-black mb-4">${value}</p>` : ''
        }

        const renderStatus = flightData => {
            const { status, time } = flightData

            const statusMapper = {
                'DL': 'btn-warning',
                'CX': 'btn-danger',
            }

            const statusClass = statusMapper[status.code] || 'btn-primary'

            return `
                <div class="d-flex flex-row mb-4">
                    <span class="btn btn-sm text-white small ${statusClass} pe-none">
                        ${status.text}
                    </span>
                </div>

            `
        }

        const renderOperator = () => {
            const { flightNumber, operator } = data

            const operatorLogo = operator.logo ? `<img class="operator-logo me-1" src="${operator.logo}">` : ''
            const operatorName = operator.name ? `${this.translations.operateBy} ${operator.name}` : ''

            return `
                <p class="d-flex text-black mb-2">
                    ${operatorLogo}
                    ${flightNumber} ${operatorName}
                </p>
            `
        }

        const renderDeparture = () => {
            const flightTime = departure.time && departure.time.scheduled ? `${departure.time.scheduled}&nbsp;&nbsp;` : ''

            return `
                <div class="d-flex flex-column mb-5">
                    <h5 class="mb-0">
                        ${flightTime}${renderCity(departure)}
                    </h5>
                    ${renderAirport(departure)}
                    ${renderStatus(departure)}
                    ${renderOperator()}
                    ${data.aircraft ? `<p class="small text-black mb-0">${this.translations.aircraft}: ${data.aircraft}</p>` : ''}
                    ${data.travelTime ? `<p class="small text-black mb-0">${this.translations.travelTime}: ${data.travelTime}</p>` : ''}
                    ${departure.terminal ? `<p class="small text-black mb-0">${this.translations.departureTerminal}: ${departure.terminal}</p>` : ''}
                    ${arrival.terminal ? `<p class="small text-black mb-0">${this.translations.arrivalTerminal}: ${arrival.terminal}</p>` : ''}
                </div>
            `
        }

        const renderArrival = () => {
            const flightTime = arrival.time && arrival.time.scheduled ? `${arrival.time.scheduled}&nbsp;&nbsp;` : ''

            return `
                <div class="d-flex flex-column">
                    <h5 class="mb-0">
                        ${flightTime}${renderCity(arrival)}
                    </h5>
                    ${renderAirport(arrival)}
                    ${renderStatus(arrival)}
                </div>
            `
        }

        return `
            <h3 class="mb-7">${this.translations.detailsTitle}</h3>
            <h4 class="mb-5">${departure.cityName}&nbsp;&mdash;&nbsp;${arrival.cityName}</h4>
            <div class="details-container">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-items-center me-3">
                        <div class="icon-container">
                            ${this.icons.takeOffIcon}
                        </div>
                        <div class="divider"></div>
                    </div>
                    ${renderDeparture()}
                </div>
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-items-center me-3">
                        <div class="icon-container">
                            ${this.icons.landingIcon}
                        </div>
                    </div>
                    ${renderArrival()}
                </div>
            </div>
        `
    }
}

export default FlightsList
